import assert from './library/debug';
import NavBar from './library/components/navbar';
import MenuPanel from './library/components/menupanel';
import { TimeZone } from './library/timezone';
import AlarmPanel from './library/alarmpanel';
import AlarmSnackBar from './library/components/alarmsnackbar';
import SettingsManager from './library/settingsmanager';
import Toolbar from './library/components/toolbar';
import Page from './library/pages/page';
import LiveDataClient, { GroupInfo } from './library/livedataclient';
import { Node, NodeFlags, VType } from './library/node';
import { Device } from './library/device';
import ViewModal from './library/viewmodal';
import Colors from './library/colors';
import { LogoutTimer } from './library/components/logouttimer';
import AlarmManager from './library/alarmmanager';
import LoginPage from './library/pages/loginpage';
import { LoginStatus, Company } from './library/livedataclient';
import Dialog from './library/dialog';
import { Router, RouteInfo, getHash } from './library/router/router';
import UndefinedPage from './library/pages/undefinedpage';
import DevicePage from './library/pages/devicepage';
import DashboardPage from './library/pages/dashboardpage';
import SettingsPage from './library/pages/settingspage';
import ExplorerPage from './library/pages/explorerpage';
import EditorPage from './library/pages/editorpage';
import MapPage from './library/pages/mappage';
import ChartPage from './library/pages/chartpage';
import AddDevicePage from './library/pages/adddevicepage';
import FinderPage from './library/pages/finderpage';
import DashboardViewerPage from './library/pages/dashboardviewerpage';
import ClassicEditorPage from './library/pages/editorpageclassic';
import ReportGeneratorPage from './library/pages/reportgeneratorpage';
import ReportRequestPage from './library/pages/reportrequestpage';
import { createElement } from './library/elements';
import { registerGizmos } from './library/dashboard/gizmomamanger';
import Loader from './library/loader';

import HomeIcon from "./library/images/icons/home.svg";
import DeviceIcon from "./library/images/icons/device.svg";
import DashboardIcon from "./library/images/icons/dashboard.svg";
import SettingsIcon from "./library/images/icons/accounts.svg";
import MapIcon from "./library/images/icons/map.svg";
import ChartIcon from "./library/images/icons/chart.svg";
import FillImage from "./library/images/fill_small.png";
import HMIIcon from './library/images//icons/hmi.svg';
import FolderIcon from './library/images/icons/folder.svg';
import AddIcon from './library/images/icons/add.svg';
import FrameParser from './library/frameparser';
import { PumpTwins } from './library/curves';
import Dropdown from './library/components/dropdown';
import Helper from './library/helper';
import './owner.css';
import View from './library/views/view';
import { DisconnectedView } from './library/views/disconnectedview';
import DebugPage from './library/pages/debugpage';
//import WidgetEditorPage from './library/pages/widgeteditorpage';
import { TagUnit, presetUnitMap } from './library/widgets/lib/tagunits';
import { GenericTreeable } from './library/views/treeview';
import PasskeyView from './library/views/passkeyview';
import WidgetEditorPage from './library/pages/widgeteditorpage';
import WidgetViewerPage from './library/pages/widgetviewerpage';
import { Tag } from './library/widgets/lib/tag';
import DashboardView from './library/views/dashboardview';
//import WidgetDashboardView from './library/views/widgetdashboardview';

// Define all our routes here
export enum Routes {
	Undefined = "undefined",
	Login = "login",
	Device = "device",
	Dashboard = "dashboard",
	Settings = "settings",
	Home = "",
	Custom = "custom",
	Creator = "create",
	Map = "map",
	Chart = "chart",
	Add = "add",
	Profile = "profile",
	Finder = "finder",
	Viewer = "hmi",
	Editor = "editor",
	ReportRequest = "reportreq",
	ReportGenerator = "reportgen",
	Debug = "debug",
	WidgetCreator = "widgetcreate",
	WidgetViewer = "viewer"
}

const RouteMap: Map<string, RouteInfo> = new Map([
	[Routes.Undefined, { displayName: 'Undefined', routeChangeCallback: (props) => {Owner.currentPage = new UndefinedPage(Owner.pageWrapper, props)}, icon: HomeIcon }],
	[Routes.Login, { displayName: 'Login', routeChangeCallback: (props) => {Owner.currentPage = new LoginPage(Owner.pageWrapper, props)}, propsChangeCallback: (props) => Owner.currentPage?.setProps(props), icon: HomeIcon }],
	[Routes.Device, { displayName: 'Sites', routeChangeCallback: (props) => {Owner.currentPage = new  DevicePage(Owner.pageWrapper, props)}, propsChangeCallback: (props) => Owner.currentPage?.setProps(props), icon: DeviceIcon }],
	[Routes.Dashboard, { displayName: 'Dashboard', routeChangeCallback: (props) => {Owner.currentPage = new DashboardPage(Owner.pageWrapper, props)}, propsChangeCallback: (props) => Owner.currentPage?.setProps(props), icon: DashboardIcon }],
	[Routes.Settings, { displayName: 'Admin', routeChangeCallback: (props) => {Owner.currentPage = new SettingsPage(Owner.pageWrapper, props)}, propsChangeCallback: (props) => Owner.currentPage?.setProps(props), icon: SettingsIcon }],
	[Routes.Home, { displayName: 'Home', routeChangeCallback: (props) => {Owner.currentPage = new ExplorerPage(Owner.pageWrapper, props)}, propsChangeCallback: (props) => Owner.currentPage?.setProps(props), icon: HomeIcon }],
	[Routes.Custom, { displayName: 'Custom', routeChangeCallback: (props) => {Owner.currentPage = new DashboardPage(Owner.pageWrapper, props)}, propsChangeCallback: (props) => Owner.currentPage?.setProps(props), icon: HomeIcon }],
	[Routes.Creator, { displayName: 'Create', routeChangeCallback: (props) => {Owner.currentPage = new EditorPage(Owner.pageWrapper, props)}, propsChangeCallback: (props) => Owner.currentPage?.setProps(props), icon: HMIIcon }],
	[Routes.WidgetCreator, 	{displayName: 'Widgets', routeChangeCallback: (props) => {Owner.currentPage = new WidgetEditorPage(Owner.pageWrapper, props)}, propsChangeCallback: (props) => Owner.currentPage?.setProps(props), 	icon: HMIIcon}],
	[Routes.Map, { displayName: 'Map',routeChangeCallback: (props) => {Owner.currentPage = new MapPage(Owner.pageWrapper, props)}, propsChangeCallback: (props) => Owner.currentPage?.setProps(props), icon: MapIcon }],
	[Routes.Chart, { displayName: 'Charts', routeChangeCallback: (props) => {Owner.currentPage = new ChartPage(Owner.pageWrapper, props)}, propsChangeCallback: (props) => Owner.currentPage?.setProps(props), icon: ChartIcon }],
	[Routes.Add, { displayName: 'Add', routeChangeCallback: (props) => {Owner.currentPage = new AddDevicePage(Owner.pageWrapper, props)}, propsChangeCallback: (props) => Owner.currentPage?.setProps(props), icon: HomeIcon }],
	//[Routes.Profile, { displayName: 'Profile', path: '/profile', page: ChartPage(Owner.pageWrapper, props), icon: HomeIcon }],
	[Routes.Finder, { displayName: 'Tags', routeChangeCallback: (props) => {Owner.currentPage = new FinderPage(Owner.pageWrapper, props)}, propsChangeCallback: (props) => Owner.currentPage?.setProps(props), icon: FolderIcon }],
	[Routes.Viewer, { displayName: 'HMI', routeChangeCallback: (props) => {Owner.currentPage = new DashboardViewerPage(Owner.pageWrapper, props)}, propsChangeCallback: (props) => Owner.currentPage?.setProps(props), icon: HMIIcon }],
	[Routes.Editor, { displayName: 'Dashboards', routeChangeCallback: (props) => {Owner.currentPage = new ClassicEditorPage(Owner.pageWrapper, props)}, propsChangeCallback: (props) => Owner.currentPage?.setProps(props), icon: DashboardIcon }],
	[Routes.ReportRequest, { displayName: 'ReportRequest', routeChangeCallback: (props) => {Owner.currentPage = new ReportRequestPage(Owner.pageWrapper, props)}, propsChangeCallback: (props) => Owner.currentPage?.setProps(props), icon: DashboardIcon }],
	[Routes.ReportGenerator, { displayName: 'ReportGenerator', routeChangeCallback: (props) => {Owner.currentPage = new ReportGeneratorPage(Owner.pageWrapper, props)}, propsChangeCallback: (props) => Owner.currentPage?.setProps(props), icon: DashboardIcon }],
	[Routes.Debug, { displayName: 'Debug', routeChangeCallback: (props) => {Owner.currentPage = new DebugPage(Owner.pageWrapper, props)}, propsChangeCallback: (props) => Owner.currentPage?.setProps(props), icon: DashboardIcon }],
	[Routes.WidgetViewer, 	{displayName: 'HMI', routeChangeCallback: (props) => {Owner.currentPage = new WidgetViewerPage(Owner.pageWrapper, props)}, propsChangeCallback: (props) => Owner.currentPage?.setProps(props), icon: HMIIcon}],
]);

class SubbedNodeArray extends Array<Node> {
	callback: (nodeCount: number) => void;
	constructor(callback: (nodeCount: number) => void) {
		super();
		this.callback = callback
	}

	push(...items: Node[]): number {
		let count = super.push(...items);
		this.callback(count);
		return count;
	}

	splice(start: number, deleteCount?: number): Node[] {
		let newArray = super.splice(start, deleteCount)
		this.callback(newArray.length);
		return newArray;
	}
}

export enum ApplicationType {
	BROWSER = 0,
	IOS_APP = 1,
	ANDROID_APP = 2
}

interface DashLookup {
	name: string;
	id: number;
	version: number;
}

class owner {
	currentRouteName: string;
	currentPage: Page; //TODO: Get rid of this eventually
	router: Router;
	settingsManager: SettingsManager;
	ldc: LiveDataClient;
	menuPanel: MenuPanel;
	navBar: NavBar;
	id: number;
	timeZone: TimeZone;
	sortedDevices: Device[] = [];
	alarmPanel: AlarmPanel;
	alarmSnackbar: AlarmSnackBar;
	fInitialized: boolean = false;
	selectedDevice: Device | null = null;
	jobCallbacks: any[] = [];
	groups: any;//FIXME: make a group interface and clean up these types
	groupFilters: any[] = [];//FIXME: make a group interface and clean up these types
	appliedGroupFilters: any[] = []; //FIXME: make a group interface and clean up these types
	fApp: boolean;
	applicationType: ApplicationType = ApplicationType.BROWSER; // Default to browser (not an Android or iOS app - app sets this in dart code at runtime)
	toolbar: Toolbar;
	modal: ViewModal<View> | null;
	colors: Colors;
	logoutTimer: LogoutTimer;
	fAudible: boolean = false;
	alarmManager: AlarmManager;
	dashboards: DashLookup[] = [];
	splash: HTMLElement;
	dialog: Dialog | undefined;
	pendingDialogs: Dialog[] = [];
	whatIf: boolean;
	connectingMessage: HTMLParagraphElement;
	returnToLogin: HTMLInputElement;
	applicationContext: number = 0;
	dropdown: Dropdown | undefined;
	helper: Helper | undefined;
	pageWrapper: HTMLElement;
	navWrapper: HTMLElement;
	toolWrapper: HTMLElement;
	menuWrapper: HTMLElement;
	alarmWrapper: HTMLElement;
	wrapper: HTMLElement;
	nodeCount: number = 0;
	fMobile: boolean;
	fReconnecting: boolean = false;
	companies: Company[];
	disconnectedModal: ViewModal<DisconnectedView>;
	fSuppressAlarms: boolean = false;
	banners: HTMLElement;
	constructor() { }

	initialize() {
		this.wrapper = createElement('div', 'owner__wrapper', document.body);
		this.wrapper.setAttribute("classic", "true");
		let pageColumn = createElement('div', 'owner__page-column', this.wrapper);
		this.banners = createElement('div', 'owner__page-banner', pageColumn);
		this.navWrapper = createElement('div', 'owner__page-top', pageColumn);
		let appRow = createElement('div', 'owner__app-row', pageColumn);
		this.menuWrapper = createElement('div', 'owner__page-menu', appRow);
		let appColumn = createElement('div', 'owner__app-column', appRow);
		this.alarmWrapper = createElement('div', 'owner__page-menu', appRow);
		this.pageWrapper = createElement('div', 'app', appColumn);
		this.toolWrapper = createElement('div', 'owner__page-tool', pageColumn);
		this.splash = createElement('div', 'page__loader', document.body);
		this.connectingMessage = createElement('p', 'page__connecting_text', this.splash, "Reconnecting you to live data...");
		this.colors = new Colors();
		this.ldc = new LiveDataClient();
		this.id = this.ldc.registerGraph(this);
		this.router = new Router((route, props) => this.onRouteChanged(route, props));
		this.router.navigate(location.hash);
		new Loader(this.splash);
		this.splash.style.display = 'none';
		this.connectingMessage.style.display = 'none';
		this.returnToLogin = createElement('input', 'se-button page__return_to_login', this.splash, '', { type: 'button', 'value': 'RETURN TO LOGIN' });
		this.returnToLogin.onclick = () => {
			this.ldc.pathStorage.deleteItem(this.ldc.refreshTokenName).then(() => {
				this.ldc.pathStorage.deleteItem(this.ldc.authTokenName).then(() => {
					window.location.reload();
				});
			});
		}
		createElement('img', 'hide', document.body, undefined, { 'src': FillImage, id: 'fill' });
		registerGizmos();
		window.addEventListener('resize', () => this.onResize());
	}

	onResize() {
		this.fMobile = innerWidth < 620;
		this.currentPage.resize()
	}

	updateSubCount(subCount: number) {
		this.menuPanel.subCount.textContent = subCount.toString();
	}

	onConnectionStatusChange(status: LoginStatus, freshTwoFaKey?: string, freshTwoFaCookie?: string) {	// Server connection status changed:
		this.currentPage.onConnectionStatusChanged(status, freshTwoFaKey, freshTwoFaCookie);
		switch (status) {
			case LoginStatus.LOGGED_IN:	// Success! Allow login page to change the styles and request the devices as it sees fit
				if (!(this.currentPage instanceof LoginPage))
					this.handleLogin();
				break;
			case LoginStatus.TWOFACTOR_INIT:
			case LoginStatus.TWOFACTOR_VERIFY:
			case LoginStatus.PASSWORD_RESET:
			case LoginStatus.LOGGED_IN_WITH_WEAK_PASSWORD:
			case LoginStatus.INVALID_PASSWORD: /*|| status == LoginStatus.LOGGED_OUT*/
			case LoginStatus.FORGOT_PASSWORD:
			case LoginStatus.FORGOT_PASSWORD_RESET:
			case LoginStatus.FORGOT_PASSWORD_RESET_SUCCESS:
			case LoginStatus.CONNECT_FAILED:
			case LoginStatus.CONNECTION_LOST: 	// Our socket just closed
			case LoginStatus.LOGGED_OUT:
			case LoginStatus.TIMED_OUT:
			default:
				break;
		}
		//	this.reset(); // Reset web page to initial login state
	}

	onGetJWTRefresh(token: any) {

	}

	onPasswordChangeResult(result: boolean) {
		if (this.currentPage instanceof LoginPage) {
			let currentPage = this.currentPage as LoginPage
			if (result)
				currentPage.processLoginStatus(LoginStatus.LOGGED_IN, '');	// We're logged in don't need to pass a new 2FA key if there was one
			else
				currentPage.displayPasswordChangeErrorModal();
		}
	}

	onUserPreferencesReceived(fp: FrameParser) {
		let preferences = fp.pop_string();
		console.log(preferences);
		this.ldc.user.updatePreferences(preferences);
		this.ldc.getGroups(this.id, this.ldc.user.companyKey);
	}

	handleLogin() {
		//this.ldc.setUserPreferences(this.id, JSON.stringify({theme: 'Dark'}))
		this.ldc.getGroups(this.id, this.ldc.user.companyKey)
		//this.ldc.getUserPreferences(this.id);
	}

	onGetDeviceInfo() {	// Received info on devices visible to logged-in user:
		this.ldc.getDashboards(this.id, this.ldc.user.companyKey);
	}

	onCompaniesReceived(companies) {		// Got the companies back
		assert(Array.isArray(companies), "onCompaniesReceived has a bad callback");
		companies.sort(((a, b) => {
			if (a.name < b.name)
				return -1;
			else if (a.name > b.name)
				return 1;
			else
				return 0;
		}));
		this.companies = companies;
		this.ldc.getDeviceInfo();								// Get devices
	}

	refreshDashboards() {
		this.ldc.getDashboards(this.id, this.menuPanel.getCompanyKey());
	}

	onDashboardsResponse(fp: FrameParser) {
		if (!this.fInitialized) {
			this.logoutTimer 				= new LogoutTimer();

			// Don't auto logout if we're operating as a local device interface
			if(process.env.TARGET == "localDevice")
				this.logoutTimer.stop();
			this.alarmManager 				= new AlarmManager();
			this.toolbar 					= new Toolbar(this.toolWrapper);
			this.menuPanel 					= new MenuPanel(this.menuWrapper); 							// create the menu side panel
			this.alarmPanel 				= new AlarmPanel(this.alarmWrapper);
			if (this.wrapper.getAttribute("classic") === "true")
				this.alarmSnackbar = new AlarmSnackBar(document.body);
			this.navBar = new NavBar(this.navWrapper); // create the top navigation bar
			this.settingsManager = new SettingsManager((pendingWrites) => {this.navBar.changeCount = pendingWrites.size}, this.banners);
			this.timeZone = new TimeZone();

			this.menuPanel.createRouteLink(getHash(Routes.Home), Routes.Home, RouteMap.get(Routes.Home)!);
			this.menuPanel.createRouteLink(getHash(Routes.Map), Routes.Map, RouteMap.get(Routes.Map)!);
			if (this.ldc.isAdmin())
				this.menuPanel.createRouteLink(getHash(Routes.Settings), Routes.Settings, RouteMap.get(Routes.Settings)!);
			this.menuPanel.createRouteLink(getHash(Routes.Chart), Routes.Chart, RouteMap.get(Routes.Chart)!);
			this.menuPanel.createRouteLink('', Routes.Device, RouteMap.get(Routes.Device)!, true);

			if (this.ldc.user.fTagConfig/* && this.ldc.user.fWizard*/)
				this.menuPanel.createRouteLink(getHash(Routes.Finder), Routes.Finder, RouteMap.get(Routes.Finder)!);
			this.menuPanel.createRouteLink(getHash(Routes.Viewer), Routes.Viewer, RouteMap.get(Routes.Viewer)!, true);
			this.menuPanel.createRouteLink(getHash(Routes.Editor), Routes.Editor, RouteMap.get(Routes.Editor)!, true);
			this.menuPanel.createTippys();

			this.sortedDevices = [];					// List of all devices sorted alphabetically

			for (let i = 0; i < this.ldc.devices.size(); ++i) {				// For each device
				this.sortedDevices.binsert(this.ldc.devices.at(i), (d1, d2) => d1.siteName.localeCompare(d2.siteName, undefined, { numeric: true }));	// Insert each device alphabetically
			}

			this.sortedDevices.forEach(device => {
				// store an array of groups that this device is in on the device object
				device.groups = this.groups.filter((group: GroupInfo) => { return group.devices.some((groupDevice) => groupDevice == device.key) });
				this.timeZone.getTimeZone(device.timeZone);
				this.menuPanel.createSubLink(Routes.Device, device.siteName, getHash(Routes.Device, { 'key': device.key, 'tab': 'Default', 'index': '0' }), undefined, device.key);
				device.requestNodeTree(this, () => { });
			});
		}

		this.menuPanel.clearSubLinks(Routes.Viewer);
		this.menuPanel.clearSubLinks(Routes.Editor);
		this.menuPanel.createSubLink(Routes.Viewer, 'New HMI', getHash(Routes.Creator), AddIcon);
		this.menuPanel.createSubLink(Routes.Editor, 'New Dashboard', getHash(Routes.Editor), AddIcon);
		let count = fp.pop_u32();
		let sortedDashboards: DashLookup[] = []
		for (let i = 0; i < count; i++) {
			let id = fp.pop_u32();
			let name = fp.pop_string();
			let version = fp.pop_u16();		// version
			fp.pop_string();	// creator
			let deviceCount = fp.pop_u32();
			let dashLookup = {
				name: name,
				id: id,
				version: version
			}
			this.dashboards.push(dashLookup);
			for (let j = 0; j < deviceCount; ++j) {
				let key = fp.pop_string();
				let device = this.ldc.devices.getByKey(key);
				if (device) {
					device.tree.nodes[0]?.dashboards.push(new GenericTreeable(name, device.tree.nodes[0], () => { return createElement('div') }, () => {
						let wrapper = createElement('div', 'full__width full__height');
						queueMicrotask(() => {
							new DashboardView(this.ldc, id).initialize(wrapper);
						})
						return wrapper}))
					device.dashboards.set(name, { version: version, id: id });
				}
			}
			sortedDashboards.binsert(dashLookup, (dash1, dash2) => dash1.name.localeCompare(dash2.name, undefined, { numeric: true }));	// Insert each key alphabetically
		}
		for (let dash of sortedDashboards) {
			let name = dash.name;
			let version = dash.version;
			let id = dash.id
			if (version > 1)
				this.menuPanel.createSubLink(Routes.Viewer, name, getHash(Routes.Viewer, { 'id': id.toString() }), undefined, id.toString());
			else
				this.menuPanel.createSubLink(Routes.Editor, name, getHash(Routes.Editor, { 'id': id.toString() }), undefined, id.toString());
		}
		if (!this.fInitialized) {
			//@ts-ignore
			if (typeof webkit !== 'undefined' && webkit.messageHandlers.callback)	// If we're running in an ios app, tell the app we got here
				//@ts-ignore
				webkit.messageHandlers.callback.postMessage('nodeTreeComplete');
			this.fInitialized = true;
			this.router.navigate(location.href);	// navigate to our desired route without changing the url
			// Hide loader page showing due to token login attempt
			this.splash.style.display = 'none';			// Hide the ripply loader thing
			this.connectingMessage.style.display = 'none';	// Hide the connecting message

			if (this.ldc.fUsedPasswordLogin) 			// Conditional prompt to register a passkey if they logged in with a password
				this.ldc.user.passKeys.register(false).then((success: boolean) => {
					if (success) { // If they successfully added one - open up passkey management and ask them to set a nickname
						new ViewModal(new PasskeyView(this.ldc.user.username, true), {
							maxWidth: '1000px',
							title: `${this.ldc.user.firstName} ${this.ldc.user.lastName}'s Passkeys`,
							titleTextColor: 'var(--color-inverseOnSurface)',
							titleBackgroundColor: 'var(--color-primary)',
						});
					}
					else // Let them know it didn't work
						new Dialog(document.body, {
							title: 'Error',
							body: 'Failed to register new passkey.'
						})
				});
		}
	}

	onGetGroupsResponse(groups: any) {
		this.groups = groups;
		this.groupFilters = [];
		this.appliedGroupFilters = [];
		for (let i = 0; i < this.groups.length; i++) {
			if (this.groups[i].name === '')
				continue;
			this.groupFilters.push({
				name: this.groups[i].name, filter: (device: Device) => {
					return device.isIncludedIn(this.groups[i])
				}
			})
		}
		if (this.ldc.user.fWizard)
			this.ldc.getCompanies(this.id);							// Load available companies
		else
			this.ldc.getDeviceInfo();								// Get devices
	}

	onRouteChanged(routeName: string, properties: { [key: string]: string }) {
		if (this.dialog) this.dialog.destroy();   // destroy any dialogs on the current route
		if (this.modal) this.modal.destroy();     // destroy any modals on the current route
		if (this.fInitialized && this.ldc.isLoggedIn()) { // check if we have a full-fledged ldc and if we are logged in
			let routeInfo = RouteMap.get(routeName)
			if (!RouteMap.has(routeName)) {            // if our path doesn't match any of our predefined routes
				let properties = {      // show a 404 dialog
					title: '404',
					body: 'The page you are looking for does not exist. Please check your url and try again.',
					buttons: [{ title: 'Return', callback: () => window.location.hash = getHash(Routes.Home) }]
				}
				new Dialog(document.body, properties);
				return;
			}
			else if (routeName === Routes.Login) { // if we are logged in and trying to reach login page, go home
				window.location.hash = getHash(Routes.Home);
				return;
			}
			else {
				if (routeName === this.currentRouteName)
					routeInfo?.propsChangeCallback && routeInfo.propsChangeCallback(properties)
				else {
					this.currentRouteName = routeName;
					routeInfo?.routeChangeCallback(properties);
				}
			}
		}

		else if (routeName === Routes.Login)
			RouteMap.get(routeName)?.routeChangeCallback(properties);
		else
			this.router.navigate(getHash(Routes.Login)); // if we're not logged in send user to login page

		if (this.menuPanel)
			this.menuPanel.onRouteChanged(routeName, properties);
	}

	addJobCompletedCallback(func: any) {
		// Pass in the function you want called upon the job's completion
		assert(typeof func == 'function', "Didn't pass a function into addJobCompletedCallback");
		for (var i = 0; i < this.jobCallbacks.length; ++i) {	// Check all the callbacks we have
			if (this.jobCallbacks[i] === null) {		// If one has been nulled out
				this.jobCallbacks[i] = func;			// Put this new function there
				return;
			}
		}
		this.jobCallbacks.push(func);	// No empty spaces in the array. Add them onto the end
	}

	onTagValueWritten(tag: Tag, value: any, caller: any) {
		//TODO: Settings manager callbacks
	}

	removeJobCompletedCallback(func: any) {
		for (var i = 0; i < this.jobCallbacks.length; ++i) {	// Check all the callbacks we have
			if (this.jobCallbacks[i] === func) {		// If this is the callback we want
				this.jobCallbacks[i] = null;			// Null out this callback
				return;
			}
		}
		assert(false, "Didn't find the job callback to remove");
	}

	isSmallScreen(): boolean {
		return window.innerWidth < 620;
	}

	onJobComplete() {	// Job completed:
		for (var i = 0; i < this.jobCallbacks.length; ++i) {
			if (this.jobCallbacks[i])			// It's possible callback has been nulled out
				this.jobCallbacks[i]();		// Call each callback
		}
	}

	reset() {		// Reset web page to initial state:
		location.reload();
	}

	changeTimeZone(zone: string) {
		switch (zone) {
			case 'UTC':
				this.timeZone.setTimeZone('UTC');
				break;
			case 'Local':
				this.timeZone.setTimeZone(this.timeZone.localZone);
				break;
			default:
				assert(this.selectedDevice, 'No selected device?');
				this.timeZone.setTimeZone(this.selectedDevice?.timeZone);
				break;
		}
		this.currentPage.onTimeZoneChanged()
		if (this.selectedDevice) {
			this.resetTimeNodes(this.selectedDevice.tree.nodes[0]!);
		}
	}

	onUnitSelectionChanged(selection: string) {
		for (let i = 0; i < this.sortedDevices.length; ++i) {
			let device = this.sortedDevices[i];
			if (device.tree) {
				device.tree.unitsMap = new Map(device.cachedUnitsMap); 	// reset each tree's unitsMap back to its original state
				presetUnitMap.get(selection)?.forEach((value, key) => { // for each of our key value pairs mapped from our selection
					device.tree.unitsMap.set(key, value);               	// set the units to the new setting
				})
				device.tree.refresh();
			}
			device.pumpTwins = new PumpTwins(device);
		}
		if (this.currentPage) {
			this.currentPage.refresh();    // just refresh our page (too complicated to try to find everywhere we may have listed a unit)
		}
	}

	resetTimeNodes(node: Node) {
		if (node.children) {
			for (var i = 0; i < node.children.length; ++i)
				this.resetTimeNodes(node.children[i]);
		} else if (node.units == TagUnit.TU_TIME)
			node._updateSubscribers();
	}
}

// singleton owner object
let Owner: owner = new owner();

export default Owner;