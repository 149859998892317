import Icon from '../../../../images/icons/name.svg';
import { RegisterWidget, Widget } from '../../../lib/widget';
import template from './pumptest.html';
import { Attribute } from '../../../lib/attributes';
import { TagAttribute, type TagDefinition } from '../../../lib/tag';
import { Role } from '../../../../role';
import { Carousel } from '../../../layout/carousel/carousel';
import { type PumpCurve } from '../pumpcurve/pumpcurve';

@RegisterWidget({tag: 'pump-test', template: template, displayName: 'Modal', icon: Icon, section: 'Layout'})
export class PumpTest extends Widget {
    @Attribute({displayName: 'Title Text'}) titleText: string = 'Title';
    @Attribute({displayName: 'Min Width'}) minWidth:              number;     // modal container minimum width style setting
    @Attribute({displayName: 'Max Width'}) maxWidth:              string;     // modal container maximum width style setting
    @Attribute({displayName: 'Min Height'}) minHeight:             string;     // modal container minimum height style setting
    @Attribute({displayName: 'Max Height'}) maxHeight:             string;     // modal container maximum height style setting
    @Attribute({displayName: 'Title Background'}) titleBackgroundColor:  string;     // title bar background color
    @Attribute({displayName: 'Title Text Color'}) titleTextColor:        string;     // title text color
    @Attribute({displayName: 'Is Animated'}) fAnimated:             boolean;    // flag for whether or not we want an animation on instantiation
    @Attribute({displayName: 'Prevent Close'}) fUncloseable:          boolean;
    @TagAttribute({displayName: 'Pump Folder', supportedRoles: [Role.ROLE_PUMP]}) pumpFolder: TagDefinition;
    @TagAttribute({displayName: 'Curve Timestamp Tag', shouldSubscribe: true}) curveTimestampTag: TagDefinition;

    @TagAttribute({displayName: 'Manual Test Tag',      shouldSubscribe: true, supportedRoles: [Role.ROLE_TLC_MANUAL_TEST_MODE]}) manualTestTag: TagDefinition;
    @TagAttribute({displayName: 'Manual Collect Tag',   shouldSubscribe: true, supportedRoles: [Role.ROLE_TLC_MANUAL_TEST_COLLECT_DATA]}) manualCollectTag: TagDefinition;
    @TagAttribute({displayName: 'Manual End Test Tag',  shouldSubscribe: true, supportedRoles: [Role.ROLE_TLC_MANUAL_TEST_END_TEST]}) manualEndTestTag: TagDefinition;
    @TagAttribute({displayName: 'Test Start Time Tag',  shouldSubscribe: true, supportedRoles: [Role.ROLE_TLC_TEST_START_TIME]}) testStartTimeTag: TagDefinition;
    @TagAttribute({displayName: 'Test Step Time Tag',   shouldSubscribe: true, supportedRoles: [Role.ROLE_TLC_TEST_STEP_TIME]}) testStepTimeTag: TagDefinition;
    @TagAttribute({displayName: 'Test Final Time Tag',  shouldSubscribe: true, supportedRoles: [Role.ROLE_TLC_TEST_COMPLETE_TIME]}) testFinTimeTag: TagDefinition;
    @TagAttribute({displayName: 'Test State Tag',       shouldSubscribe: true, supportedRoles: [Role.ROLE_TLC_TEST_STATE]}) testStateTag: TagDefinition;
    @TagAttribute({displayName: 'Pre Check Done Tag',   shouldSubscribe: true, supportedRoles: [Role.ROLE_TLC_PRE_CHECKLIST_DONE]}) preCheckDoneTag: TagDefinition;
    @TagAttribute({displayName: 'Post Check Done Tag',  shouldSubscribe: true, supportedRoles: [Role.ROLE_TLC_POST_CHECKLIST_DONE]}) postCheckDoneTag: TagDefinition;

    carousel: Carousel;

    protected enliven(): void {
        //this.systemStateNode		= this.nodeManager.addNodeByRole(this.pumpSystem,	Role.ROLE_SYSTEM_CURVE_STATE);
        let modelPump = this.pumpFolder.tag.parent.findChildByRole(Role.ROLE_MODEL_PUMPSYSTEM)?.findChild(this.pumpFolder.tag.name);
        if (!modelPump)
            throw(new Error('No model pump'));
        this.carousel = this.shadowRoot?.getElementById('carousel') as Carousel;
        this.carousel.onchange = () => this.onCarouselChanged(this.carousel.index);
        let introBody = this.shadowRoot?.getElementById('intro-body') as HTMLDivElement;
        let pumpName = this.pumpFolder.tag.getDisplayName(false, true);

        introBody.textContent = `This dialog will walk you through performing a test on ${pumpName}. ${pumpName} was last tested on ${this.curveTimestampTag.tag.getFormattedText(false)}. In this test, the DPO will vary this
        pump's speed to adjust flow and automatically collect data points. Please select either an automated or operator-guided test, and then click the right arrow button to proceed.`

        let curveWrapper = this.shadowRoot?.getElementById('curve-wrapper') as HTMLDivElement;
        let pumpCurve = document.createElement('pump-curve') as PumpCurve;
        pumpCurve.pumpFolder        = {tag: this.pumpFolder.tag};
        pumpCurve.modelFolder       = {tag: modelPump};

        curveWrapper.appendChild(pumpCurve);
    }

    /**
     * We just either went forward or back on our carousel
     * @param index the current index of the carousel
     */
    private onCarouselChanged(index: number) {

    }

}