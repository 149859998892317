import DesktopIcon          from "../images/icons/desktop.svg";
import PhoneIcon            from "../images/icons/smartphone.svg";
import PhoneIconLandscape   from "../images/icons/smartphone_landscape.svg";
import TabletIcon           from "../images/icons/tablet.svg";
import TabletIconLandscape  from "../images/icons/tablet_landscape.svg";

import View from "./view";
import { createElement } from "../elements";
import './dashboardview.css';
import EditorPage, { Dashboard } from '../pages/widgeteditorpage';
import { Gizmo } from '../dashboard/gizmos/gizmo';
import owner, { Routes } from "../../owner";
import LiveData from "../livedata";
import FrameParser from "../frameparser";
import Dialog from "../dialog";
import { RootElement } from "../widgets/hmiroot";
import HTMLSanitizer from "../htmlsanitizer";
import LiveDataClient from "../livedataclient";
import { getHash } from "../router/router";
interface MediaQuery {
    name: string;
    minW: number;
    maxW: number;
    minH: number;
    maxH: number;
    icon: string;
}

export const MediaQueries: MediaQuery[] = [
    {name: 'Desktop',           minW: 0,            maxW: Infinity,     minH: 0,            maxH: Infinity,     icon: DesktopIcon},
    {name: 'Portrait Tablet',   minW: 0,            maxW: 768,          minH: 0,            maxH: Infinity,     icon: TabletIcon},
    {name: 'Landscape Tablet',  minW: Infinity,     maxW: Infinity,     minH: 621,          maxH: 1024,         icon: TabletIconLandscape},
    {name: 'Portrait Mobile',   minW: 0,            maxW: 480,          minH: 0,            maxH: Infinity,     icon: PhoneIcon},
    {name: 'Landscape Mobile',  minW: Infinity,     maxW: Infinity,     minH: 0,            maxH: 620,          icon: PhoneIconLandscape},
];

export interface Report {
    name        : string;
    timestamp   : number;
	url			: string;
	element		: HTMLElement;
}

export default class WidgetDashboardView extends View {
    parent: HTMLElement;
    editor: EditorPage | undefined;
    doc: HTMLIFrameElement;
    wrapper: HTMLElement;
    id: number;
    graphID: number;
    dashboardWrapper: HTMLDivElement;
    currentQueryIndex: number;
    dashboard: Dashboard;
    sanitizer: HTMLSanitizer;
    reportsContainer: HTMLElement;
    reportsList: HTMLElement;
    reportsDisplay: HTMLElement;
    embed: HTMLEmbedElement;
    reports: Report[];
    onLoad: (()=>void) | undefined
    ldc: LiveDataClient;
    constructor(ldc: LiveDataClient, id: number, editor?: EditorPage, onLoad?: ()=>void) {
        super();
        this.editor     = editor;
        this.id         = id;
        this.ldc        = ldc;
        this.graphID    = ldc.registerGraph(this);
        this.onLoad     = onLoad;
        this.sanitizer  = new HTMLSanitizer(undefined, true);
    }

    initialize(parent: HTMLElement) {
        super.initialize(parent);
        this.wrapper            = createElement('div', 'dashboard-view__wrapper', this.parent);
        this.dashboardWrapper   = createElement('div', 'dashboard-view__iframe', this.wrapper);
        this.reportsContainer   = createElement('div', 'dashboard-view__report hide', this.wrapper); // Reports container starts our hidden
        this.reportsList  		= createElement('div', 'dashboard-view__report_list', this.reportsContainer);
        this.reportsDisplay  	= createElement('div', 'dashboard-view__report_display', this.reportsContainer);
        this.embed				= createElement('embed', 'dashboard-view__report_embed', this.reportsDisplay, undefined, {'type':'application/pdf'});
        this.currentQueryIndex  = this.getQuery();
        if (this.editor)
            this.dashboardWrapper.onscroll = () => this.editor?.overlay.resize()

        this.ldc.fm.buildFrame(LiveData.WVC_GET_DASHBOARD, undefined, this.graphID);
        this.ldc.fm.push_u32(this.id);
        this.ldc.send();
        this.fInitialized = true;
        return this;
    }

    onDashboardResponse(fp: FrameParser, fUncompressed: boolean = false) {
        let success = fp.pop_u8() == 1;
        if (success) {
            this.dashboard = {
                id:                 fp.pop_u32(),
                creator:            fp.pop_string(),
                name:               fp.pop_string(),
                version:            fp.pop_u16(),
                companyKey:         fp.pop_string(),
                fPrivate:           fp.pop_u8() == 1,
                fWrites:            fp.pop_u8() == 1,
                fReports:           fp.pop_u8() == 1,
                reportFrequency:    fp.pop_u8(),
                reportFrequencyMod: fp.pop_u16(),
                reportHourOffset:   fp.pop_u8(),
                reportSize:         fp.pop_u8(),
                reportTimezone:     fp.pop_string(),
                assets:             new Map(),
                sharedUsers:        new Map(),
                reportUsers:        new Map(),
                devices:            [],
                thumb:              '',
            };
            let dataSize        = fp.pop_u32();
            let jsonString      = '';
            let fCompressed     = this.dashboard.version >= 3 && !fUncompressed;
            let data: ArrayBuffer;
            if (fCompressed)
                data = fp.pop_buffer(dataSize);
            else
                jsonString = fp.pop_bytes(dataSize);
            let userCount = fp.pop_u16();
            for (let i=0;i<userCount;++i) {
                let username = fp.pop_string();
                this.dashboard.sharedUsers.set(username, {
                    fAccess: fp.pop_u8(),
                    fWrites: fp.pop_u8(),
                });
                this.dashboard.reportUsers.set(username, fp.pop_u8() == 1);
            }
            let assetCount       = fp.pop_u16();
            for (let i=0;i<assetCount;++i) {
                let uuid        = fp.pop_string();
                let url         = fp.pop_string();
                this.dashboard.assets.set(uuid, url);
            }
            let deviceCount = fp.pop_u32();
            for (let i=0;i<deviceCount;++i) {
                let key       = fp.pop_string();
                this.dashboard.devices.push(key)
            }
            //this.buildFromJSON(data);
            if (fCompressed) {
                //@ts-ignore
                const ds = new DecompressionStream("gzip");
                const stream = new Blob([data!]).stream();
                if (data!.byteLength > 0) {
                    const decompressedStream = stream.pipeThrough(ds);
                    new Response(decompressedStream).text().then((result) => {
                        this.sanitizer.setHTML(this.dashboardWrapper, result);
                        this.onLoad && this.onLoad();
                    })
                }
                else {
                    this.onLoad && this.onLoad();
                }
            }
            else {
                //this.sanitizer.setHTML(this.dashboardWrapper, jsonString);
                this.onLoad && this.onLoad();
            }
        } else {
            let dialogProperties = {
                title:  'Failed to Load Dashboard',
                body:   'The dashboard you requested could not be loaded. Please check your url, and make sure you have permissions to access this dashboard.',
                buttons: [{title:'Return',callback:()=>{window.location.hash = getHash(Routes.Home)}}]
            }
            new Dialog(document.body, dialogProperties);
        }
    }

    resizeGizmo(gizmo: Gizmo) {
        gizmo.recipe.children?.forEach(childRecipe => {
            this.resizeGizmo(childRecipe.gizmo!);
        })
        gizmo.onResize();
    }

    applyStylesRecursively(gizmo: Gizmo) {
        gizmo.recipe.children && gizmo.recipe.children.forEach(childRecipe => {
            this.applyStylesRecursively(childRecipe.gizmo!);
        })
        gizmo.applyStyles();
    }

    getQuery(): number {
        let width   = this.dashboardWrapper.clientWidth;
        let height  = this.dashboardWrapper.clientHeight;
        let currentQuery = 0;
        for (let i=0;i<MediaQueries.length;++i) {
            let query = MediaQueries[i];
            if (query.maxH >= height && query.maxW >= width && query.minH <= height && query.minW <= width)
                currentQuery = i;
        }
        return currentQuery;
    }

    showReports(): boolean {
        this.dashboardWrapper.classList.toggle('hide');
        let fShowingReports = !this.reportsContainer.classList.toggle('hide');
        if(fShowingReports && this.reports === undefined) {	// If we are now showing the reports container and we don't have history
            this.ldc.fm.buildFrame(LiveData.WVC_GET_USER_REPORTS, undefined, this.graphID);
            this.ldc.fm.push_u32(this.id);
            this.ldc.send();
            this.reports = [];	// We now have history
        }
        return fShowingReports;
    }

    onDashboardReportListResponse(fp: FrameParser) {
        let count = fp.pop_u32();
        for (let i = 0; i < count; ++i) {
            let name = fp.pop_string();
            let timestamp = fp.pop_u64();
            this.reports.push({
                name: name,
                timestamp: timestamp,
                url: fp.pop_string(),
                element: createElement('div', 'dashboard-view__report_label', undefined, name + ' - ' + (new Date(timestamp/1000).format("%yyyy/%MM/%dd %HH:%mm")))
            });
            let report = this.reports.back();
            report.element.onclick = () => fetch(report.url).then(async (response) => {this.embed.src = URL.createObjectURL(await response.blob())});
        }
        this.reports.sort((a, b) => b.timestamp - a.timestamp);	// Sort the reports so the newest reports are first (biggest timestamps first)
        for (const report of this.reports)				// Now attach each sorted element
			this.reportsList.append(report.element);
    }
}
