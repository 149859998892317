import * as echarts from 'echarts';

type EChartsOption = echarts.EChartsOption;
import { RegisterWidget, Widget } from "./lib/widget";
import { TagAttribute, TagSetAttribute } from "./lib/tag";
import type { TagDefinition, Tag } from './lib/tag';
import LineChartIcon from '../images/icons/tanklevel.svg';
import { Attribute } from './lib/attributes';

const stackID = 'tank';
const template = /*html*/`
<style>
:host {
    display: block;
    height: 400px;
    width: 400px;
}
.wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
}
</style>
<div class="wrapper"></div>
`.trim()

@RegisterWidget({tag: 'tag-tank', displayName: 'Tank', icon: LineChartIcon, section: 'Charts'})
export class Tank extends Widget {
    tank: echarts.ECharts;
    @Attribute({displayName: 'Background Color', typeModifier: 'color'}) backgroundColor: string = '#eeedee';
    @Attribute({displayName: 'Level Color', typeModifier: 'color'}) levelColor: string = '#64b5f6';

    @TagAttribute({displayName: 'Level Tag', shouldSubscribe: true}) levelTag: TagDefinition;
    @TagSetAttribute({displayName: 'Setpoint Tags', shouldSubscribe: true, isOptional: true}) setpointTags: TagDefinition[] = [];

    options: EChartsOption = {
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow'
            },
        },
        legend: {
            show: false
        },
        grid: [{
            left: 0,
            bottom: 0,
            top: 10,
            right: 72,
            containLabel: true,
            backgroundColor: this.backgroundColor,
            show: true
        }],
        xAxis: [
            {
            type: 'category',
            data: ['']
            }
        ],
        yAxis: [
            {
                position: 'right',
                axisTick: {
                    show: true,
                },
                type: 'value',
            }
        ],
        series: [
            {
                name: 'Level',
                type: 'bar',
                stack: stackID,
                color: this.levelColor,
                barWidth: '100%'
            }
        ],

        animation: false,
    };

    render(): Node | null {
        let templateElement = document.createElement('template');
        templateElement.innerHTML = template;
        return templateElement.content.cloneNode(true);
    }

    protected enliven(): void {
        let chartDom = this.shadowRoot?.querySelector('.wrapper') as HTMLDivElement;
        this.tank = echarts.init(chartDom);

        this.options.yAxis![0].min = this.levelTag.tag.engMin;
        this.options.yAxis![0].max = this.levelTag.tag.engMax;
        let markData: any[] = [];
        this.setpointTags.forEach(setpoint => {
            markData.push({
                lineStyle: {
                    width: 2,
                    type: 'solid',
                    color: 'black'
                },
                label: {
                    backgroundColor: 'black',
                    padding: 4,
                    color: 'white',
                    borderWidth: 2,
                    borderRadius: 5,
                    formatter: setpoint.tag.getDisplayName(false),
                    position: 'end',
                    fontFamily: 'Source Sans Pro'
                },
                symbol: 'none',
                yAxis: 14,
            })

        })
        this.options.series![0].markLine = {
            data: markData
        }

        this.tank.setOption(this.options);
    }

    update(tag: Tag): void {
        if (tag === this.levelTag.tag) {
            let level = this.levelTag.tag.getValue();
            this.options.series![0].data = [level]
        }
        else {
            let maxChar = 0;
            this.options.series![0].markLine.data = []
            this.setpointTags.forEach(setpoint => {
                let name = setpoint.tag.getDisplayName();
                maxChar = Math.max(maxChar, name.length);
                this.options.series![0].markLine.data.push({
                    lineStyle: {
                        width: 2,
                        type: 'dashed',
                        color: 'black'
                    },
                    label: {
                        backgroundColor: 'black',
                        padding: 4,
                        color: 'white',
                        borderWidth: 2,
                        borderRadius: 5,
                        formatter: setpoint.tag.getDisplayName(),
                        position: 'end',
                        fontFamily: 'Source Sans Pro'
                    },
                    symbol: 'none',
                    yAxis: setpoint.tag.getValue(),
                });
            });
            this.options.grid![0].right = maxChar * 6;
        }

        this.tank.setOption(this.options);
    }
}