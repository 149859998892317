import {Widget} from '../widget';
import SVGModal from './svgmodal'
import createSVGElement from '../svgelements';
import * as elements from '../elements';
import {Node} from '../node';
import LiveDataClient from '../livedataclient';
import assert from '../debug';
import {Device} from '../device';

//A driver is an object containing an attach(...) function.
//attach(... ,element) is responsible for setting up whatever internal objects
//are required in order to enliven an element.
//
//By default, we expect that most of the time this will need to be done by creating a driver object, hence the parent-class implementation of SVGWidget below (with a constructor, etc.) However, this need not always be the case!
//Sometimes, attach(...) may be a bare function that creates a structure of /other/ driver objects - in which case the object containing attach() should not extend SVGWidget!
//
// Does a check if a node error is not found to see if that error is not upheld later by some other widget with se-ignore-error[node path, most likely nothing]="true"

// Will make an attempt to refresh the values of the nodes subscribed to because of referencing another device's nodes
// in a device's page breaking if the other device disconnects and reconnects because of the deletion of the node tree.
// This should maybe be fixed elsewhere, where we might keep the old node tree around and just repopulate it when we a
// device reconnects.

// This will only be created once when asked for
var svgWidgetModal = undefined;

export default class SVGWidget extends Widget {

  //A (normal) driver is created with a nodeRoot that it should evaluate paths relative to, and also the element that it should be attached to.
  //This constructor isn't called directly by the enlivener - it's called by attach().
  //So, the arguments could really be anything you wanted.
  constructor(nodeRoot, element) {
    super(); //in derived classes, you have to call super before you use "this", in this case, Widget's constructor!
    this.nodeRoot = nodeRoot;
    this.element = element;
    this.nodesSubscribedTo = [];
    this.nodeInfo = {};			// Used later if a device disconnects and reconnects to get the device's new nodes

    this.registerAsWidget(element);
  }

  //The attach function is called by the enlivener as a constructor for some Widgets (bargraph) who don't create objects.
  //Normally we will want it to create exactly one driver object (this one),
  //but that may not always be the case.
  static attach(nodeRoot, element) {
    return new this(nodeRoot, element);
  }

  getSVGModal()
  {
    if(svgWidgetModal === undefined){
      svgWidgetModal = new SVGModal();
    }
    return svgWidgetModal;
  }

  //As per widget custom, destroy() unsubscribes from nodes so that the driver can be GC'd.
  destroy() {
    for (var i = 0; i < this.nodesSubscribedTo.length; i++) {
      if(this.nodesSubscribedTo[i].subscribers && this.nodesSubscribedTo[i].subscribers.has(this)) {
        this.nodesSubscribedTo[i].unsubscribe(this);
      }
    }
    this.unregisterAsWidget();
  }

  //Subscribes to a node and records in the driver that we have done so.
  subscribeToNode(node, name) {
    if (node === undefined) return; //node-not-found will return undefined out of the resolver.
    node.subscribe(this, this.element, 0);
	// Need to save the name of the node, its device's key, and the relative path of the node.
	this.nodeInfo[name] = {dev: node.tree.device.key, path: node.getDeviceRelativePath()};
    this.nodesSubscribedTo.push(node); //show red X for all quality flags by default
    return node;
  }

  //Loads all of our nodes into a node object
  //returns the result of validateNodes
  setupNodes(requiredNodeIDs, dontSubscribe) {
    this.nodes = {}
    this.setupDevices();
    this.nodePaths = SVGWidget.readAttributes(this.element, "seNode");
    if(this.devices && this.treesComplete())
      this.addNodes(requiredNodeIDs, dontSubscribe);
    else {
		// Will ignore errors on nodes that have data-se-ignore-error-NODE_NAME="true"
		// Useful if the errors are handled by the individual widget.
      for(var name in this.nodePaths) {
        if (!SVGWidget.readFlag(this.element, "seIgnoreError" + name, "false")) {
	        this.informNodeNotFound(undefined, this.nodePaths[name]);
		}
	}
      let that = this;
      this.nodeTreeTimer = setInterval(function(){
        if(that.treesComplete()) {
          let status = that.addNodes(requiredNodeIDs, dontSubscribe);
          clearInterval(that.nodeTreeTimer);
          return status;
        }
      }, 250, that, requiredNodeIDs, dontSubscribe);
    }
  }

  addNodes(requiredNodeIDs, dontSubscribe) {
    for (var name in this.nodePaths) {
      this.nodes[name] =
        SVGWidget.readAndResolveNodePath(this.element,
          this.nodeRoot, "seNode" + name);
    }
    // If we found nodes and we weren't specifically told to skip subscription step, then subscribe
    let status = this.validateNodes(requiredNodeIDs, this.nodePaths);
    if (status && !dontSubscribe) {
      for (var name in this.nodes) {
        this.subscribeToNode(this.nodes[name], name);
      }
    }
    return status;
  }
	// Need this to handle when a device's tree is complete, possibly after disconnecting and reconnecting
	// to ensure that all of the device's nodes used by this widget are updated to the new tree
	handleTreeComplete(device) {
        for (var name in this.nodePaths) {
			// Iterate through all nodes
            let node = this.nodes[name];
            if (node && node.tree && node.tree.device && node.tree.device.key === device.key) {
				// if the current node exists and the device keys are the same, unsubscribe if needed
                if (node.subscribers && node.subscribers.has(this)) {
                    node.unsubscribe(this);
            	    assert(device.isTreeComplete(), "Tree not complete!!");
				}
			}
			let nodeInfo = this.nodeInfo[name];
			// If the device key of the nodeInfo matches the device key of the recently reconnected device,
			// subscribe to that device's node.
			if (nodeInfo && nodeInfo.dev === device.key) {
				// Need a "/" because if refering to the root node of a device, getDeviceRelativePath
				// will return "" and not "/" like you would expect
   	            this.nodes[name] = device.tree.findNode((nodeInfo.path.length <= 0) ? "/" : nodeInfo.path);
                this.subscribeToNode(this.nodes[name], name);
            }
        }
	}

	// Handle when device (re)connects
	handleDeviceConnect(device) {
		device.onDisconnect.set(this, this.handleDeviceDisconnect);
		device.onConnect.delete(this);
		device.requestNodeTree(this, this.handleTreeComplete);
	}

	// Handle a device disconnecting
	handleDeviceDisconnect(device) {
		device.onConnect.set(this, this.handleDeviceConnect);
		device.onDisconnect.delete(this);
	}

  setupDevices(requiredNodeIDs, dontSubscribe) {
    this.devices = {}
    let nodePaths = SVGWidget.readAttributes(this.element, "seNode");

    for (var name in nodePaths) {
      this.devices[name] =
        SVGWidget.readAndResolveNodePath(this.element,
          this.nodeRoot, "seNode" + name, true);
		// Find the device, if possible. readAndResolveNodePath could return LiveDataClient though,
		// so need to check if its a device after the following if statement
		let device = this.devices[name];
		if (device instanceof Node)
			device = device.tree.device;	// Node -> Device
		// Set up handling of device's disconnect and connect
		if (device instanceof Device && device.connected)
			device.onDisconnect.set(this, this.handleDeviceDisconnect);
		else if (device instanceof Device)
			device.onConnect.set(this, this.handleDeviceConnect);
    }
    return this.devices;
  }

  treesComplete() {
    for(var name in this.devices) {
		// Remove 'this.devices[name] &&' to make sure that all devices exist and otherwise will get an error
		// Handle if name refers to an LDC instead of a device
		let isLdc = (this.devices[name] instanceof LiveDataClient);
		let devs = (isLdc) ? this.devices[name].devices : [];
		let ret = false, i = 0;
		for (; i < devs && !ret; i++) {
			ret = !(devs[i].tree.isComplete());
		}
		if (ret) return false;
		else if (isLdc)
			continue;			// Could probably break here.
		// Now that we know it can't be an LDC, check if the device's tree is complete.
      if(this.devices[name] && this.devices[name].tree.isComplete())
        continue;
      else return false;
    }
    return true;
  }

  //Called by a constructor when it doesn't have the nodes it wants.
  informNodeNotFound(nodeID, path) {
    this.postError("Tag not found: " + path);
  }

	// message- message that you want displayed when hovering over the red 'X'
	// element- the element you want to cover, default: this.element
	// strokeWidth- the width of the red 'X''s stroke. Default 20% width & height of element
  postError(message, element, strokeWidth) {
    if (element == undefined)
        element = this.element;
    let bbox = element.getBoundingClientRect();
    if (element.getBBox)
      bbox = element.getBBox();

    //Find the nearest element capable of having an SVG child.
    let current = element;
	let tName = current.tagName.toLowerCase();
    while (tName !== "g" && tName !== "svg" && tName !== "div" && tName !== "body") {
		current = current.parentNode;
		tName = current.tagName.toLowerCase();
    }
    let parent = current;
    let elem = this.makeRedX(parent, element, bbox, strokeWidth);

    elem.setAttribute("title", message);

    /*
    tippy(elem, {
      position: 'right',
      animation: 'shift',
      duration: 60,
      arrow: true,
      theme: 'light',
      trigger: 'click mouseenter',
    });
    */

  }

	// parent- the parent of the new SVG element
	// element- the element you want to cover, default: this.element
	// bbox- the bounds of what we want to cover
	// strokeWidth- the width of the red 'X''s stroke. Default 20% width & height of element
  makeRedX(parent, element, bbox, strokeWidth) {
    // Compute red x coordinates to cover 90% of the parent element:

	const defaultWidth = 10;
	const offsetFromEnds = 0.05;
	var x1 = defaultWidth * offsetFromEnds;
	var x2 = defaultWidth * (1 - offsetFromEnds);
	var xc = defaultWidth / 2;
	var height = (bbox.height/bbox.width * defaultWidth);
	if (isNaN(height)) {
		height = defaultWidth;
	}
	var y1 = x1*height/defaultWidth;
	var y2 = x2*height/defaultWidth;
	var yc = xc*height/defaultWidth;

    if (strokeWidth == undefined) {
		const defaultStrokeWidth = 2;
		// Don't want the stroke width to be super huge when at a shallow angle
		// or very large when at a deep angle either
		if (height > defaultWidth) {
			strokeWidth = defaultStrokeWidth * defaultWidth / height;
		}
		else {
			strokeWidth = defaultStrokeWidth * height / defaultWidth;
		}
    }

	var redXGroup = null;
	if (parent instanceof SVGElement) {
		// Different if dealing with SVG vs HTML element.

		redXGroup = createSVGElement('g', null, parent, {});

		SVGWidget.extractSVGTransformListFrom(parent, element, redXGroup.transform.baseVal);
	}
	else {
		assert(parent instanceof HTMLElement, "Proposed red 'X's parent is neither SVG nor HTML Element! Don't know what to do.");
		assert(element instanceof HTMLElement, "Red 'X's base element is likely an SVG, but its parent is not!");

		let parRect = parent.getBoundingClientRect();
		let top = bbox.top - parRect.top, left = bbox.left - parRect.left;

		redXGroup = elements.createElement('div', null, parent);
		redXGroup.setAttribute("style", "position:absolute;top:" + top + ";left:" + left);	// Position appropriately
	}

	bbox.viewBox = "0 0 " + defaultWidth + " " + height;

    element._redX = createSVGElement('svg', null, redXGroup, bbox);

	// Create a better(TM) red 'X'. This one won't have the patch in the middle that is darker than the rest of the 'X'
	// It should also have a somewhat dynamic stroke-width that doesn't get too ridiculous when looking at a high-ratio rectangle
	// (very un-square rectangle, i.e. the ratio of the larger side to the shorter side is very large).
	createSVGElement('path', null, element._redX, {
		d: "M " + x1 + " "  + y1 + " L " + xc + " " + yc + " L " + x2 + " " + y1 + " L " + xc + " " + yc + " L " + x2 + " " + y2 + " L " + xc + " " + yc + " L " + x1 + " " + y2 + " L " + xc + " " + yc + " Z",
		'stroke-width': strokeWidth,
		stroke: 'rgba(255,0,0,0.5)',
		'stroke-linecap':'butt',
	});

    return element._redX;
  }

  //Checks for correct nodes and calls informNodeNotFound if missing
  validateNodes(requiredNodeIDs, nodePaths) {
    for (let name in this.nodes) {
      if (!this.nodes[name]) { //undefined -> unresolvable path
		if (!SVGWidget.readFlag(this.element, "seIgnoreError" + name, "false")) {
	        this.informNodeNotFound(name, nodePaths[name]);
		}
        return false;
      }
    }

    if (requiredNodeIDs) {
      for (let i = 0; i < requiredNodeIDs.length; i++) {
        //all requiredNodeIDs must be in this.nodes's keys
        if (!(requiredNodeIDs[i] in this.nodes)) {
          this.informNodeNotFound(name, nodePaths[name]);
          return false;
        }
      }
    }
    return true;
  }


  ////////////////////////////////////
  // HELPER FUNCTIONS
  //   Make our documented standard
  //   easier to stick to by factoring
  //   format-related things into
  //   functions of their own

  //READ ATTRIBUTE
  //Reads a string from a dataset attribute and handles error reporting.
  //Note: here, like in all reader helpers, defaultValue should be a string!
  static readAttribute(element, name, defaultValue) {
    var value = element.dataset[name];
    if (value === undefined) { //if the data-se-<name> attribute has not been set
      if (defaultValue !== undefined) {
        return defaultValue; //If there's a default, return it.
      }
      //otherwise, report the error
      console.log("Error: Element", element, "lacks attribute data-se-" + name, ", even though it was expected by the driver.");
      return;
    }
    //if a string was found, return it.
    return value;
  }

  //READ ATTRIBUTES
  //Reads multiple attributes using underscore notation.
  //Stores them in an object.
  static readAttributes(element, prefix, defaultValue) {
    var prefix = prefix
                  ? prefix
                  : "seNode";
    var result = {};
    for (var name in element.dataset) {
      if (name.substr(0, prefix.length) === prefix) {
        var ID = name.substr(prefix.length);
        result[ID] = SVGWidget.readAttribute(
          element, prefix + ID, defaultValue);
      }
    }
    return result;
  }

  //READ FLAG
  //Reads a boolean flag. "t" is considered true, and "f" false.
  //If a default is set, it will be returned if the attribute is not found.
  //Flags which are not "t" or "f" will be treated as unrecognised (an error condition).
  //Note: the only reason this isn't declared as static is because I want to call it with 'this'.
  static readFlag(element, name, defaultValue) {
    var value = SVGWidget.readAttribute(element, name, defaultValue);
    if (value === undefined) return;
    if (value === null) return null;

    //ensure case-insensitivity
    value = value.toLocaleLowerCase();

    if (value === 'f' || value === 'false') //false flag
      return false;
    else if (value === 't' || value === 'true') //true flag
      return true;

    //other strings are an error conditon
    else {
      console.log("Error: Element", element, "has attribute data-se-" + name, " set to", value, "which is not recognised.");
      return;
    }
  }

  //READ ENUM
  //Reads a string and (case-insensitively) accepts it only if it is in the list of acceptable values.
  //NOTE: the elements in acceptableValues _must_ all be lower-case.
  static readEnum(element, name, defaultValue, acceptableValues) {
    var value = SVGWidget.readAttribute(element, name, defaultValue);
    if (value === undefined) return;
    if (value === null) return null;

    //ensure case-insensitivity
    value = value.toLocaleLowerCase();

    if (acceptableValues.indexOf(value) !== -1) { //value found in enum
      return value;
    } else { //unrecognised value
      console.log("Error: Element", element, "has attribute data-se-" + name, " set to", value, "which is not recognised.");
      return;
    }
  }

  //READ NUMBER
  //Reads a string and calls parseFloat. If it succeeds, returns the number
  //NOTE: the elements in acceptableValues _must_ all be lower-case.
  static readNumber(element, name, defaultValue) {
    var value = SVGWidget.readAttribute(element, name, defaultValue);
    if (value === undefined) return;
    if (value === null) return null;

    //attempt to parse number
    var number = parseFloat(value);

    if (!isNaN(number)) { //it is, in fact, a number
      return number;
    } else { //unrecognised value
      console.log("Error: Element", element, "has attribute data-se-" + name, " set to", value, "which could not be parsed as a number.");
      return;
    }
  }

  //READ AND RESOLVE NODE PATH
  //Reads a node path and resolves it into a node, from the given nodeRoot.
  static readAndResolveNodePath(element, nodeRoot, name, fDeviceLocate) {
    var nodePath = SVGWidget.readAttribute(element, name);
    if (nodePath === undefined) return;
    let ldc = null;
    if (nodeRoot instanceof LiveDataClient) {
      ldc = nodeRoot;
    } else if (nodeRoot instanceof Node) {
      ldc = nodeRoot.tree.device.ldc;
    } else if (nodeRoot !== null) { // This case shouldn't happen
      assert(false, "nodeRoot not set to LDC or node!");
    }
    else {
      assert(false, "nodeRoot is null... trying to navigate to unconnected device?");
      return null;
    }
    var node = ldc.resolvePath(nodeRoot, nodePath, element._environment, fDeviceLocate);

    if (!node) {
      console.log("ERROR: Node path not found:",
        nodePath, element._environment);
      return null;
    }
    return node;
  }

  //Finds the HTML element that contains the SVG widget.
  //See below for major use case.
  // todo?: refactor SVGWidget and enlivener so that the
  //   enlivener always knows the containing elements and
  //   the SVGwidgets retain a reference to their enlivener.
  static findFirstHTMLParent(element) {
    let current = element;
    while (current instanceof SVGElement)
      current = current.parentNode;
    return current;
  }


  //Used for when we need to position an HTML element over
  //an SVG element, but as a child of the HTML containing
  //the SVG. (This is important because you can't put a canvas
  //  or any other HTML elements inside of an SVG group.)
  //
  //moves the passed-in element in place and returns the rect
  static positionHTMLElement(element, htmlParent, svgPositioner) {
    var elemRect = svgPositioner.getBoundingClientRect();
    var parentRect = htmlParent.getBoundingClientRect();

    var rect = {
      top: elemRect.top - parentRect.top,
      left: elemRect.left - parentRect.left,
      height: elemRect.height,
      width: elemRect.width
    }

	if (element.absolutePos === undefined || element.absolutePos)	// Check if we want to position absolutely, and by default positioning absolutely
		element.style.position = 'absolute'; 						//positon select at svg element
    element.style.top = rect.top.toString() + "px";
    element.style.left = rect.left.toString() + "px";
    element.style.width = rect.width.toString() + "px";
    element.style.height = rect.height.toString() + "px";

    return rect;
  }
	// Extract the SVG Transform List information from the DOM by going from the element we want to overlay
	// to the closest ancestor to that element where we can add stuff. parentElem is intended to be that
	// closest ancestor, but that is not necessarily true. The parentElem just needs to be AN ancestor.
	// The childElem is the element which we walk back up to the parentElem with, accumulating the
	// transform operations into the transformList. childElem and parentElem must both be SVGElements, with
	// only SVGElements between them. Could in theory allow transition between SVG and HTML, but that would
	// likely require some funky unit conversions between whatever is native to the SVG to some HTML element,
	// and could end up transforming outside of the SVG's boundary, thereby appearing to do nothing
	static extractSVGTransformListFrom(parentElem, childElem, transformList) {
		let retV = transformList;
		while (childElem != parentElem && childElem != null && childElem instanceof SVGElement) {
			let tList = childElem.transform.baseVal;
			let index = tList.numberOfItems - 1;		// Iterate backwards and add backwards through the list to
			while (index >= 0) {						// ensure that the transforms are applied in apporopriate order
				if (retV.numberOfItems == 0) {
					retV.appendItem(tList.getItem(index));
				}
				else {
					retV.insertItemBefore(tList.getItem(index), 0);
				}
				index--;
			}
			childElem = childElem.parentNode;
		}
		assert(childElem == parentElem, "The 'parent' element was not a parent of the passed 'child' element!!!");
		return retV;
	}

}
