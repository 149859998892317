import { Attribute, ColorAttribute } from "../../lib/attributes";
import { TagAttribute } from "../../lib/tag";
import { RegisterWidget, Widget } from "../../lib/widget";
import type { Tag, TagDefinition } from "../../lib/tag";
import template from './lineargauge.html'
import { AlarmConditions, type ConfiguredAlarm } from "../../../alarm";

export enum IndicatorDirection {
    TO_MIN = "min",
    TO_MAX = "max",
    TO_BAR = "bar"
}
/**
 * Base Linear Gauge
 */
@RegisterWidget({tag: 'linear-gauge', displayName: 'Linear Gauge', template: template})
export class LinearGauge extends Widget {
    protected scale: HTMLElement;
    protected level: HTMLElement;
    protected wrapper: HTMLElement;
    protected minLabel: HTMLElement;
    protected maxLabel: HTMLElement
    protected indicators: HTMLElement;

    protected min: number;
    protected max: number;

    @Attribute({displayName: 'Level Color'}) levelColor: ColorAttribute = new ColorAttribute('#0047AB');
    @Attribute({displayName: 'Background Color'}) backgroundColor: ColorAttribute = new ColorAttribute('#ffffff');
    @Attribute({displayName: 'Vertical'}) isVertical: boolean = false;
    @Attribute({displayName: 'Show Ticks'}) showTicks: boolean = false;
    @Attribute({displayName: 'Tick Count'}) tickCount: number = 4;
    @Attribute({displayName: 'Min'}) minValue: number;
    @Attribute({displayName: 'Max'}) maxValue: number;
    @Attribute({displayName: 'Show Labels'}) showLabels: boolean =  true;

    protected connectedCallback(): void {
        this.scale = this.shadowRoot?.getElementById('scale')!;
        this.level = this.shadowRoot?.getElementById('level')!;
        this.wrapper = this.shadowRoot?.getElementById('wrapper')!;
        this.minLabel = this.shadowRoot?.getElementById('min')!;
        this.maxLabel = this.shadowRoot?.getElementById('max')!;
        this.indicators = this.shadowRoot?.getElementById('indicators')!;
        if (this.isVertical) {
            this.wrapper.classList.add('vertical');
        }
        if (this.showTicks)
            for (let i=0;i<this.tickCount;++i) {
                let tick = document.createElement('div');
                tick.classList.add('tick');
                this.scale.appendChild(tick);
            }
        this.style.setProperty('--label-display', this.showLabels ? 'flex' : 'none')
    }

    protected enliven(): void {
        this.style.setProperty('--level-color', this.levelColor.color);
        this.style.setProperty('--background-color', this.backgroundColor.color);
        this.min = this.minValue ?? 0;
        this.max = this.maxValue ?? 100;
    }

    public addIndicator(value: number, color: string, direction: IndicatorDirection) {
        let alarmIndicator = document.createElement('div');
        alarmIndicator.classList.add('indicator');
        alarmIndicator.classList.add(direction);
        alarmIndicator.style.setProperty('--indicator-color', color);
        let offset = `${(value - this.min) / (this.max - this.min) * 100}%`
        if (this.isVertical)
            alarmIndicator.style.top = offset;
        else
            alarmIndicator.style.left = offset;
        this.indicators.appendChild(alarmIndicator);
    }
}

/**
 * Base Linear Gauge
 */
@RegisterWidget({tag: 'tag-linear-gauge', displayName: 'Linear Gauge', template: template})
export class TagLinearGauge extends LinearGauge {
    @TagAttribute({
        displayName: 'Value Tag',
        supportedTypes: ['numeric'],
        shouldSubscribe: true
    }) valueTag: TagDefinition;

    @TagAttribute({
        displayName: 'Value Tag',
        supportedTypes: ['numeric'],
        shouldSubscribe: true,
        isOptional: true
    }) minValueTag: TagDefinition;

    @TagAttribute({
        displayName: 'Value Tag',
        supportedTypes: ['numeric'],
        shouldSubscribe: true,
        isOptional: true
    }) maxValueTag: TagDefinition;

    protected enliven(): void {
        this.style.setProperty('--level-color', this.levelColor.color);
        this.style.setProperty('--background-color', this.backgroundColor.color);
        let tag = this.valueTag.tag;
        this.min = this.minValue ?? tag.engMin ?? 0;
        this.max = this.maxValue ?? tag.engMax ?? 100;
        this.minLabel.textContent = this.min.toFixed(0);
        this.maxLabel.textContent = this.max.toFixed(0);
    }

    onConfiguredAlarm(tag: Tag, configuredAlarm: ConfiguredAlarm, fAdded: boolean): void {
        if (configuredAlarm.fDisabled)
            return;
        switch(configuredAlarm.type) {
            case AlarmConditions.HIGH:
            case AlarmConditions.VERY_HIGH:
                this.addIndicator(configuredAlarm.setting, configuredAlarm.fActive ? 'var(--color-alarm-1)' : 'var(--color-gray-6)', IndicatorDirection.TO_MIN);
                break;
            case AlarmConditions.LOW:
            case AlarmConditions.VERY_LOW:
                this.addIndicator(configuredAlarm.setting, configuredAlarm.fActive ? 'var(--color-alarm-1)' : 'var(--color-gray-6)', IndicatorDirection.TO_MAX);
        }
    }



    update(tag: Tag): void {
        let value = this.valueTag.tag.getValue(this) as number;
        let min = this.minValue ?? tag.engMin ?? 0;
        let max = this.maxValue ?? tag.engMax ?? 100
        if (this.minValueTag)
            min = this.minValueTag.tag.getValue(this) as number;
        if (this.maxValueTag)
            max = this.maxValueTag.tag.getValue(this) as number
        this.style.setProperty('--level-value', (value - min) / (max  - min) * 100 + '%');
    }
}

