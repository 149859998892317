import ArrowIcon                from "../images/icons/arrow_down_filled.svg";
import AlignVTopIcon            from "../images/icons/align_vertical_top.svg";
import AlignVCenterIcon         from "../images/icons/align_vertical_center.svg";
import AlignVBottomIcon         from "../images/icons/align_vertical_bottom.svg";
import AlignVDIcon              from "../images/icons/align_vertical_distribute.svg";
import AlignHDIcon              from "../images/icons/align_horizontal_distribute.svg";
import AlignHCenterIcon         from "../images/icons/align_horizontal_center.svg";
import AlignHRightIcon          from "../images/icons/align_horizontal_right.svg";
import AlignHLeftIcon           from "../images/icons/align_horizontal_left.svg";
import AlignSpaceIcon           from "../images/icons/align_space_around.svg";
import GridIcon                 from "../images/icons/layout_grid.svg";
import DisplayBlockIcon         from "../images/icons/display_block.svg";
import DisplayInlineBlockIcon   from "../images/icons/display_inline_block.svg";
import DisplayFlexIcon          from "../images/icons/display_flex.svg";
import HiddenIcon               from "../images/icons/hidden.svg";

import { createElement } from '../elements';
import View from "./view";
import Switch from "../components/switch";
import owner from "../../owner";
import { RadioButton, RadioSelector } from '../components/radio';
import { TabManager } from '../components/tabmanager';
import assert from "../debug";
import tippy from 'tippy.js';
import Dropdown from '../components/dropdown';
import ViewModal from "../viewmodal";
import ImageBrowserView from "./imagebrowserview";
import { Widget } from "../widgets/lib/widget"
import { AttributeMetadata, ExtendedAttributeMetadata, RangeAttribute, RangeProperties } from "../widgets/lib/attributes";
import WidgetEditorPage from "../pages/widgeteditorpage";

/**
 * DashboardSettingViews are used to create an interface for editing generic string-string key-value pairs.
 */
export class DashboardSettingView extends View {
    parent:     HTMLElement;
    settings:  {[key: string]: string};
    defaultSettings: {[key: string]: string};
    inputs:     SettingInput[] = [];
    editor:     WidgetEditorPage;
    sectionMap: Map<string, HTMLElement> = new Map();
    autoSettings: {[key: string]: string};
    constructor() {
        super();
    }

    initialize(parent: HTMLElement): View {
        super.initialize(parent);
        this.fInitialized = true;
        return this;
    }

    /**
     * Sets the string-string key-value pair to be edited.
     * @param newSettings       // The key value pair that we are interested in editing
     * @param sections          // Array of names of the settings allowed for newSettings
     * @param defaultSettings   // A default string-string key value pair that will show its values, but won't be editable
     */
    setSettingsToEdit(newSettings: {[key: string]: string}, defaultSettings: {[key: string]: string} = {}, autoSettings: {[key: string]: string} = {}): void {
        assert(newSettings);
        this.settings           = newSettings;
        this.defaultSettings    = defaultSettings;
        this.autoSettings       = autoSettings;
        this.updateSettings();
    }

    /**
     * Calls each input's update method
     */
    updateSettings(): void {
        for (let i=0;i<this.inputs.length;++i) {
            this.inputs[i].update();
        }
    }

    /**
     * Returns a collapsible HTMLElement section
     * @param parent
     * @param name
     * @param callback
     * @param switchState
     * @param switchColor
     * @returns
     */
    createSection(parent: HTMLElement, name: string, callback?:(state: boolean)=>void, switchState?: boolean, switchColor?: string) : HTMLElement {
        this.wrapper         = createElement('div', 'editor__settings__wrapper', parent);
        let titleContainer = createElement('div', 'editor__container__setting', this.wrapper);
        createElement('img', 'editor__container__setting__arrow', titleContainer, '', {'src':ArrowIcon});
        createElement('div', 'editor__container__setting__title', titleContainer, name);
        if (callback) {
            new Switch(titleContainer, switchState, switchColor && 'var(--color-primary)', (state: boolean)=>callback(state))
        }
        return this.wrapper;
    }

    /**
     * Each settings view needs its own apply method
     */
    apply() {
        assert(false, "Dashboard settings views must implement their own apply method");
    }

    hideSections() {
        for (let [category, section] of this.sectionMap) {
            section.classList.toggle('hide', true);
        }
    }
}

export enum SerializedTagCategories {
    PATH        = "PATH",
    CHART       = "CHART",
    NAME        = "NAME",
    VISIBILITY  = "VISIBILITY",
    HOA         = "HOA"
}

export class AttributeEditorView extends DashboardSettingView {
    attributeChangeCallback: (attributes: {[key: string]: string})=>void;
    constructor(attributeChangeCallback: (attributes: {[key: string]: string})=>void) {
        super();
        this.attributeChangeCallback = attributeChangeCallback;
    }

    initialize(parent: HTMLElement) {
        super.initialize(parent);
        this.fInitialized = true;
        return this;
    }

    populateSettings(attributes?: Readonly<Map<string, ExtendedAttributeMetadata>>): void {
        this.parent.removeChildren();
        this.inputs = [];
        if (attributes)
        {
            for (let [attrName, setting] of attributes)
            {
                switch(setting.type)
                {

                    case "Boolean":
                        this.inputs.push(new ToggleInput(setting.displayName, this.parent, attrName, this));
                        //createSettingToggle(this.editor.selectedElement!, this.parent, setting.name, setting.displayName, setting.default == 'true', setting.tooltip);
                    break;
                    case "Number":
                    case "String":
                        if (setting.typeModifier) {
                            switch(setting.typeModifier) {
                                case "select":
                                    this.inputs.push(new SelectInput(setting.displayName, this.parent, attrName, setting.typeConfig?.displayNames, setting.typeConfig?.values, this));
                                break;
                                case "color":
                                    this.inputs.push(new ColorInput(setting.displayName, this.parent, attrName, this));
                                break;
                                case "file":
                                    this.inputs.push(new FileInput(setting.displayName, this.parent, attrName, this));
                                    break;
                            }
                        }
                        else if (setting.type == "String")
                            this.inputs.push(new TextInput(setting.displayName, this.parent, attrName, this));
                        else
                            this.inputs.push(new NumberInput(setting.displayName, this.parent, attrName, this));
                    break;
                    case "Array":
                        if (setting.typeModifier) {
                            switch(setting.typeModifier) {
                                case "range":
                                    this.inputs.push(new RangeInput(setting.displayName, this.parent, attrName, this));
                                break;
                            }
                        }
                    break;
                    case 'RangeAttribute':
                        this.inputs.push(new RangeInput(setting.displayName, this.parent, attrName, this));
                        break;
                    case 'ColorAttribute':
                        this.inputs.push(new ColorInput(setting.displayName, this.parent, attrName, this));
                    break;
                    case "Object":
                        if (setting.typeModifier) {
                            switch(setting.typeModifier) {
                                case "range":
                                    this.inputs.push(new RangeInput(setting.displayName, this.parent, attrName, this));
                                    break;
                                case "map":
                                    //TODO: this this.inputs.push(new)
                                break;
                            }
                        }
                        break;
                    default:
                        throw new Error('Unexpected attribute type');
                }
            }
        }
    }

    createAbsolutePathSettings(): HTMLElement {
        let pathSection  = this.createSection(this.parent,'Tag Path');
        this.inputs.push(new ToggleInput('Make Relative to Device', pathSection, 'fRelative', this));
        return pathSection;
    }

    createChartSettings(): HTMLElement {
        let pathSection  = this.createSection(this.parent,'Tag Style');
        this.inputs.push(
            new ColorInput('Color', pathSection, 'color', this),
            new TextInput('Display Name', pathSection, 'name', this, 'The name to show on the legend in the chart'),
            new NumberInput('Minimum', pathSection, 'min', this, 'The minimum value to show on the y-axis'),
            new NumberInput('Max', pathSection, 'max', this, 'The maximum value to show on the y-axis'),
        );
        return pathSection;
    }

    createVisibilitySettings(): HTMLElement {
        let pathSection  = this.createSection(this.parent,'Visibility Settings');
        this.inputs.push(
            new NumberInput('Hide When Value Equals', pathSection, 'hideWhen', this, 'Update this'),
        );
        return pathSection;
    }

    createHOASettings(): HTMLElement {
        let hoaSection  = this.createSection(this.parent,'Visibility Settings');
        this.inputs.push(
            new TextInput('Display Name', hoaSection, 'name', this, 'Update this'),
        );
        return hoaSection;
    }

    apply() {
        this.attributeChangeCallback(this.settings);
        //this.editor.selectedElement?.onTagSettingChanged(); TODO: this
    }


}

export enum StyleCategories {
    POSITION    = "POSITION",
    DIMENSIONS  = "DIMENSIONS",
    TYPOGRAPHY  = "TYPOGRAPHY",
    BACKGROUND  = "BACKGROUND",
    BORDER      = "BORDER",
    LAYOUT      = "LAYOUT",
    CHART       = "CHART",
    SPACING     = "SPACING"
}

export class DashboardStyleView extends DashboardSettingView {
    parent: HTMLElement;
    inputs: SettingInput[] = [];
    editor: WidgetEditorPage;
    _selectedElement: HTMLElement | null;
    constructor(editor: WidgetEditorPage) {
        super();
        this.editor = editor;
    }
    initialize(parent: HTMLElement) {
        super.initialize(parent);
        this.sectionMap = new Map([
            [StyleCategories.POSITION,      this.createPositionSettings()],
            [StyleCategories.DIMENSIONS,    this.createSizeSettings()],
            [StyleCategories.TYPOGRAPHY,    this.createTypographySettings()],
            [StyleCategories.BACKGROUND,    this.createBackgroundSettings()],
            [StyleCategories.BORDER,        this.createBorderSettings()],
            [StyleCategories.LAYOUT,        this.createLayoutSettings()],
            [StyleCategories.SPACING,       this.createSpacingSettings()],
        ]);

        this.fInitialized = true;
        return this;
    }

    setSettingsToEdit(): void {
        if (this.editor.selectedElement === null)
            return;
        let computedStyles = getComputedStyle(this.editor.selectedElement);
        let rule = this.editor.getStyles(this.editor.selectedElement);
        if (!rule)
            return;
        this.settings = {};
        for (let i=0;i<this.inputs.length;++i) {
            if (rule.style[this.inputs[i].property] === '')
                continue;
            this.settings[this.inputs[i].property] = rule.style[this.inputs[i].property];
        }
        this.defaultSettings = {};
        this.autoSettings = {};
        for (let i=0;i<this.inputs.length;++i) {
            this.autoSettings[this.inputs[i].property] = computedStyles.getPropertyValue(this.inputs[i].property);
        }
        super.setSettingsToEdit(this.settings, this.defaultSettings, this.autoSettings)
    }

    set selectedElement(element: HTMLElement | null)
    {
        this._selectedElement = element;
        if (this._selectedElement === null)
        {
            this.hideSections();
        }
    }

    /**
     *
     *
     * @return {*}
     * @memberof DashboardStyleView
     */
    apply()
    {
        if (this.editor.selectedElement === null)
            return;
        let rule = this.editor.getStyles(this.editor.selectedElement)!;
        for (let i=0;i<this.inputs.length;++i) {        // Clear all existing styles
            rule.style[this.inputs[i].property] = '';
        }
        Object.keys(this.settings).forEach(key => {
            this.setStyle(this.editor.selectedElement!, key, this.settings[key])
        });
        //TODO: how do we make sure we update the widget on style change? resize?
        //if (this.editor.selectedElement instanceof Widget)
        //    this.editor.selectedElement.refresh();
        //this.editor.view.gizmoContainer.customStyles.sheet?.cssRules[0].sel selectedElement?.applyStyles(); TODO: Do this
    }

    setStyle(element: Element, key: string, value: string, fRefresh: boolean = false)
    {
        let rule = this.editor.getStyles(element)!;
        rule.style[key] = value;
        //if (element instanceof Widget && fRefresh)
        //    element.refresh() TODO: again, how do we refresh?
        this.settings[key] = value;
    }

    createSpacingSettings(): HTMLElement {
        let spacingSection  = this.createSection(this.parent,'Spacing');
        let spacingCanvas   = createElement('canvas', '', spacingSection, '', {height:180, width: 348});
        let ctx = spacingCanvas.getContext('2d')!;
        ctx.rect(0,0,spacingCanvas.width, spacingCanvas.height);
        ctx.fillStyle = owner.colors.hex('--color-onSurface') + '20';
        ctx.fill()
        ctx.beginPath();
        ctx.moveTo(0,0);
        ctx.lineTo(4 * spacingCanvas.width / 9, 7 * spacingCanvas.height / 16);
        ctx.lineTo(4 * spacingCanvas.width / 9, 9 * spacingCanvas.height / 16);
        ctx.lineTo(0, spacingCanvas.height);
        ctx.lineTo(spacingCanvas.width, spacingCanvas.height);
        ctx.lineTo(5 * spacingCanvas.width / 9, 9 * spacingCanvas.height / 16);
        ctx.lineTo(5 * spacingCanvas.width / 9, 7 * spacingCanvas.height / 16);
        ctx.lineTo(spacingCanvas.width, 0);
        ctx.closePath();
        ctx.fillStyle = owner.colors.hex('--color-onSurface') + '30';
        ctx.fill();
        ctx.beginPath();
        ctx.rect(4 * spacingCanvas.width / 9, 7 * spacingCanvas.height / 16, spacingCanvas.width / 9, spacingCanvas.height / 8);
        ctx.fillStyle = owner.colors.hex('--color-onSurface') + '50';
        ctx.fill();
        ctx.beginPath();
        ctx.rect(spacingCanvas.width / 5, spacingCanvas.height / 5, 3 * spacingCanvas.width / 5, 3 * spacingCanvas.height / 5);
        ctx.lineWidth = 4;
        ctx.strokeStyle = owner.colors.hex('--color-onSurface') + '50';
        ctx.stroke();

        let marginLabel     = createElement('div', 'editor__container__setting__title', spacingSection, 'Margin');
        let paddingLabel    = createElement('div', 'editor__container__setting__title', spacingSection, 'Padding');
        marginLabel.style.position = 'absolute';
        marginLabel.style.top = '28px';
        marginLabel.style.left = '6px';
        marginLabel.style.marginLeft = 'calc(0px-50%)';
        marginLabel.style.fontSize = '0.75em';
        marginLabel.style.color = 'var(--color-onSurface)';

        paddingLabel.style.position = 'absolute';
        paddingLabel.style.top = spacingCanvas.height / 4 + 22 + 'px' ;
        paddingLabel.style.left = spacingCanvas.width / 5 + 6 + 'px';
        paddingLabel.style.fontSize = '0.75em';
        paddingLabel.style.color = 'var(--color-onSurface)';

        let mInputRight     = new SizeInput('', spacingSection, 'margin-right',  ['px','%'], this, 'Edit margin right');
        let mInputTop       = new SizeInput('', spacingSection, 'margin-top',    ['px','%'], this, 'Edit margin top');
        let mInputLeft      = new SizeInput('', spacingSection, 'margin-left',   ['px','%'], this, 'Edit margin left');
        let mInputBottom    = new SizeInput('', spacingSection, 'margin-bottom', ['px','%'], this, 'Edit margin bottom');

        this.inputs.push(mInputRight, mInputTop, mInputLeft, mInputBottom);

        let margins: SizeInput[] = [mInputLeft, mInputTop, mInputRight, mInputBottom];

        for (let i=0;i<margins.length;++i) {
            margins[i].wrapper.style.position = 'absolute';
            margins[i].wrapper.style.width = 'max-content';
            margins[i].wrapper.style.top = 24 + spacingCanvas.height / 2 - Math.sin(i * Math.PI / 2) * spacingCanvas.height / 2.5 + 'px';
            margins[i].wrapper.style.left = spacingCanvas.width / 2 - Math.cos(i * Math.PI / 2) * spacingCanvas.width / 2.5 + 'px';
            margins[i].wrapper.style.transformOrigin = 'center'
            margins[i].wrapper.style.transform = 'translate(-50%, -50%) scale(0.66)'
        }

        let pInputRight     = new SizeInput('', spacingSection, 'padding-right', ['px','%'], this, 'Edit padding right');
        let pInputTop       = new SizeInput('', spacingSection, 'padding-top',   ['px','%'], this, 'Edit padding top');
        let pInputLeft      = new SizeInput('', spacingSection, 'padding-left',  ['px','%'], this, 'Edit padding left');
        let pInputBottom    = new SizeInput('', spacingSection, 'padding-bottom',['px','%'], this, 'Edit padding bottom');

        this.inputs.push(pInputRight, pInputTop, pInputLeft, pInputBottom);

        let paddings: SizeInput[] = [pInputLeft, pInputTop, pInputRight, pInputBottom];

        for (let i=0;i<margins.length;++i) {
            paddings[i].wrapper.style.position = 'absolute';
            paddings[i].wrapper.style.width = 'max-content';
            paddings[i].wrapper.style.top = 24 + spacingCanvas.height / 2 - Math.sin(i * Math.PI / 2) * spacingCanvas.height / 6 + 'px';
            paddings[i].wrapper.style.left = spacingCanvas.width / 2 - Math.cos(i * Math.PI / 2) * spacingCanvas.width / 6 + 'px';
            paddings[i].wrapper.style.transformOrigin = 'center'
            paddings[i].wrapper.style.transform = 'translate(-50%, -50%) scale(0.66)'
        }
        return spacingSection;

    }

    createSizeSettings(): HTMLElement {
        let sizeSection     = this.createSection(this.parent,'Size');
        let sizeColumns     = createElement('div', 'flex__row full__width', sizeSection);
        let sizeColumn1     = createElement('div', 'editor__container__setting__column', sizeColumns);
        let sizeColumn2     = createElement('div', 'editor__container__setting__column', sizeColumns);
        this.inputs.push(
            new SizeInput('Height:',sizeColumn1,    'height',        ['px','%','em'], this),
            new SizeInput('Min H:',sizeColumn1,     'min-height',    ['px','%','em'], this),
            new SizeInput('Max H:',sizeColumn1,     'max-height',    ['px','%','em'], this),

            new SizeInput('Width:',sizeColumn2, 'width',     ['px','%','em'], this),
            new SizeInput('Min W:',sizeColumn2, 'min-width', ['px','%','em'], this),
            new SizeInput('Max W:',sizeColumn2, 'max-width', ['px','%','em'], this),

            new ToggleInput('Grow to Fill', sizeColumn1, 'flex-grow', this, `Grow to fill the parent's size`)
        )
        return sizeSection;
    }

    createTypographySettings(): HTMLElement {
        let typographySection   = this.createSection(this.parent, 'Typography');
        let types               = createElement('div', 'flex__column full__width', typographySection);
        let align               = createElement('div', 'editor__radio__row', typographySection);
        this.inputs.push(
            new SizeInput('Font Size:', types, 'font-size',     ['px','%','em'], this),
            new SelectInput('Font:',    types, 'font-family',   ['Source Sans', 'Arial', 'IBM Plex Mono'], ['\"Source Sans Pro\"', 'Arial', `"IBM Plex Mono", monospace`], this),
            new ColorInput('Text Color:', typographySection, 'color', this),
            new RadioInput(align, 'text-align', 'Align:', [
                {name: 'left',          displayName: '', icon: AlignHLeftIcon,   tooltip: 'Left'},
                {name: 'center',        displayName: '', icon: AlignHCenterIcon, tooltip: 'Center'},
                {name: 'right',         displayName: '', icon: AlignHRightIcon,  tooltip: 'Right'},
                {name: 'justify',       displayName: '', icon: AlignHDIcon,      tooltip: 'Justify'}
            ], this)
        );
        return typographySection;
    }

    createBackgroundSettings(): HTMLElement  {
        let backgroundSection   = this.createSection(this.parent, 'Background');
        this.inputs.push(
            new ColorInput('Background Color:', backgroundSection, 'background-color', this)
        );
        return backgroundSection;
    }


    createBorderSettings(): HTMLElement  {
        let spacingSection  = this.createSection(this.parent, 'Border');
        this.inputs.push(
            new SelectInput('Border Style',     spacingSection, 'border-style', ['None', 'Solid', 'Dashed', ], [ 'none', 'solid', 'dashed',], this),
            new SizeInput('Border Radius:',     spacingSection, 'border-radius', ['px','%'], this),
            new SizeInput('Border Thickness:',  spacingSection, 'border-width', ['px','%'], this),
            new ColorInput('Border Color:',     spacingSection, 'border-color', this),
        );
        return spacingSection;
    }

    createLayoutSettings(): HTMLElement  {
        let layoutSection   = this.createSection(this.parent, 'Layout');

        let displayTabs = new TabInput(createElement('div', 'flex__column full__width', layoutSection), 'display', 'Display', [
            {name: 'block', icon: DisplayBlockIcon, displayName: '', tooltip: '<strong>Block</strong> — Displays an element as a block element. It starts on a new line, and takes up the whole width'},
            {name: 'inline-block', icon: DisplayInlineBlockIcon, displayName: '', tooltip: '<strong>Inline-Block</strong> — Positioned according to the normal flow of the document'},
            {name: 'flex', icon: DisplayFlexIcon, displayName: '', tooltip: 'Removed from the normal document flow and positioned relative to its closest positioned parent'},
            {name: 'grid', icon: GridIcon, displayName: '', tooltip: 'Removed from the normal document flow and positioned relative to its closest positioned parent'},
            {name: 'none', icon: HiddenIcon, displayName: '', tooltip: 'Removed from the normal document flow and positioned relative to its closest positioned parent'},
        ], this, (name) => {
            //switch (name) {
            //
            //    default:
            //        assert(false, "Bad position style setting");
            //}
            this.apply();
            this.updateSettings();
        });

        let blockTab            = displayTabs.tabManager.sectionMap.get("block");
        let inlineBlockTab      = displayTabs.tabManager.sectionMap.get("inline-block");
        let flexTab             = displayTabs.tabManager.sectionMap.get("flex");


        this.inputs.push(displayTabs);

        let flexWrapper2 = createElement('div', 'editor__radio__row', flexTab);
        let flexWrapper3 = createElement('div', 'editor__radio__row', flexTab);
        let flexWrapper6 = createElement('div', 'editor__radio__row', flexTab);
        let flexWrapper7 = createElement('div', 'editor__radio__row', flexTab);

        this.inputs.push(
            new RadioInput(flexWrapper2, 'align-items', 'Alignment', [
                {name: 'normal',        displayName: '', icon: AlignVTopIcon,    tooltip: 'Start'},
                {name: 'center',        displayName: '', icon: AlignVCenterIcon, tooltip: 'Center'},
                {name: 'flex-end',      displayName: '', icon: AlignVBottomIcon, tooltip: 'End'},
                {name: 'stretch',       displayName: '', icon: AlignVDIcon,      tooltip: `<strong>Stretch</strong> to fit the container`}
            ], this),
            new RadioInput(flexWrapper3, 'justify-content', 'Justify', [
                {name: 'normal',        displayName: '', icon: AlignHLeftIcon,   tooltip: 'Start'},
                {name: 'center',        displayName: '', icon: AlignHCenterIcon, tooltip: 'Center'},
                {name: 'flex-end',      displayName: '', icon: AlignHRightIcon,  tooltip: 'End'},
                {name: 'space-between', displayName: '', icon: AlignSpaceIcon,   tooltip: '<strong>Space Between</strong> — Distribute items evenly. The first item is flush with the start, the last is flush with the end'},
                {name: 'space-around',  displayName: '', icon: AlignHDIcon,      tooltip: '<strong>Space Around</strong> — Distribute items evenly. Items have equal spacing around all children'}
            ], this),
            new RadioInput(flexWrapper6, 'flex-wrap', 'Wrap Children', [
                {name: 'nowrap',    displayName: 'No Wrap', icon: '', tooltip: 'The items break into multiple lines'},
                {name: 'wrap',      displayName: 'Wrap',    icon: '', tooltip: 'The items are laid out in a single line. May cause overflow'},
            ], this),
            new RadioInput(layoutSection, 'overflow', 'Overflow', [
                {name: 'auto',      displayName: 'Auto',    icon: '', tooltip: 'Only scroll when content overflows'},
                {name: 'scroll',    displayName: 'Scroll',  icon: '', tooltip: 'Scroll overflowing content'},
                {name: 'hidden',    displayName: 'Hidden',  icon: '', tooltip: 'Hide overflowing content without scrolling'},
                {name: 'visible',   displayName: 'Visible', icon: '', tooltip: 'Show overflowing content without scrolling'},
            ], this),
        );
        return layoutSection;
    }

    createPositionSettings(): HTMLElement {
        let positionSection   = this.createSection(this.parent, 'Position');

        let positionTabs = new TabInput(createElement('div', 'flex__column full__width', positionSection), 'position', 'Position', [
            {name: 'relative', icon: '', displayName: 'Relative', tooltip: 'Positioned according to the normal flow of the document'},
            {name: 'absolute', icon: '', displayName: 'Absolute', tooltip: 'Removed from the normal document flow and positioned relative to its closest positioned parent'},
        ], this, (name) => {
            switch (name) {
                case 'relative':
                    delete this.settings['left'];
                    delete this.settings['right'];
                    delete this.settings['top'];
                    delete this.settings['bottom'];
                    break;
                case 'absolute':
                    this.settings['left'] = this.settings['top'] = '0px';
                    break;
                default:
                    assert(false, "Bad position style setting");
            }
            this.apply();
            this.updateSettings();
        });

        this.inputs.push(positionTabs);

        let absoluteTab     = positionTabs.tabManager.sectionMap.get("absolute");
        let absoluteSection = createElement('div', 'full__width flex__column', absoluteTab);

        let spacingCanvas   = createElement('canvas', '', absoluteSection, '', {height:79, width: 300});
        let ctx = spacingCanvas.getContext('2d')!;

        ctx.beginPath();
        ctx.rect(spacingCanvas.width / 3, 2 * spacingCanvas.height / 5, spacingCanvas.width / 3, spacingCanvas.height / 5);
        ctx.lineWidth = 4;
        ctx.strokeStyle = '#00000080';
        ctx.stroke();

        let positionInputs  = ['right', 'top', 'left', 'bottom'];

        for (let i=0;i<positionInputs.length;++i) {
            let wrapper = createElement('div', '', absoluteSection);
            let input = new SizeInput('', wrapper, positionInputs[i], ['px','%'], this);
            wrapper.style.position         = 'absolute';
            wrapper.style.top              = spacingCanvas.height / 2 - Math.sin(i * Math.PI / 2) * spacingCanvas.height / 3 - 6 + 'px';
            wrapper.style.left             = spacingCanvas.width / 2 + Math.cos(i * Math.PI / 2) * spacingCanvas.width  / 3 - 6 + 'px';
            wrapper.style.transformOrigin  = 'center';
            wrapper.style.transform        = 'scale(0.66) translate(-50%, -50%)';
            this.inputs.push(input);
            positionTabs.registerSubSetting(input);
        }
        return positionSection;
    }


}

export class SettingInput {
    input: HTMLInputElement;
    property: string;
    view: DashboardSettingView;
    parent: HTMLElement;
    wrapper: HTMLElement;
    constructor(parent: HTMLElement, property: string, view: DashboardSettingView, tooltip?: string) {
        this.parent         = parent
        this.wrapper        = createElement('div', 'setting-input__wrapper', this.parent);
        if (tooltip && tooltip.length > 0) {
            assert(tooltip != undefined, 'Tooltip string is undefined');
            assert(tooltip != '', 'Tooltip string is empty');
            tippy(this.wrapper, {
                content  : tooltip,
                delay    : [750, 0],  // duration: [show, hide]
                duration : [0, 0],    // duration: [show, hide]
                placement: 'top',
                offset   : [0, 5],
                allowHTML: true
            });
        }
        this.wrapper.addEventListener('contextmenu', (e) => {
            e.preventDefault();
            new Dropdown(e, ['Reset to Default'], (name)=> {
                switch(name) {
                    case 'Reset to Default':
                        this.resetStyle();
                    break;
                    default:
                        assert(false);
                }
            })
        });
        this.property       = property;
        this.view           = view;
    }

    apply(setting: string, fResize: boolean = false) {
        this.view.settings[this.property] = setting;
        this.view.apply();
        if (fResize) {
            this.view.editor.resize();
        }
        this.updateIndicator();
    }

    resetStyle() {
        delete this.view.settings[this.property];
        this.view.apply();
        this.update();
        dispatchEvent(new Event('resize'));
    }

    setEnabled(fEnabled: boolean) {}

    getSettingValue() {
        return this.view.settings[this.property] ?? this.view.defaultSettings[this.property] ?? this.view.autoSettings[this.property] ?? '';
    }

    update() {
        this.updateIndicator();
    }

    updateIndicator() {
        if (this.property in this.view.settings) {
            this.wrapper.style.border = `1px solid var(--color-blue-3)`;
        }
        else if (this.property in this.view.defaultSettings)
            this.wrapper.style.border = `1px solid var(--color-orange-3)`;
        else
            this.wrapper.style.border = '';

    }
}

class RadioInput extends SettingInput {
    radioSelector: RadioSelector;
    constructor(parent: HTMLElement, property: string, title: string, radioButtons: RadioButton[], view: DashboardSettingView) {
        super(parent, property, view);
        this.radioSelector = new RadioSelector(this.wrapper, {
            buttons: radioButtons,
            title: title,
            changeCallback: (name) => {
                this.apply(name);
            }
        });
    }

    update(): void {
        super.update();
        this.radioSelector.update(this.getSettingValue());
    }

    setEnabled(fEnabled: boolean): void {

    }
}

class TabInput extends SettingInput {
    tabManager: TabManager;
    subSettings: SettingInput[] = [];
    constructor(parent: HTMLElement, property: string, title: string, radioButtons: RadioButton[], view: DashboardSettingView, callback?: (name: string)=>void, tooltip?: string) {
        super(parent, property, view, tooltip);
        this.tabManager = new TabManager(this.wrapper, title, radioButtons, undefined,(name)=> {
            this.apply(name);
            if (callback)
                callback(name);
        });
    }

    registerSubSetting(subSetting: SettingInput) {
        this.subSettings.push(subSetting);
    }

    update(): void {
        super.update()
        this.tabManager.update(this.getSettingValue());
    }

    resetStyle(): void {
        super.resetStyle();
        for (let setting of this.subSettings) {
            setting.resetStyle();
        }
    }
}

export class SelectInput extends SettingInput {
    element: HTMLInputElement;
    select: HTMLSelectElement;
    property: string;
    setting: string;
    constructor(name: string, parent: HTMLElement, property: string, options: string[], values: string[], view: DashboardSettingView, tooltip?: string) {
        super(parent, property, view, tooltip);
        this.property   = property;
        createElement('div', 'editor__container__settings__name', this.wrapper, name);
        this.select = createElement('select', 'editor__container__settings__select', this.wrapper);
        for (let i=0;i<options.length;++i) {
            createElement('option', 'editor__container__settings__unit__option', this.select, options[i], {value: values[i]});
        }
        this.select.onchange = () => this.apply(this.select.options[this.select.selectedIndex].value);
    }

    update(): void {
        super.update()
        this.select.value = this.getSettingValue();
    }

    setEnabled(fEnabled: boolean): void {
        this.select.disabled = !fEnabled;
    }
}

export class NumberInput extends SettingInput {
    element: HTMLInputElement;
    property: string;
    setting: string;
    wrapper: HTMLElement;
    constructor(name: string, parent: HTMLElement, property: string, view: DashboardSettingView, tooltip?: string) {
        super(parent, property, view, tooltip);
        this.property   = property;
        createElement('div', 'editor__container__settings__name', this.wrapper, name);
        this.element    = createElement('input', 'spinner editor__container__settings__input', this.wrapper, '', {'type':'number'});
        this.element.onchange       = () => this.onChange();
    }

    onChange() {
        this.apply(this.element.value == '' ? '' : this.element.value);

    }
    update(): void {
        super.update()
        this.element.value = this.getSettingValue();
    }

    setEnabled(fEnabled: boolean): void {
        this.element.disabled = !fEnabled;
    }
}

export class TextInput extends SettingInput{
    element: HTMLInputElement;
    property: string;
    setting: string;
    wrapper: HTMLElement;
    constructor(name: string, parent: HTMLElement, property: string, view: DashboardSettingView, tooltip?: string) {
        super(parent, property, view, tooltip);
        this.property   = property;
        createElement('div', 'editor__container__settings__name', this.wrapper, name);
        this.element    = createElement('input', 'editor__container__settings__input', this.wrapper, '', {'type':'text'});
        this.element.onchange       = () => this.onChange();
    }

    onChange() {
        this.apply(this.element.value == '' ? '' : this.element.value);

    }
    update(): void {
        super.update()
        this.element.value = this.getSettingValue();
    }

    setEnabled(fEnabled: boolean): void {
        this.element.disabled = !fEnabled;
    }
}

export class ColorInput extends SettingInput {
    element: HTMLInputElement;
    property: string;
    setting: string;
    constructor(name: string, parent: HTMLElement, property: string, view: DashboardSettingView, tooltip?: string) {
        super(parent, property, view, tooltip);
        this.property           = property;
        createElement('div', 'editor__container__settings__name', this.wrapper, name);
        this.element            = createElement('input', '',this.wrapper, '', {'type':'color'});
        this.element.style.border       = 'none'
        this.element.style.borderRadius = '4px';
        this.element.style.padding      = '0px';
        this.element.oninput            = () => {
            this.colorCallback(this.element.value)
        }
    }

    colorCallback(color: string) {
        this.apply(color);
    }

    update(): void {
        super.update()
        this.element.value = this.getSettingValue();
    }

    setEnabled(fEnabled: boolean): void {
        this.element.disabled = !fEnabled;
    }
}

export class SizeInput extends SettingInput {
    element: HTMLInputElement;
    unitsInput: HTMLSelectElement;
    wrapper: HTMLElement;
    property: string;
    setting: string;
    unit: string;
    constructor(name: string, parent: HTMLElement, property: string, units: string[], view: DashboardSettingView, tooltip?: string) {
        super(parent, property, view, tooltip);
        this.property   = property;
        let container = createElement('div', 'editor__container__settings__wrapper', this.wrapper);
        createElement('div', 'editor__container__settings__name', container, name);
        this.element    = createElement('input', 'editor__container__settings__input', container, '', {'type':'number', 'step':'1'});
        this.unitsInput = createElement('select', 'editor__container__settings__unit', container);
        for (let i=0;i<units.length;++i) {
            createElement('option', 'editor__container__settings__unit__option', this.unitsInput, units[i]);
        }
        this.element.onchange       = () => this.onChange();
        this.unitsInput.onchange    = () => this.onChange();
    }
    onChange() {
        this.apply(this.element.value == '' ? '' : this.element.value + this.unitsInput.options[this.unitsInput.selectedIndex].value, true);
    }

    update(): void {
        super.update();
        let setting = this.getSettingValue();
        if (setting.length > 1) {
            let number          = setting.match(/^\d*\.?\d*/);
            if (number !== null) {
                this.element.value      = number[0].length > 0 ? number[0] : '0';
                let unit                = setting.split(number[0]);
                this.unitsInput.value   = unit[1];
                return;
            }
        }
        this.element.value = ''
        this.unitsInput.selectedIndex = 0;
    }

    setEnabled(fEnabled: boolean): void {
        this.element.disabled = !fEnabled;
        this.unitsInput.disabled = !fEnabled;
    }
}

export class ToggleInput extends SettingInput{
    element: HTMLInputElement;
    wrapper: HTMLElement;
    property: string;
    setting: string;
    constructor(name: string, parent: HTMLElement, property: string, view: DashboardSettingView, tooltip?: string) {
        super(parent, property, view, tooltip);
        this.property   = property;
        createElement('div', 'editor__container__settings__name', this.wrapper, name);
        this.element                = new Switch(this.wrapper).switchCheckbox;
        this.element.onchange       = () => this.onChange();
    }
    onChange() {
        this.apply(this.element.checked ? 'true' : 'false');
    }

    update(): void {
        super.update();
        let value = this.getSettingValue();
        this.element.checked = !!value && value != "false";
    }
}

export class FileInput extends SettingInput {
    element: HTMLButtonElement;
    property: string;
    setting: string;
    wrapper: HTMLElement;
    constructor(name: string, parent: HTMLElement, property: string, view: DashboardSettingView, tooltip?: string) {
        super(parent, property, view, tooltip);
        this.property   = property;
        this.element    = createElement('button', 'se-button', this.wrapper, 'Select Image');
        this.element.onclick = () => {
            new ViewModal(ImageBrowserView.bind(undefined, undefined, '/SE_Dashboard/', (uuid: string)=>{this.onChange(uuid)}), {

            })
        }
    }

    onChange(uuid: string) {
        this.apply(uuid);
    }
}

export interface Range {
    name: string;
    upperLimit: number;
    value: string;
}

export interface RangeConfig {
    defaultMinimum: number;
    defaultRanges: Range[];
    defaultValue: string;
}

export interface RangeSetting {
    minimum: number;
    ranges: Range[];
}

export class RangeInput extends SettingInput {
    nameColumn: HTMLElement;
    inputColumn: HTMLElement;
    valueColumn: HTMLElement;
    constructor(name: string, parent: HTMLElement, property: string, view: DashboardSettingView, tooltip?: string) {
        super(parent, property, view, tooltip);
        let optionWrapper       = createElement('div', 'socket___range__column', parent)
        let rangeWrapper        = createElement('div', 'socket__range', optionWrapper);
        this.nameColumn         = createElement('div', 'socket__range__column', rangeWrapper);
        this.inputColumn        = createElement('div', 'socket__range__column', rangeWrapper);
        this.valueColumn        = createElement('div', 'socket__range__column', rangeWrapper);
        this.nameColumn.style.flex = '1';
        this.valueColumn.style.margin = this.nameColumn.style.margin = '12px 0 0 0'
        let addButton           = createElement('div', 'socket__add__button', optionWrapper);
        addButton.style.width   = '100%';
        createElement('div', 'socket__add__button__text', addButton, 'Add Range');
        addButton.onclick = () => {
            let setting = this.getSettingValue();
            debugger;
            let range = JSON.parse(setting) as RangeProperties;
            if (range)
                range.ranges = [...range.ranges, {
                    name: '',
                    upperLimit: range.ranges.length > 0 ? range.ranges[range.ranges.length - 1].upperLimit + 1 : 100,
                    value: '#81D4FA'
                }];
            this.apply(JSON.stringify(range));
            this.update();
        }
    }

    update() {
        super.update();
        this.nameColumn.removeChildren();
        this.inputColumn.removeChildren();
        this.valueColumn.removeChildren();
        let setting = this.getSettingValue();
        if (!setting)
            return;
        let rangeProps: RangeProperties = setting != '' ? JSON.parse(setting) : {};
        let range = new RangeAttribute(rangeProps);
        let minInput = createElement('input', 'spinner', this.inputColumn, undefined, {'type':'number'});	// Create the input element
        minInput.value = range.minimum.toString();
        minInput.onchange = () =>
        {
            range.minimum = parseFloat(minInput.value);
            this.apply(JSON.stringify(range));
        }
        for (let i=0;i<range.ranges.length;i++)
        {
            let nameInput   = createElement('input', 'editor__container__settings__input', this.nameColumn, '', {value: range.ranges[i].name});
            let rangeInput  = createElement('input', 'spinner', this.inputColumn, undefined, {'type':'number'});	// Create the input element
            rangeInput.value = range.ranges[i].upperLimit.toString();
            rangeInput.onchange = () =>
            {
                range.ranges[i].upperLimit = parseFloat(rangeInput.value);
                this.apply(JSON.stringify(range));
            }
            let tagColor = createElement('input', 'tag-color__node-wrapper__color', this.valueColumn, '', {'type':'color'});
            tagColor.value = range.ranges[i].value;
            tagColor.onchange = () =>
            {
                range.ranges[i].value = tagColor.value;
                this.apply(JSON.stringify(range));
            }
        }
    }
}