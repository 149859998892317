import './styles/main.css';
import assert from './debug';

export default class Colors {
    theme: string = /*window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches ? "dark" : */ "light"; // Uncomment to enable theme selection by browser default
    generic: { [key: string]: string };
    currentTheme: { [key: string]: string };
    themes: { [key: string]: { [key: string]: string } } = {
        "light": {
            "brand": "#2C2D79",
            "onBrand": "#ffffff",
            "primary": "#68548E",
            "surfaceTint": "#68548E",
            "onPrimary": "#FFFFFF",
            "primaryContainer": "#EBDDFF",
            "onPrimaryContainer": "#230F46",
            "secondary": "#006A6A",
            "onSecondary": "#FFFFFF",
            "secondaryContainer": "#9CF1F1",
            "onSecondaryContainer": "#002020",
            "tertiary": "#32628D",
            "onTertiary": "#FFFFFF",
            "tertiaryContainer": "#CFE4FF",
            "onTertiaryContainer": "#001D34",
            "error": "#BA1A1A",
            "onError": "#FFFFFF",
            "errorContainer": "#FFDAD6",
            "onErrorContainer": "#410002",
            "background": "#FEF7FF",
            "onBackground": "#1D1B20",
            "surface": "#FEF7FF",
            "onSurface": "#1D1B20",
            "surfaceVariant": "#E7E0EB",
            "onSurfaceVariant": "#49454E",
            "outline": "#7A757F",
            "outlineVariant": "#CBC4CF",
            "shadow": "#000000",
            "scrim": "#000000",
            "inverseSurface": "#322F35",
            "inverseOnSurface": "#F5EFF7",
            "inversePrimary": "#D3BCFD",
            "primaryFixed": "#EBDDFF",
            "onPrimaryFixed": "#230F46",
            "primaryFixedDim": "#D3BCFD",
            "onPrimaryFixedVariant": "#4F3D74",
            "secondaryFixed": "#9CF1F1",
            "onSecondaryFixed": "#002020",
            "secondaryFixedDim": "#80D5D4",
            "onSecondaryFixedVariant": "#004F50",
            "tertiaryFixed": "#CFE4FF",
            "onTertiaryFixed": "#001D34",
            "tertiaryFixedDim": "#9DCBFC",
            "onTertiaryFixedVariant": "#134A74",
            "surfaceDim": "#DED8E0",
            "surfaceBright": "#FEF7FF",
            "surfaceContainerLowest": "#FFFFFF",
            "surfaceContainerLow": "#F8F1FA",
            "surfaceContainer": "#F2ECF4",
            "surfaceContainerHigh": "#EDE6EE",
            "surfaceContainerHighest": "#E7E0E8",
            "surfaceContainerTransparent": "#F2ECF490",
            "iconOnSurface": "invert(0)",
            "iconOnPrimary": "invert(1)",
            "iconOnTertiary": "invert(1)"
        },
        "light-medium-contrast": {
            "brand": "#2C2D79",
            "onBrand": "#ffffff",
            "primary": "#4B3970",
            "surfaceTint": "#68548E",
            "onPrimary": "#FFFFFF",
            "primaryContainer": "#7F6AA5",
            "onPrimaryContainer": "#FFFFFF",
            "secondary": "#004B4B",
            "onSecondary": "#FFFFFF",
            "secondaryContainer": "#238181",
            "onSecondaryContainer": "#FFFFFF",
            "tertiary": "#0C466F",
            "onTertiary": "#FFFFFF",
            "tertiaryContainer": "#4A78A5",
            "onTertiaryContainer": "#FFFFFF",
            "error": "#8C0009",
            "onError": "#FFFFFF",
            "errorContainer": "#DA342E",
            "onErrorContainer": "#FFFFFF",
            "background": "#FEF7FF",
            "onBackground": "#1D1B20",
            "surface": "#FEF7FF",
            "onSurface": "#1D1B20",
            "surfaceVariant": "#E7E0EB",
            "onSurfaceVariant": "#45414A",
            "outline": "#625D67",
            "outlineVariant": "#7E7983",
            "shadow": "#000000",
            "scrim": "#000000",
            "inverseSurface": "#322F35",
            "inverseOnSurface": "#F5EFF7",
            "inversePrimary": "#D3BCFD",
            "primaryFixed": "#7F6AA5",
            "onPrimaryFixed": "#FFFFFF",
            "primaryFixedDim": "#65528B",
            "onPrimaryFixedVariant": "#FFFFFF",
            "secondaryFixed": "#238181",
            "onSecondaryFixed": "#FFFFFF",
            "secondaryFixedDim": "#006767",
            "onSecondaryFixedVariant": "#FFFFFF",
            "tertiaryFixed": "#4A78A5",
            "onTertiaryFixed": "#FFFFFF",
            "tertiaryFixedDim": "#2F5F8A",
            "onTertiaryFixedVariant": "#FFFFFF",
            "surfaceDim": "#DED8E0",
            "surfaceBright": "#FEF7FF",
            "surfaceContainerLowest": "#FFFFFF",
            "surfaceContainerLow": "#F8F1FA",
            "surfaceContainer": "#F2ECF4",
            "surfaceContainerHigh": "#EDE6EE",
            "surfaceContainerHighest": "#E7E0E8",
            "surfaceContainerTransparent": "#F2ECF490",
            "iconOnSurface": "invert(0)",
            "iconOnPrimary": "invert(1)",
            "iconOnTertiary": "invert(1)"
        },
        "light-high-contrast": {
            "brand": "#2C2D79",
            "onBrand": "#ffffff",
            "primary": "#2A164D",
            "surfaceTint": "#68548E",
            "onPrimary": "#FFFFFF",
            "primaryContainer": "#4B3970",
            "onPrimaryContainer": "#FFFFFF",
            "secondary": "#002727",
            "onSecondary": "#FFFFFF",
            "secondaryContainer": "#004B4B",
            "onSecondaryContainer": "#FFFFFF",
            "tertiary": "#00243F",
            "onTertiary": "#FFFFFF",
            "tertiaryContainer": "#0C466F",
            "onTertiaryContainer": "#FFFFFF",
            "error": "#4E0002",
            "onError": "#FFFFFF",
            "errorContainer": "#8C0009",
            "onErrorContainer": "#FFFFFF",
            "background": "#FEF7FF",
            "onBackground": "#1D1B20",
            "surface": "#FEF7FF",
            "onSurface": "#000000",
            "surfaceVariant": "#E7E0EB",
            "onSurfaceVariant": "#26222B",
            "outline": "#45414A",
            "outlineVariant": "#45414A",
            "shadow": "#000000",
            "scrim": "#000000",
            "inverseSurface": "#322F35",
            "inverseOnSurface": "#FFFFFF",
            "inversePrimary": "#F3E8FF",
            "primaryFixed": "#4B3970",
            "onPrimaryFixed": "#FFFFFF",
            "primaryFixedDim": "#352258",
            "onPrimaryFixedVariant": "#FFFFFF",
            "secondaryFixed": "#004B4B",
            "onSecondaryFixed": "#FFFFFF",
            "secondaryFixedDim": "#003333",
            "onSecondaryFixedVariant": "#FFFFFF",
            "tertiaryFixed": "#0C466F",
            "onTertiaryFixed": "#FFFFFF",
            "tertiaryFixedDim": "#002F4F",
            "onTertiaryFixedVariant": "#FFFFFF",
            "surfaceDim": "#DED8E0",
            "surfaceBright": "#FEF7FF",
            "surfaceContainerLowest": "#FFFFFF",
            "surfaceContainerLow": "#F8F1FA",
            "surfaceContainer": "#F2ECF4",
            "surfaceContainerHigh": "#EDE6EE",
            "surfaceContainerHighest": "#E7E0E8",
            "surfaceContainerTransparent": "#F2ECF490",
            "iconOnSurface": "invert(0)",
            "iconOnPrimary": "invert(1)",
            "iconOnTertiary": "invert(1)"
        },
        "dark": {
            "brand": "#2C2D79",
            "onBrand": "#ffffff",
            "primary": "#D3BCFD",
            "surfaceTint": "#D3BCFD",
            "onPrimary": "#38265C",
            "primaryContainer": "#4F3D74",
            "onPrimaryContainer": "#EBDDFF",
            "secondary": "#80D5D4",
            "onSecondary": "#003737",
            "secondaryContainer": "#004F50",
            "onSecondaryContainer": "#9CF1F1",
            "tertiary": "#9DCBFC",
            "onTertiary": "#003355",
            "tertiaryContainer": "#134A74",
            "onTertiaryContainer": "#CFE4FF",
            "error": "#FFB4AB",
            "onError": "#690005",
            "errorContainer": "#93000A",
            "onErrorContainer": "#FFDAD6",
            "background": "#151218",
            "onBackground": "#E7E0E8",
            "surface": "#151218",
            "onSurface": "#E7E0E8",
            "surfaceVariant": "#49454E",
            "onSurfaceVariant": "#CBC4CF",
            "outline": "#948F99",
            "outlineVariant": "#49454E",
            "shadow": "#000000",
            "scrim": "#000000",
            "inverseSurface": "#E7E0E8",
            "inverseOnSurface": "#322F35",
            "inversePrimary": "#68548E",
            "primaryFixed": "#EBDDFF",
            "onPrimaryFixed": "#230F46",
            "primaryFixedDim": "#D3BCFD",
            "onPrimaryFixedVariant": "#4F3D74",
            "secondaryFixed": "#9CF1F1",
            "onSecondaryFixed": "#002020",
            "secondaryFixedDim": "#80D5D4",
            "onSecondaryFixedVariant": "#004F50",
            "tertiaryFixed": "#CFE4FF",
            "onTertiaryFixed": "#001D34",
            "tertiaryFixedDim": "#9DCBFC",
            "onTertiaryFixedVariant": "#134A74",
            "surfaceDim": "#151218",
            "surfaceBright": "#3B383E",
            "surfaceContainerLowest": "#0F0D13",
            "surfaceContainerLow": "#1D1B20",
            "surfaceContainer": "#211F24",
            "surfaceContainerHigh": "#2C292F",
            "surfaceContainerHighest": "#36343A",
            "surfaceContainerTransparent": "#211F2490",
            "iconOnSurface": "invert(1)",
            "iconOnPrimary": "invert(1)",
            "iconOnTertiary": "invert(17%) sepia(16%) saturate(5380%) hue-rotate(176deg) brightness(90%) contrast(104%)"
        },
        "dark-medium-contrast": {
            "brand": "#2C2D79",
            "onBrand": "#ffffff",
            "primary": "#D7C0FF",
            "surfaceTint": "#D3BCFD",
            "onPrimary": "#1D0840",
            "primaryContainer": "#9B86C4",
            "onPrimaryContainer": "#000000",
            "secondary": "#84D9D8",
            "onSecondary": "#001A1A",
            "secondaryContainer": "#479E9E",
            "onSecondaryContainer": "#000000",
            "tertiary": "#A3CFFF",
            "onTertiary": "#00182C",
            "tertiaryContainer": "#6794C3",
            "onTertiaryContainer": "#000000",
            "error": "#FFBAB1",
            "onError": "#370001",
            "errorContainer": "#FF5449",
            "onErrorContainer": "#000000",
            "background": "#151218",
            "onBackground": "#E7E0E8",
            "surface": "#151218",
            "onSurface": "#FFF9FF",
            "surfaceVariant": "#49454E",
            "onSurfaceVariant": "#CFC8D3",
            "outline": "#A7A1AB",
            "outlineVariant": "#86818B",
            "shadow": "#000000",
            "scrim": "#000000",
            "inverseSurface": "#E7E0E8",
            "inverseOnSurface": "#2C292F",
            "inversePrimary": "#513E75",
            "primaryFixed": "#EBDDFF",
            "onPrimaryFixed": "#18023B",
            "primaryFixedDim": "#D3BCFD",
            "onPrimaryFixedVariant": "#3E2C62",
            "secondaryFixed": "#9CF1F1",
            "onSecondaryFixed": "#001414",
            "secondaryFixedDim": "#80D5D4",
            "onSecondaryFixedVariant": "#003D3D",
            "tertiaryFixed": "#CFE4FF",
            "onTertiaryFixed": "#001223",
            "tertiaryFixedDim": "#9DCBFC",
            "onTertiaryFixedVariant": "#00395E",
            "surfaceDim": "#151218",
            "surfaceBright": "#3B383E",
            "surfaceContainerLowest": "#0F0D13",
            "surfaceContainerLow": "#1D1B20",
            "surfaceContainer": "#211F24",
            "surfaceContainerHigh": "#2C292F",
            "surfaceContainerHighest": "#36343A",
            "surfaceContainerTransparent": "#211F2490",
            "iconOnSurface": "invert(1)",
            "iconOnPrimary": "invert(1)",
            "iconOnTertiary": "invert(17%) sepia(16%) saturate(5380%) hue-rotate(176deg) brightness(90%) contrast(104%)"

        },
        "dark-high-contrast": {
            "brand": "#2C2D79",
            "onBrand": "#ffffff",
            "primary": "#FFF9FF",
            "surfaceTint": "#D3BCFD",
            "onPrimary": "#000000",
            "primaryContainer": "#D7C0FF",
            "onPrimaryContainer": "#000000",
            "secondary": "#EAFFFE",
            "onSecondary": "#000000",
            "secondaryContainer": "#84D9D8",
            "onSecondaryContainer": "#000000",
            "tertiary": "#FAFAFF",
            "onTertiary": "#000000",
            "tertiaryContainer": "#A3CFFF",
            "onTertiaryContainer": "#000000",
            "error": "#FFF9F9",
            "onError": "#000000",
            "errorContainer": "#FFBAB1",
            "onErrorContainer": "#000000",
            "background": "#151218",
            "onBackground": "#E7E0E8",
            "surface": "#151218",
            "onSurface": "#FFFFFF",
            "surfaceVariant": "#49454E",
            "onSurfaceVariant": "#FFF9FF",
            "outline": "#CFC8D3",
            "outlineVariant": "#CFC8D3",
            "shadow": "#000000",
            "scrim": "#000000",
            "inverseSurface": "#E7E0E8",
            "inverseOnSurface": "#000000",
            "inversePrimary": "#321F55",
            "primaryFixed": "#EEE2FF",
            "onPrimaryFixed": "#000000",
            "primaryFixedDim": "#D7C0FF",
            "onPrimaryFixedVariant": "#1D0840",
            "secondaryFixed": "#A0F5F5",
            "onSecondaryFixed": "#000000",
            "secondaryFixedDim": "#84D9D8",
            "onSecondaryFixedVariant": "#001A1A",
            "tertiaryFixed": "#D7E8FF",
            "onTertiaryFixed": "#000000",
            "tertiaryFixedDim": "#A3CFFF",
            "onTertiaryFixedVariant": "#00182C",
            "surfaceDim": "#151218",
            "surfaceBright": "#3B383E",
            "surfaceContainerLowest": "#0F0D13",
            "surfaceContainerLow": "#1D1B20",
            "surfaceContainer": "#211F24",
            "surfaceContainerHigh": "#2C292F",
            "surfaceContainerHighest": "#36343A",
            "surfaceContainerTransparent": "#211F2490",
            "iconOnSurface": "invert(1)",
            "iconOnPrimary": "invert(1)",
            "iconOnTertiary": "invert(17%) sepia(16%) saturate(5380%) hue-rotate(176deg) brightness(90%) contrast(104%)"
        }
    }

    constructor() {
        this.generic = {
            '--color-flow-1': '#0D47A1',
            '--color-se-1': '#056839',
            '--color-dis-pressure': '#800080',
            '--color-transparent': '#00000000',
            '--color-alarm-1': '#C56E02',
            '--color-graph-1': '#06C',
            '--color-graph-2': '#4CB140',
            '--color-graph-3': '#009596',
            '--color-graph-4': '#5752D1',
            '--color-graph-5': '#000000',
            '--color-graph-6': '#EC7A08',
            '--color-graph-7': '#C9190B',
            '--color-graph-8': '#6A6E73',
            '--color-graph-9': '#F0AB00',
            '--color-graph-10': '#000000',
            '--color-red-0': '#FFEBEE',
            '--color-red-1': '#FFCDD2',
            '--color-red-2': '#EF9A9A',
            '--color-red-3': '#E57373',
            '--color-red-4': '#EF5350',
            '--color-red-5': '#F44336',
            '--color-red-6': '#E53935',
            '--color-red-7': '#D32F2F',
            '--color-red-8': '#C62828',
            '--color-red-9': '#B71C1C',
            '--color-red-a1': '#FF8A80',
            '--color-red-a2': '#FF5252',
            '--color-red-a4': '#FF1744',
            '--color-red-a7': '#D50000',
            '--color-pink-0': '#FCE4EC',
            '--color-pink-1': '#F8BBD0',
            '--color-pink-2': '#F48FB1',
            '--color-pink-3': '#F06292',
            '--color-pink-4': '#EC407A',
            '--color-pink-5': '#E91E63',
            '--color-pink-6': '#D81B60',
            '--color-pink-7': '#C2185B',
            '--color-pink-8': '#AD1457',
            '--color-pink-9': '#880E4F',
            '--color-pink-a1': '#FF80AB',
            '--color-pink-a2': '#FF4081',
            '--color-pink-a4': '#F50057',
            '--color-pink-a7': '#C51162',
            '--color-purple-0': '#F3E5F5',
            '--color-purple-1': '#E1BEE7',
            '--color-purple-2': '#CE93D8',
            '--color-purple-3': '#BA68C8',
            '--color-purple-4': '#AB47BC',
            '--color-purple-5': '#9C27B0',
            '--color-purple-6': '#8E24AA',
            '--color-purple-7': '#7B1FA2',
            '--color-purple-8': '#6A1B9A',
            '--color-purple-9': '#4A148C',
            '--color-purple-a1': '#EA80FC',
            '--color-purple-a2': '#E040FB',
            '--color-purple-a4': '#D500F9',
            '--color-purple-a7': '#AA00FF',
            '--color-deep-purple-0': '#EDE7F6',
            '--color-deep-purple-1': '#D1C4E9',
            '--color-deep-purple-2': '#B39DDB',
            '--color-deep-purple-3': '#9575CD',
            '--color-deep-purple-4': '#7E57C2',
            '--color-deep-purple-5': '#673AB7',
            '--color-deep-purple-6': '#5E35B1',
            '--color-deep-purple-7': '#512DA8',
            '--color-deep-purple-8': '#4527A0',
            '--color-deep-purple-9': '#311B92',
            '--color-deep-purple-a1': '#B388FF',
            '--color-deep-purple-a2': '#7C4DFF',
            '--color-deep-purple-a4': '#651FFF',
            '--color-deep-purple-a7': '#6200EA',
            '--color-indigo-0': '#E8EAF6',
            '--color-indigo-1': '#C5CAE9',
            '--color-indigo-2': '#9FA8DA',
            '--color-indigo-3': '#7986CB',
            '--color-indigo-4': '#5C6BC0',
            '--color-indigo-5': '#3F51B5',
            '--color-indigo-6': '#3949AB',
            '--color-indigo-7': '#303F9F',
            '--color-indigo-8': '#283593',
            '--color-indigo-9': '#1A237E',
            '--color-indigo-a1': '#8C9EFF',
            '--color-indigo-a2': '#536DFE',
            '--color-indigo-a4': '#3D5AFE',
            '--color-indigo-a7': '#304FFE',
            '--color-blue-0': '#E3F2FD',
            '--color-blue-1': '#BBDEFB',
            '--color-blue-2': '#90CAF9',
            '--color-blue-3': '#64B5F6',
            '--color-blue-4': '#42A5F5',
            '--color-blue-5': '#2196F3',
            '--color-blue-6': '#1E88E5',
            '--color-blue-7': '#1976D2',
            '--color-blue-8': '#1565C0',
            '--color-blue-9': '#0D47A1',
            '--color-blue-a1': '#82B1FF',
            '--color-blue-a2': '#448AFF',
            '--color-blue-a4': '#2979FF',
            '--color-blue-a7': '#2962FF',
            '--color-light-blue-0': '#E1F5FE',
            '--color-light-blue-1': '#B3E5FC',
            '--color-light-blue-2': '#81D4FA',
            '--color-light-blue-3': '#4FC3F7',
            '--color-light-blue-4': '#29B6F6',
            '--color-light-blue-5': '#03A9F4',
            '--color-light-blue-6': '#039BE5',
            '--color-light-blue-7': '#0288D1',
            '--color-light-blue-8': '#0277BD',
            '--color-light-blue-9': '#01579B',
            '--color-light-blue-a1': '#80D8FF',
            '--color-light-blue-a2': '#40C4FF',
            '--color-light-blue-a4': '#00B0FF',
            '--color-light-blue-a7': '#0091EA',
            '--color-cyan-0': '#E0F7FA',
            '--color-cyan-1': '#B2EBF2',
            '--color-cyan-2': '#80DEEA',
            '--color-cyan-3': '#4DD0E1',
            '--color-cyan-4': '#26C6DA',
            '--color-cyan-5': '#00BCD4',
            '--color-cyan-6': '#00ACC1',
            '--color-cyan-7': '#0097A7',
            '--color-cyan-8': '#00838F',
            '--color-cyan-9': '#006064',
            '--color-cyan-a1': '#84FFFF',
            '--color-cyan-a2': '#18FFFF',
            '--color-cyan-a4': '#00E5FF',
            '--color-cyan-a7': '#00B8D4',
            '--color-teal-0': '#E0F2F1',
            '--color-teal-1': '#B2DFDB',
            '--color-teal-2': '#80CBC4',
            '--color-teal-3': '#4DB6AC',
            '--color-teal-4': '#26A69A',
            '--color-teal-5': '#009688',
            '--color-teal-6': '#00897B',
            '--color-teal-7': '#00796B',
            '--color-teal-8': '#00695C',
            '--color-teal-9': '#004D40',
            '--color-teal-a1': '#A7FFEB',
            '--color-teal-a2': '#64FFDA',
            '--color-teal-a4': '#1DE9B6',
            '--color-teal-a7': '#00BFA5',
            '--color-green-0': '#E8F5E9',
            '--color-green-1': '#C8E6C9',
            '--color-green-2': '#A5D6A7',
            '--color-green-3': '#81C784',
            '--color-green-4': '#66BB6A',
            '--color-green-5': '#4CAF50',
            '--color-green-6': '#43A047',
            '--color-green-7': '#388E3C',
            '--color-green-8': '#2E7D32',
            '--color-green-9': '#1B5E20',
            '--color-green-a1': '#B9F6CA',
            '--color-green-a2': '#69F0AE',
            '--color-green-a4': '#00E676',
            '--color-green-a7': '#00C853',
            '--color-light-green-0': '#F1F8E9',
            '--color-light-green-1': '#DCEDC8',
            '--color-light-green-2': '#C5E1A5',
            '--color-light-green-3': '#AED581',
            '--color-light-green-4': '#9CCC65',
            '--color-light-green-5': '#8BC34A',
            '--color-light-green-6': '#7CB342',
            '--color-light-green-7': '#689F38',
            '--color-light-green-8': '#558B2F',
            '--color-light-green-9': '#33691E',
            '--color-light-green-a1': '#CCFF90',
            '--color-light-green-a2': '#B2FF59',
            '--color-light-green-a4': '#76FF03',
            '--color-light-green-a7': '#64DD17',
            '--color-lime-0': '#F9FBE7',
            '--color-lime-1': '#F0F4C3',
            '--color-lime-2': '#E6EE9C',
            '--color-lime-3': '#DCE775',
            '--color-lime-4': '#D4E157',
            '--color-lime-5': '#CDDC39',
            '--color-lime-6': '#C0CA33',
            '--color-lime-7': '#AFB42B',
            '--color-lime-8': '#9E9D24',
            '--color-lime-9': '#827717',
            '--color-lime-a1': '#F4FF81',
            '--color-lime-a2': '#EEFF41',
            '--color-lime-a4': '#C6FF00',
            '--color-lime-a7': '#AEEA00',
            '--color-yellow-0': '#FFFDE7',
            '--color-yellow-1': '#FFF9C4',
            '--color-yellow-2': '#FFF59D',
            '--color-yellow-3': '#FFF176',
            '--color-yellow-4': '#FFEE58',
            '--color-yellow-5': '#FFEB3B',
            '--color-yellow-6': '#FDD835',
            '--color-yellow-7': '#FBC02D',
            '--color-yellow-8': '#F9A825',
            '--color-yellow-9': '#F57F17',
            '--color-yellow-a1': '#FFFF8D',
            '--color-yellow-a2': '#FFFF00',
            '--color-yellow-a4': '#FFEA00',
            '--color-yellow-a7': '#FFD600',
            '--color-amber-0': '#FFF8E1',
            '--color-amber-1': '#FFECB3',
            '--color-amber-2': '#FFE082',
            '--color-amber-3': '#FFD54F',
            '--color-amber-4': '#FFCA28',
            '--color-amber-5': '#FFC107',
            '--color-amber-6': '#FFB300',
            '--color-amber-7': '#FFA000',
            '--color-amber-8': '#FF8F00',
            '--color-amber-9': '#FF6F00',
            '--color-amber-a1': '#FFE57F',
            '--color-amber-a2': '#FFD740',
            '--color-amber-a4': '#FFC400',
            '--color-amber-a7': '#FFAB00',
            '--color-orange-0': '#FFF3E0',
            '--color-orange-1': '#FFE0B2',
            '--color-orange-2': '#FFCC80',
            '--color-orange-3': '#FFB74D',
            '--color-orange-4': '#FFA726',
            '--color-orange-5': '#FF9800',
            '--color-orange-6': '#FB8C00',
            '--color-orange-7': '#F57C00',
            '--color-orange-8': '#EF6C00',
            '--color-orange-9': '#E65100',
            '--color-orange-a1': '#FFD180',
            '--color-orange-a2': '#FFAB40',
            '--color-orange-a4': '#FF9100',
            '--color-orange-a7': '#FF6D00',
            '--color-deep-orange-0': '#FBE9E7',
            '--color-deep-orange-1': '#FFCCBC',
            '--color-deep-orange-2': '#FFAB91',
            '--color-deep-orange-3': '#FF8A65',
            '--color-deep-orange-4': '#FF7043',
            '--color-deep-orange-5': '#FF5722',
            '--color-deep-orange-6': '#F4511E',
            '--color-deep-orange-7': '#E64A19',
            '--color-deep-orange-8': '#D84315',
            '--color-deep-orange-9': '#BF360C',
            '--color-brown-0': '#EFEBE9',
            '--color-brown-1': '#D7CCC8',
            '--color-brown-2': '#BCAAA4',
            '--color-brown-3': '#A1887F',
            '--color-brown-4': '#8D6E63',
            '--color-brown-5': '#795548',
            '--color-brown-6': '#6D4C41',
            '--color-brown-7': '#5D4037',
            '--color-brown-8': '#4E342E',
            '--color-brown-9': '#3E2723',
            '--color-gray-50': '#FAFAFA',
            '--color-gray-1': '#F5F5F5',
            '--color-gray-2': '#EEEEEE',
            '--color-gray-3': '#E0E0E0',
            '--color-gray-4': '#BDBDBD',
            '--color-gray-5': '#9E9E9E',
            '--color-gray-6': '#757575',
            '--color-gray-7': '#616161',
            '--color-gray-8': '#424242',
            '--color-gray-9': '#212121',
            '--color-blue-gray-0': '#ECEFF1',
            '--color-blue-gray-1': '#CFD8DC',
            '--color-blue-gray-2': '#B0BEC5',
            '--color-blue-gray-3': '#90A4AE',
            '--color-blue-gray-4': '#78909C',
            '--color-blue-gray-5': '#607D8B',
            '--color-blue-gray-6': '#546E7A',
            '--color-blue-gray-7': '#455A64',
            '--color-blue-gray-8': '#37474F',
            '--color-blue-gray-9': '#263238',
        }

        this.currentTheme = this.generic;
        this.setTheme(this.theme);
    }

    setTheme(theme) {
        if (!this.themes[theme])
            return;

        this.theme = theme;
        this.currentTheme = this.generic;
        Object.entries(this.themes[this.theme]!).forEach(([key, value]) => {
            this.currentTheme[`--color-${key}`] = value;
            document.documentElement.style.setProperty(`--color-${key}`, value);
        });
        this._changeTheme();
    }

    hex(e: string, opacity: number = 1): string {
        assert(opacity >= 0 && opacity <= 1);
        let opacityString = opacity == 1 ? '' : (Math.round(opacity * 100)).toString().padStart(2, '0');
        // debugger;
        return `${this.currentTheme[e]}${opacityString}`;
    }

    getThemes() { // returns a list of available themes
        return Object.keys(this.themes);
    }

    getTheme() { // returns the currently selected theme
        return this.currentTheme;
    }

    pumpColors() {
        return;
    }

    _changeTheme() {
        Object.entries(this.currentTheme).forEach(([key, value]) => {
            document.documentElement.style.setProperty(key, value);
        });
    }

    static hslToRgb(h, s, l) {    // Expects input to be 0-1
        var r, g, b;            // Since dygraph doesn't handle HSL colors and HSL colors are nice for assigning colors to
        if (s == 0) {             // n number of objects across the hsl wheel, we have to convert to rgb
            r = g = b = l;      // achromatic
        } else {
            var hue2rgb = function hue2rgb(p, q, t) {
                if (t < 0) t += 1;
                if (t > 1) t -= 1;
                if (t < 1 / 6) return p + (q - p) * 6 * t;
                if (t < 1 / 2) return q;
                if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
                return p;
            }

            var q = l < 0.5 ? l * (1 + s) : l + s - l * s;
            var p = 2 * l - q;
            r = hue2rgb(p, q, h + 1 / 3);
            g = hue2rgb(p, q, h);
            b = hue2rgb(p, q, h - 1 / 3);
        }

        return ('rgb(' + Math.round(r * 255) + ',' + Math.round(g * 255) + ',' + Math.round(b * 255) + ')');
    }

    static hex2rgba(hex, alpha = 1) {
        const [r, g, b] = hex.match(/\w\w/g).map(x => parseInt(x, 16));
        return `rgba(${r},${g},${b},${alpha})`;
    };
}
