import Icon from '../../../images/icons/name.svg';
import CloseIcon from '../../../images/icons/cancel.svg';
import { RegisterWidget, Widget } from '../../lib/widget';
import template from './modal.html';
import { Attribute } from '../../lib/attributes';

@RegisterWidget({tag: 'hmi-modal', displayName: 'Modal', icon: Icon, section: 'Layout'})
export class Modal extends Widget {
    titleElement: HTMLDivElement;
    closeIcon: HTMLImageElement;
    @Attribute({displayName: 'Title Text'}) titleText: string = 'Title';
    @Attribute({displayName: 'Min Width'}) preferredWidth:              string;     // modal container minimum width style setting
    @Attribute({displayName: 'Max Width'}) preferredHeight:              string;     // modal container maximum width style setting
    @Attribute({displayName: 'Min Height'}) minHeight:             string;     // modal container minimum height style setting
    @Attribute({displayName: 'Max Height'}) maxHeight:             string;     // modal container maximum height style setting
    @Attribute({displayName: 'Title Background'}) titleBackgroundColor:  string = '#000000';     // title bar background color
    @Attribute({displayName: 'Title Text Color'}) titleTextColor:        string = '#ffffff';     // title text color
    @Attribute({displayName: 'Is Animated'}) fAnimated:             boolean;    // flag for whether or not we want an animation on instantiation
    @Attribute({displayName: 'Prevent Close'}) fUncloseable:          boolean;
    closeCallback?: ()=>boolean;   // called just before modal is destroyed

    render(): Node | null {
        this.style.setProperty('--title-background-color', this.titleBackgroundColor);
        this.style.setProperty('--title-text-color', this.titleTextColor);
        this.style.setProperty('--preferred-width', this.preferredWidth);
        this.style.setProperty('--preferred-height', this.preferredHeight);

        let templateElement = document.createElement('template');
        templateElement.innerHTML = template;
        return templateElement.content.cloneNode(true);
    }

    protected enliven(): void {
        this.titleElement = this.shadowRoot?.getElementById('title-bar') as HTMLDivElement;
        this.titleElement.textContent = this.titleText;
        this.closeIcon = this.shadowRoot?.getElementById('close-icon') as HTMLImageElement;
        this.closeIcon.src = CloseIcon;
        this.closeIcon.onclick = () => {
            this.parentElement?.removeChild(this);
        }
    }
}