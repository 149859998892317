import owner, { Routes } from "../../owner";
import { AlarmBadge } from "../alarmwidget";
import assert from "../debug";
import { Device } from '../device';
import { createElement } from '../elements';
import { BooleanFill, GenericGraph, PumpSystemCardGraph } from "../graph";
import { Node, NodeQuality, NodeFlags } from '../node';
import ResponsiveBarGraph from "../responsivebargraph";
import SquishedCurve    from "../squishedcurve";
import ValueDisplay     from "../valuedisplay";
import ChartIcon        from '../images/icons/chart.svg';
import DashboardIcon    from '../images/icons/dashboard.svg';
import DisconnectedIcon from '../images/icons/disconnected.svg';

import './devicecard.css';


import { Widget } from "../widget";
import { Role } from "../role";
import { getHash } from "../router/router";

export class DeviceCardFactory {
    parent: HTMLElement;
    device: Device;
    graphHeight?: number;
    graphWidth?: number;
    fInitializing: boolean = false;
    fInitialized: boolean = false;
    card: DeviceCard;
    constructor(parent: HTMLElement, device: Device) {
        this.parent = parent;
        this.device = device;
    }
    initialize() {
        this.fInitializing = true;
        if (this.device.connected) {
            this.device.onDisconnect.set(this, this.onDeviceStatusChanged.bind(this));
        }
        else
            this.device.onConnect.set(this, () => this.onDeviceStatusChanged());

        if (this.device.connected || this.device.cachedTree) {
            this.device.requestNodeTree(this, (returnedDevice: Device) => {
                const rootNode      = returnedDevice.tree.nodes[0]!;
                const pumpSystem    = rootNode.findChildByRole(Role.ROLE_PUMP_BANK);
                const dpoFolder     = rootNode.findChildByRole(Role.ROLE_DPO_FOLDER);
                const alternators   = rootNode.findByRole(Role.ROLE_ALTERNATOR_FOLDER);
                const hasConfig     = rootNode.findChildByRole(Role.ROLE_CONFIG_ERRORS);

                const assetMode = dpoFolder ? dpoFolder.findChild('AssetManagementMode') : null;
                let fAssetOnly   = assetMode && assetMode.getValue() && !owner.ldc.isPowerUser();
                if(alternators.length > 1) {		// Simple Mustang Well
                    this.card = new WellCard(this.parent, this.device).initialize();
                } else if (alternators.length == 1 && pumpSystem) {		// Mustang well with opt booster
                    alternators.push(pumpSystem);
                    this.card = new WellCard(this.parent, this.device).initialize();
                } else if (pumpSystem) { //It's a pump station.
                    if (pumpSystem.findChildByRole(Role.ROLE_LOW_FLOAT_INDICATOR)) {
                        if (dpoFolder) {
                            this.card = new PumpStationCard(this.parent, this.device).initialize();
                        }
                        else {
                            this.card = new LiftStationCard(this.parent, this.device).initialize();
                        }
                    }
                    else if (dpoFolder) {
                        this.card = new PumpStationCard(this.parent, this.device).initialize();
                    }
                    else {
                        this.card = new TaggerCard(this.parent, this.device).initialize();
                    }

                } else if (alternators.length == 1) {
                    if(alternators[0].findChildByRole(Role.ROLE_LOW_FLOAT_INDICATOR)) //It must be a lift station.
                        this.card = new LiftStationCard(this.parent, this.device).initialize();
                    else
                        this.card = new TaggerCard(this.parent, this.device).initialize();
                }
                else if (this.device.customFiles.length > 0)
                    this.card = new CustomCard(this.parent, this.device).initialize();
                else
                    this.card = new TaggerCard(this.parent, this.device).initialize();
                this.fInitializing = false;
                this.fInitialized = true;
                this.fInitializing = false;
            })
        }
        else {
            this.card = new DisconnectedCard(this.parent, this.device).initialize();
            this.fInitializing = false;
            this.fInitialized = true;
            this.fInitializing = false;
        }
        return this;
    }

    onDeviceStatusChanged() {
        if (this.fInitialized)
            this.uninitialize();
        this.initialize();
    }

    uninitialize() {
        if (this.card && this.card.fInitialized)
            this.card.destroy();
        this.fInitialized = false;
    }

    resize() {
        if (this.fInitialized)
            this.card.resize();
    }

    destroy() {
        this.device.onConnect.delete(this);
        this.device.onDisconnect.delete(this);
    }
}

export class DeviceCard {
    wrapper: HTMLElement;
    titleWrapper: HTMLElement;
    siteName: HTMLElement;
    device: Device;
    graphHeight: number | null;
    graphWidth: number | null;
    observer: IntersectionObserver;
    fInitialized: boolean;
    alarmBadge: AlarmBadge;
    dataWrapper: HTMLElement;
    container: HTMLElement;
    constructor(parent: HTMLElement, device: Device) {
        this.wrapper        = createElement('div', 'device-card__wrapper', parent);
        this.device         = device;
        createElement('div', 'device-card__overlay', this.wrapper);
        this.container      = createElement('div', 'device-card__container', this.wrapper);
        this.alarmBadge     = new AlarmBadge(this.container, this.device, owner.alarmPanel);
    }

    initialize(): DeviceCard {
        this.titleWrapper   = createElement('div', 'explorer__list__card-title', this.container);
        let siteName        = `${this.device.siteName} ${this.device.connected ? '' : '(Disconnected)'}`
        this.siteName       = createElement('div', 'explorer__list__card-name', this.titleWrapper, siteName );
        this.siteName.style.fontSize = Math.min(Math.max((this.titleWrapper.clientWidth / siteName.length * 1.4), 12), 18) + 'px';
        this.dataWrapper    = createElement('div', 'explorer__data', this.container);
        if (this.device.isTreeComplete()) {
            this.container.onclick = () => location.hash = getHash(Routes.Device, {'key':this.device.key,'tab':'default','index':'0'});
            this.container.style.cursor = 'pointer';
        }
        if (!this.device.connected) {
            this.wrapper.classList.add('device-card__disconnected');
        }
        setTimeout(this.buildDeviceGuts.bind(this), 0);
        this.fInitialized = true;
        return this;
    }

    buildDeviceGuts() {}

    createTanks(tankGrid: HTMLElement, parentFolder: Node) {
        for (let i = 0; i < parentFolder.children.length; ++i) {	// Check each sub-folder (one for each tank)
            let tankFolder	= parentFolder.children[i];
            let level		= tankFolder.findChildByRole(Role.ROLE_TLC_TANK_LEVEL);	// Find each source tank level node
            assert(level !== null, "Should have found a level node for the source tank!");
            this.createTank(level!, tankFolder.getDisplayName(), tankGrid, null, null);
        }
    }

    createTank(level: Node, name: string, div: HTMLElement, lowLevel?: Node | null, highLevel?: Node | null) {
        var tankWrapper = createElement('div', 'explorer__data__tanks__wrapper', div);
        new TankHoverText(tankWrapper, name, level);    // Add the level to the hover text
        var tankElement = createElement('div', 'explorer__data__tanks__tank', tankWrapper);	// Create the division for the bar graph
        new ResponsiveBarGraph(tankElement,
        {	node:					level,
            fVertical:				true,
            lowLevel:               lowLevel,
            highLevel:              highLevel,
        }).initialize();	// Create and initialize the bar graph
    }

    createPumpIndicator(parent: HTMLElement, pump: Node, modelPump?: Node, index?: number) {
        let pumpContainer = createElement('div', 'explorer__data__pump-container', parent)
        let pumpColor       = createElement('div', 'explorer__data__pump-color', pumpContainer)
        pumpColor.style.backgroundColor = BooleanFill.colorStrings[index ?? 0]
        if (pump) {
            new PumpHoverText(createElement('div', 'explorer__data__pump-hover', pumpContainer), pump, modelPump!);
            new SquishedCurve(pumpContainer, pump, modelPump, {fUseNode:'false'}).initialize();
        }
    }

    resize() {};

    destroy() {
        if (this.fInitialized) {
            this.wrapper.destroyWidgets(true);
            this.wrapper.removeChildren();
            this.wrapper.parentElement?.removeChild(this.wrapper);
        }
    }
}

export class DisconnectedCard extends DeviceCard {
    constructor(parent: HTMLElement, device: Device) {
        super(parent, device);
    }

    buildDeviceGuts(): void {
        let disconnectWrapper = createElement('div', 'explorer__data__icon-wrapper', this.dataWrapper);
        createElement('img', 'explorer__disconnected-icon', disconnectWrapper, undefined, {'src':DisconnectedIcon});
        createElement('div', 'explorer__disconnected-text', disconnectWrapper, 'Device Disconnected');
        this.container.style.cursor = 'wait';
    }
}
export class PumpStationCard extends DeviceCard {
    graph: PumpSystemCardGraph;
    graphRow: HTMLElement;
    constructor(parent: HTMLElement, device: Device) {
        super(parent, device);
    }

    buildDeviceGuts(): void {
        const rootNode      = this.device.tree.nodes[0]!;
        const pumpSystem    = rootNode.findChildByRole(Role.ROLE_PUMP_BANK)!;
        const dpoFolder     = rootNode.findChildByRole(Role.ROLE_DPO_FOLDER)!;
        const alternators   = rootNode.findByRole(Role.ROLE_ALTERNATOR_FOLDER);
        const hasConfig     = rootNode.findChildByRole(Role.ROLE_CONFIG_ERRORS);

        const assetMode = dpoFolder ? dpoFolder.findChild('AssetManagementMode') : null;

        let fAssetOnly   = assetMode && assetMode.getValue() && !owner.ldc.isPowerUser();
        let sourceFolder = dpoFolder?.findChildByRole(Role.ROLE_TLC_SOURCE_TANK_FOLDER);
        let targetFolder = dpoFolder?.findChildByRole(Role.ROLE_TLC_TARGET_TANK_FOLDER);

        let station = createElement('div', 'explorer__data__station', this.dataWrapper);
        let values = createElement('div', 'explorer__data__values', this.dataWrapper);

        if (sourceFolder) {
            let tankGrid    = createElement('div', 'explorer__data__tanks', station);
            this.createTanks(tankGrid, sourceFolder)
        }
        let graphContainer = createElement('div', 'explorer__data__station__graphs__container', station);
        this.graphRow     = createElement('div', 'explorer__data__graph__row', graphContainer);
        createElement('div', 'explorer__data__station__interval', this.graphRow, 'Previous 24 Hours')

        if (targetFolder) {
            let newTankGrid    = createElement('div', 'explorer__data__tanks', station);
            this.createTanks(newTankGrid, targetFolder)
        }
        let pumpArray   = pumpSystem.findByRole(Role.ROLE_PUMP);	// Find all the pumps
        let modelFolder = pumpSystem.findChildByRole(Role.ROLE_MODEL_PUMPSYSTEM);
        let flowNode    = pumpSystem.findChildByRole(Role.ROLE_TOTAL_FLOW);
        let modelPumps	= modelFolder && modelFolder.findByRole(Role.ROLE_MODEL_PUMP);

        if (flowNode) {
            let displayWrapper = createElement('div', 'explorer__list__card-title__value', this.titleWrapper);
            displayWrapper.style.color = 'var(--color-blue-8)';
            new ValueDisplay(displayWrapper, {node:flowNode,fShowUnits:true}).initialize();
        }
        //let pumpRow     = createElement('div', 'explorer__data__station__pumps', station);
        for (let j = 0; j < pumpArray.length; ++j)
        {
            this.createPumpIndicator(values, pumpArray[j], modelPumps ? modelPumps.find(modelPump => modelPump.name == pumpArray[j].name) : undefined, j);
        }
        let options = {drawXAxis:false,drawYAxis:false,xLabelWidth:0,yLabelWidth:0,rightGap:0,drawXGrid:false,drawYGrid:false,fillAlpha:0.1}
        this.graph = new PumpSystemCardGraph(owner.ldc, pumpSystem, this.graphRow, this.graphRow.clientWidth, this.graphRow.clientHeight - 18, false, undefined, undefined, options);
    }

    resize() {
        this.graph?.graph?.resize(this.graphRow.clientWidth, this.graphRow.clientHeight - 18)
    }
}

export class WellCard extends DeviceCard {
    graph: PumpSystemCardGraph;
    graphRow: HTMLElement;
    constructor(parent: HTMLElement, device: Device) {
        super(parent, device);
    }

    buildDeviceGuts(): void {
        let rootNode        = this.device.tree.nodes[0]!;
        let alternators     = rootNode.findByRole(Role.ROLE_ALTERNATOR_FOLDER);
        let pumpSystem	    = rootNode.findChildByRole(Role.ROLE_PUMP_BANK);	// Save a pointer to the pump system node
        let wellFolder      = alternators[0];
        let boosterFolder   = alternators[1];
        let dpoFolder	    = rootNode.findChildByRole(Role.ROLE_DPO_FOLDER);
        let pumpArray       = wellFolder.findByRole(Role.ROLE_PUMP);
        let sourceTanks		= wellFolder.findByRole(Role.ROLE_TARGET_TANK_LEVEL);
        let targetTanks     = (dpoFolder||boosterFolder).findByRole(Role.ROLE_TARGET_TANK_LEVEL);
        let modelFolder: Node | null = null;
        let wellFlow        = wellFolder.findChildByRole(Role.ROLE_TOTAL_FLOW);

        if (boosterFolder)
            pumpArray.push(...boosterFolder.findByRole(Role.ROLE_PUMP))

        if (wellFlow) {
            let displayWrapper = createElement('div', 'explorer__list__card-title__value', this.siteName);
            displayWrapper.style.color = 'var(--color-blue-8)';
            new ValueDisplay(displayWrapper, {node:wellFlow,fShowUnits:true}).initialize();
        }

        let station = createElement('div', 'explorer__data__station', this.dataWrapper);
        let values = createElement('div', 'explorer__data__values', this.dataWrapper);
        let graphsRow = createElement('div', 'explorer__data__station__graphs', station);
        let graphContainer = createElement('div', 'explorer__data__station__graphs__container', graphsRow);

        if (pumpSystem) {
            pumpArray.unshift(...pumpSystem.findByRole(Role.ROLE_PUMP));
            modelFolder         = pumpSystem.findChildByRole(Role.ROLE_MODEL_PUMPSYSTEM);
            let modelPumps	    = modelFolder && modelFolder.findByRole(Role.ROLE_MODEL_PUMP);
            for (let j = 0; j < pumpArray.length; ++j)
                this.createPumpIndicator(values, pumpArray[j], modelPumps ? modelPumps[j] : undefined, j);
        }

        if (sourceTanks) {
            let tankGrid    = createElement('div', 'explorer__data__tanks', graphsRow);
            for (let i=0;i<sourceTanks.length; ++i) {
                this.createTank(sourceTanks[i], sourceTanks[i].getDisplayName(), tankGrid);
            }
        }

        this.graphRow     = createElement('div', 'explorer__data__graph__row', graphContainer);
        createElement('div', 'explorer__data__station__interval', this.graphRow, 'Previous 24 Hours')

        if (targetTanks) {
            let tankGrid    = createElement('div', 'explorer__data__tanks', graphsRow);
            for (let i=0;i<targetTanks.length; ++i) {
                this.createTank(targetTanks[i], targetTanks[i].getDisplayName(), tankGrid);
            }
        }
        let end			= new Date();						// Current time
        let endTime 	= end.getTime();
        let start		= new Date(endTime - 24*1000*3600);	// Default to the last hour
        let startTime   = start.getTime();

        if (!this.graphHeight)
            this.graphHeight = this.graphRow.clientHeight - 18;

        let options = {drawXAxis:false,drawYAxis:false,xLabelWidth:0,yLabelWidth:0,rightGap:0,minorXLines:0,drawXGrid:false,drawYGrid:false,fillAlpha:1.0}

        var pumpBank = rootNode.findChildByRole(Role.ROLE_PUMP_BANK);
        if(pumpBank) {
            this.graph = new PumpSystemCardGraph(owner.ldc, pumpBank, this.graphRow, this.graphRow.clientWidth, this.graphHeight, false, undefined, undefined, options);
        }
        else {
            this.graph = new GenericGraph(owner.ldc, this.graphRow, this.graphRow.clientWidth, this.graphHeight, start, end, false, undefined, undefined, options);	// Create the graph that takes care of the hard stuff for us
            let gstLevel 	= boosterFolder.findChildByRole(Role.ROLE_SOURCE_TANK_LEVEL);
            let estLevel 	= boosterFolder.findChildByRole(Role.ROLE_TARGET_TANK_LEVEL);
            let bDischarge 	= boosterFolder.findChildByRole(Role.ROLE_DISCHARGE_PRESSURE);

            if(gstLevel)
                this.graph.addNode(gstLevel, true, gstLevel.name, "red", true, gstLevel.engMax, gstLevel.engMin);
            if(estLevel)
                this.graph.addNode(estLevel, true, estLevel.name, "black", true, estLevel.engMax, estLevel.engMin);
            if(bDischarge)
                this.graph.addNode(bDischarge, true, bDischarge.name, "purple", true, bDischarge.engMax, bDischarge.engMin);

            var running	= wellFolder.findByRole(Role.ROLE_BOOL_RUNNING);
            if(running.length > 0)								// If we got pump running status nodes
                this.graph.createBooleanFill(running, true);	// Create a special fill for these guys
            this.graph.stopHighlighting();//@ts-ignore
            this.graph.requestDataForAllDevices(startTime, endTime, this.graph._calculateInterval(startTime, endTime), 0);

        }
    }

    resize() {
        this.graph?.graph?.resize(this.graphRow.clientWidth, this.graphHeight ?? 0)
    }
}

export class LiftStationCard extends DeviceCard {
    graph: PumpSystemCardGraph;
    graphRow: HTMLElement;
    constructor(parent: HTMLElement, device: Device) {
        super(parent, device);
    }

    buildDeviceGuts(): void {
        let rootNode        = this.device.tree.nodes[0]!;
        let station         = createElement('div', 'explorer__data__station', this.dataWrapper);
        let values          = createElement('div', 'explorer__data__values', this.dataWrapper);
        let graphsRow       = createElement('div', 'explorer__data__station__graphs', station);
        let graphContainer  = createElement('div', 'explorer__data__station__graphs__container', graphsRow);

        let liftStation	    = rootNode.findChildByRole(Role.ROLE_PUMP_BANK) ?? rootNode.findByRole(Role.ROLE_ALTERNATOR_FOLDER)[0];	// Pump Controller/Alternator node folder
        let inflow          = liftStation.findChild(Role.ROLE_INFLOW);
        let outflow         = liftStation.findChild(Role.ROLE_OUTFLOW);
        let pumpArray       = liftStation.findByRole(Role.ROLE_PUMP);
        let modelFolder     = liftStation.findChildByRole(Role.ROLE_MODEL_PUMPSYSTEM);
        let modelPumps	    = modelFolder && modelFolder.findByRole(Role.ROLE_MODEL_PUMP);
        let wetWellHeight	= liftStation.findChild("WetWellHeight") || liftStation.findChild(Role.ROLE_WET_WELL_DEPTH);
        let levelNode		= liftStation.findChildByRole(Role.ROLE_SOURCE_TANK_LEVEL);
        let lowLevel        = liftStation.findChildByRole(Role.ROLE_LOW_FLOAT_LEVEL);
        let highLevel       = liftStation.findChildByRole(Role.ROLE_HIGH_FLOAT_LEVEL);

        let tankGrid    = createElement('div', 'explorer__data__tanks', graphsRow);
        if (levelNode)
            this.createTank(levelNode, levelNode.parent.getDisplayName(), tankGrid, lowLevel, highLevel);

        this.graphRow     = createElement('div', 'explorer__data__graph__row', graphContainer);
        createElement('div', 'explorer__data__station__interval', this.graphRow, 'Previous Hour');

        for (var j = 0; j < pumpArray.length; ++j)
            this.createPumpIndicator(values, pumpArray[j], modelPumps ? modelPumps[j] : undefined, j);

        var end			= new Date();						// Current time
        var endTime 	= end.getTime();
        var start		= new Date(endTime - 1000*3600);	// Default to the last hour
        // var start		= new Date(endTime - 1000*3600*24);	// Default to the last day (in milliseconds)
        var startTime	= start.getTime();
        let options = {drawXAxis:false,drawYAxis:false,xLabelWidth:0,yLabelWidth:0,rightGap:0,minorXLines:0,drawXGrid:false,drawYGrid:false,fillAlpha:1.0}
        this.graph = new GenericGraph(owner.ldc, this.graphRow, this.graphRow.clientWidth, this.graphRow.clientHeight - 18, start, end, false, undefined, undefined, options);	// Create the graph that takes care of the hard stuff for us
        this.graph.stopHighlighting();
        if (inflow)
            this.graph.addNode(inflow, true, 'Inflow', owner.colors.hex('--color-blue-8'), true, inflow.engMax, inflow.engMin);
        if(outflow) {
            let displayWrapper = createElement('div', 'explorer__list__card-title__value', this.siteName);
            displayWrapper.style.color = 'var(--color-blue-8)';
            new ValueDisplay(displayWrapper, {node:outflow,fShowUnits:true}).initialize();
            this.graph.addNode(outflow, true, 'Outflow', 'maroon', true, outflow.engMax, outflow.engMin);
        }

        var running	= liftStation.findByRole(Role.ROLE_BOOL_RUNNING);
        if(running.length > 0)								// If we got pump running status nodes
            this.graph.createBooleanFill(running, true);	// Create a special fill for these guys
            //@ts-ignore
        this.graph.requestDataForAllDevices(startTime, endTime, this.graph._calculateInterval(startTime, endTime), 0);
    }

    resize() {
        this.graph?.graph?.resize(this.graphRow.clientWidth, this.graphRow.clientHeight - 18)
    }
}

export class TaggerCard extends DeviceCard {
    graph: PumpSystemCardGraph;
    graphRow: HTMLElement;
    constructor(parent: HTMLElement, device: Device) {
        super(parent, device);
    }

    buildDeviceGuts(): void {
        let rootNode        = this.device.tree.nodes[0]!;
        let logged: Node[]  = [];
        let superSpecials   = rootNode.findByRole(Role.ROLE_GRAPH_VALUE);

        if (superSpecials.length > 0)
            logged = superSpecials;
        else
            this.device.tree.getLoggedNodes(rootNode, logged);

        var end			= new Date();						// Current time
        var endTime 	= end.getTime();
        var start		= new Date(endTime - 24*1000*3600);	// Default to the last hour
        var startTime	= start.getTime();

        if (logged.length > 0) {
            let station         = createElement('div', 'explorer__data__station', this.dataWrapper);
            let values          = createElement('div', 'explorer__data__values', this.dataWrapper);
            let graphsRow       = createElement('div', 'explorer__data__station__graphs', station);
            let graphContainer  = createElement('div', 'explorer__data__station__graphs__container', graphsRow);

            this.graphRow       = createElement('div', 'explorer__data__graph__row', graphContainer);
            let intervalLabel   = createElement('div', 'explorer__data__station__interval', this.graphRow, 'Previous 24 Hours');

            let options = {drawXAxis:false,drawYAxis:false,xLabelWidth:0,yLabelWidth:0,rightGap:0,drawXGrid:false,drawYGrid:false,fillAlpha:0.6}
            this.graph = new GenericGraph(owner.ldc, this.graphRow, this.graphRow.clientWidth, this.graphRow.clientHeight - 18, start, end, false, undefined, undefined, options);	// Create the graph that takes care of the hard stuff for us
            for (let i=0;i<Math.min(logged.length, 5);i++) {
                if (logged[i].flags & NodeFlags.NF_LOG || logged[i].flags & NodeFlags.NF_DERIVED) {
                    this.graph.addNode(logged[i], true, null, owner.colors.hex('--color-graph-'+(i+1)), false, logged[i].engMax, logged[i].engMin);
                    let container = createElement('div', 'explorer__data__value__container', values);
                    let units = logged[i].getUnitsText();
                    createElement('div', 'explorer__data__values__title', container, `${logged[i].getDisplayName()} ${units == '' ? '' : `(${units})`}`);
                    let displayWrapper = createElement('div', 'explorer__data__values__value', container);
                    let color = owner.colors.hex('--color-graph-'+(i+1));
                    container.style.color = color;
                    container.style.borderColor = color;
                    new ValueDisplay(displayWrapper, {node:logged[i],fShowUnits:false}).initialize();
                }
            }
            this.graph.stopHighlighting();
            this.graph.requestDataForAllDevices(startTime, endTime);
        }
        else {
            let noLoggedWrapper = createElement('div', 'explorer__data__icon-wrapper', this.dataWrapper)
            createElement('img', 'explorer__icon', noLoggedWrapper, undefined, {'src':ChartIcon});
            createElement('div', 'explorer__text', noLoggedWrapper, 'No Logged Tags');
        }
    }

    resize() {
        this.graph?.graph?.resize(this.graphRow.clientWidth, this.graphRow.clientHeight - 18)
    }
}

export class CustomCard extends DeviceCard {
    graph: PumpSystemCardGraph;
    constructor(parent: HTMLElement, device: Device) {
        super(parent, device);
    }

    buildDeviceGuts(): void {
        let noLoggedWrapper = createElement('div', 'explorer__data__icon-wrapper', this.dataWrapper)
        let noLoggedIcon = createElement('img', 'explorer__icon', noLoggedWrapper, undefined, {'src':DashboardIcon});
        let noLoggedText = createElement('div', 'explorer__text', noLoggedWrapper, 'Dashboard');
    }
}
// This is a little helper class to put the tank level on the tanks
class TankHoverText extends Widget {
    element: HTMLElement;
    tankName: string;
    tank: Node;
    constructor(element: HTMLElement, tankName: string, tank: Node) {
        super();
        assert(tank, 'Tank hover text widget requires a level node.')
        if (!tank) return;
        this.element	= element;		// Save the reference to the element
        this.tankName	= tankName;		// Save the string with the tank's name
        this.tank		= tank;			// Save the reference to the node
        this.registerAsWidget(this.element);	// Register with the element like a good little widget
        this.tank.subscribe(this);		// Subscribe to the node
    };

    update(node) {
        if (node.quality != NodeQuality.NQ_GOOD)	// Bad quality on this node
            return;
        this.element.title = this.tankName + ": " + node.getFormattedText(true);	// Create hover text with the tank name
    };

    destroy() {
        if (this.tank)
            this.tank.unsubscribe(this);
        this.unregisterAsWidget();
    };
};

class PumpHoverText extends Widget {
    element: HTMLElement;
    pumpName: string;
    runTimeNode: Node | null;
    actSpeedNode: Node | null;
    running: Node | null;
    bepNode: Node | null;
    flowNode: Node | null;
    constructor(element: HTMLElement, pump: Node, modelPump: Node) {
        super();
        this.element        = element;
        this.pumpName       = pump.getDisplayName();
        this.runTimeNode    = pump.findChildByRole(Role.ROLE_PUMP_CURRENT_RUN_TIME);
        this.running        = pump.findChildByRole(Role.ROLE_BOOL_RUNNING);
        this.actSpeedNode	= pump.findChildByRole(Role.ROLE_ACT_SPEED);

        if (modelPump) {
            this.bepNode    = modelPump.findChild('PercentBEP');
            this.flowNode   = modelPump.findChildByRole(Role.ROLE_MODEL_PUMP_FLOW);
        }
        this.registerAsWidget(this.element);
        if (this.bepNode)
            this.bepNode.subscribe(this);
        if (this.runTimeNode)
            this.runTimeNode.subscribe(this);
        if (this.flowNode)
            this.flowNode.subscribe(this);
        if (this.running)
            this.running.subscribe(this);
        if (this.actSpeedNode)
            this.actSpeedNode.subscribe(this);
    }

    update(node) {
        if (node.quality != NodeQuality.NQ_GOOD)	// Bad quality on this node
            return;
        this.element.title = this.pumpName;
        if (this.actSpeedNode) {
            this.element.title += "\n" + this.actSpeedNode.getFormattedText(true);	// Create hover text with the tank name
        }
        if (this.flowNode) {
            this.element.title += "\n" + this.flowNode.getFormattedText(true);	// Create hover text with the tank name
        }
        if (this.bepNode) {
            this.element.title += "\n" + this.bepNode.getFormattedText(true);	// Create hover text with the tank name
        }
        if (this.runTimeNode) {
            this.element.title += "\n" + this.runTimeNode.getFormattedText(true) + ' ' + (this.running?.getValue() ? 'On' : 'Off');
        }
    }

    destroy() {
        if (this.bepNode)
            this.bepNode.unsubscribe(this);
        if (this.runTimeNode)
            this.runTimeNode.unsubscribe(this);
        if (this.flowNode)
            this.flowNode.unsubscribe(this);
        if (this.running)
            this.running.unsubscribe(this);
        if (this.actSpeedNode)
            this.actSpeedNode.unsubscribe(this);
        this.unregisterAsWidget();
    };
}