import assert from '../debug';
import { createElement } from '../elements';
import owner from '../../owner';
import ViewModal from '../viewmodal';
import View from '../views/view';
import AddIcon from '../images/icons/add.svg';
import RemoveIcon from '../images/icons/remove.svg';
import './tagsocket.css'
import TreeView, {TreeViewTypes} from '../views/treeview';

/**
 * TagSocket
 * @param  {string}         name
 * @param  {string}         description
 * @param  {string[]}       props=[]
 * @param  {TagFilter[]}    andFilters
 * @param  {TagFilter[]}    orFilters
 * @param  {boolean}        fMultiple=false
 */
export class TagSocket {

    constructor(name, description, props = [], andFilters, orFilters, fMultiple = false) {
        assert(name, 'Tag Sockets must be provided a name');
        this.name           = name;
        this.description    = description;
        this.props          = props;
        this.andFilters     = andFilters;
        this.orFilters      = orFilters;
        this.fMultiple      = fMultiple;
        this.tags           = [];
        this.devices        = new Map();
    }

    add(serializedTag) {
        serializedTag.socket = this.name;
        if (!this.tags.some((tag)=>tag.deviceKey == serializedTag.deviceKey && tag.path == serializedTag.path))
            this.tags.push(serializedTag);
    }
    remove(index) {
        if (index > -1) {
            this.tags.splice(index, 1);
        }
    }
    modify(tag) {
    }
    resolveTag(tag) {
        let device = owner.ldc.devices.getByKey(tag.deviceKey);
        device.requestNodeTree(this,this.onNodeTreeComplete);
        let resolvedTag = device.tree.findNode(tag.path);
        return resolvedTag;
    }

    onNodeTreeComplete() {

    }

    getDefaultProp(prop, tag) {
        switch(prop) {
            case 'name':
                return tag.getDisplayName();
            case 'color':
                return owner.colors.hex('--color-graph-' + (this.tags.length + 1));
            case 'period':
                return '1 Day';
            case 'reps':
                return 7;
            case 'ranges':
                return [[tag.engMin, tag.engMax],['#7ea0f3']];
        }
    }
}
export class SocketList {
    constructor(socketList, card) {
        this.card                       = card;
        this.card.element.socketList    = this;
        this.sockets                    = new Map();
        this.deviceTagMap               = new Map();
        this.createSockets(socketList);
    }

    add(socket) {
        socket.list = this;
        this.sockets.set(socket.name,socket);
    }

    populate() {
        for (let i = 0; i < this.card.tags.length; i++) {
            let tag = this.card.tags[i];
            tag.fBadStatus = true; // bad status until proven good
            let device = owner.ldc.devices.getByKey(tag.deviceKey);
            if (!device) { // user doesn't have access to this device; omit this tag
                tag.fBadStatus = true;
                continue;
            }
            if (!this.deviceTagMap.has(device))
                this.deviceTagMap.set(device, {
                    tags: [tag],
                    fTreeComplete: false
                });
            else
                this.deviceTagMap.get(device).tags.push(tag);
            if (!tag.socket)
                this.sockets.values().next().value.add(tag); // if we don't have a socket (i.e. for widgets created before sockets existed), add them to the first socket by default
            else
                this.sockets.get(tag.socket).add(tag);
        }
        for (let device of this.deviceTagMap.keys()) {                  // for each device we're concerned with
            if (device.connected)                                       // if our device is connected
                device.requestNodeTree(this, this.onNodeTreeComplete);  // request the node tree
            else
                device.onConnect.set(this, this.onDeviceConnect);       // if not, set up our connect callback
        }
        this.sync(); // make sure we sync our tags just in case we added a property to a tag that previously didn't have it
    }

    refresh() {
        this.deviceTagMap.clear();
        for (let value of this.sockets.values()) {
            value.tags = [];
        }
        this.populate();
    }

    onNodeTreeComplete(device) {
        let deviceProps = this.deviceTagMap.get(device);
        let tags = deviceProps.tags;       // get all the tags for this device
        deviceProps.fTreeComplete = true;
        for (let i=0;i<tags.length;++i) {               // for each tag
            if (this.card.resolveTag(tags[i])) {        // check if it is a valid tag
                tags[i].fBadStatus = false;
                device.onConnect.delete(this);
            }
            else
                tags[i].fBadStatus = true;
        }
        device.onDisconnect.set(this, this.onDeviceDisconnect);     // we know we are connected and have a node tree at this point
        this.sync();                                                // sync everything up //FIXME: should we be rebuilding each time? seems excessive
        if (Array.from(this.deviceTagMap.values()).every(deviceProps => deviceProps.fTreeComplete))
            this.card.rebuild();
    }

    onDeviceConnect(device) {
        this.deviceTagMap.get(device).fTreeComplete = false;
        device.requestNodeTree(this, this.onNodeTreeComplete);
    }

    onDeviceDisconnect(device) {
        let tags = this.deviceTagMap.get(device).tags;
        for (let i=0;i<tags.length;++i)
            tags[i].fBadStatus = true;
        device.onDisconnect.delete(this);
        device.onConnect.set(this, this.onDeviceConnect);
        this.sync();
    }

    createSockets(socketList) {
        for (let i = 0; i < socketList.length; i++) {
            socketList[i].list = this;
            this.sockets.set(socketList[i].name, socketList[i]);
        }
    }

    modify() {
        new ViewModal(new SocketView(this, ()=>this.sync()));
    }

    sync() {
        this.card.tags = [];
        for (let [name, socket] of this.sockets.entries()) {
            for (let i=0;i<socket.tags.length;++i) {
                let tag = socket.tags[i]
                this.card.tags.push(tag)
            }
        }

        if (this.card.tags.some((tag)=>tag.fBadStatus))
            this.card.element.warning.style.opacity = '1';
        else
            this.card.element.warning.style.opacity = '0';
    }

    destroy() {
        for (let device of this.deviceTagMap.keys()) {                  // for each device we're concerned with
            device.onConnect.delete(this);
            device.onDisconnect.delete(this);
        }
    }
}

export class SocketView extends View {
    constructor(socketList, callback) {
        super();
        this.socketList = socketList;
        this.sockets    = this.socketList.sockets;
        this.callback   = callback;
    }
    initialize(parent) {
        super.initialize(parent);
        this.wrapper = createElement('div', 'socket', this.parent, undefined, undefined);
        this.buildSockets();
        this.warningWrapper = createElement('div', 'socket__warning-wrapper', this.wrapper);
        this.warning        = createElement('div', 'socket__warning-wrapper__warning', this.warningWrapper);
    }

    buildSockets() {
        this.wrapper.removeChildren();

        for (let socket of this.sockets.values()) {
            let socketWrapper = createElement('div', 'socket__wrapper', this.wrapper, undefined, undefined);
            let socketContainer = createElement('div', 'socket__container', socketWrapper, undefined, undefined);
            let socketName      = createElement('div', 'socket__container__name', socketContainer, socket.name, undefined);
            socket.tags.forEach((tag, index) => {
                let tagContainer    = createElement('div', 'socket__tag-container ' + (tag.fBadStatus ? 'socket__tag-container__bad' : ''), socketContainer);
                let tagDetails      = createElement('div', 'socket__tag-details', tagContainer);
                let tagPath         = createElement('div', 'socket__tag-details__path', tagDetails, tag.deviceKey + tag.path);
                let propContainer   = createElement('div', 'socket__tag-details__prop-container', tagDetails);
                for (let [prop, value] of Object.entries(tag)) {
                    switch (prop) {
                        case 'name':
                            let nameEditor = createElement('input', 'tag-color__node-wrapper__name-input', propContainer, undefined, {})
                            nameEditor.value = value? value : '';
                            nameEditor.onchange = () => {
                                tag[prop] = nameEditor.value;
                                this.callback();
                            }
                            break;
                        case 'color':
                            let tagColor = createElement('input', 'tag-color__node-wrapper__color', propContainer, '', {'type':'color'});
                            tagColor.value = value;
                            tagColor.onchange = () => {
                                tag[prop] = tagColor.value;
                            };
                            break;
                        case 'period':
                            let tagPeriod = createElement('select', 'socket__select', propContainer);
                            let periods = [
                                '1 Day',
                                '1 Week',
                                '30 Days',
                                '1 Year',
                            ]
                            for (let i=0;i<periods.length;i++) {
                                createElement('option', 'socket__option', tagPeriod, periods[i]);
                            }
                            tagPeriod.onchange = () => {
                                tag[prop] = tagPeriod.selectedOptions[0].value;
                                this.callback();
                            }
                            break;
                        case 'reps':
                            let tagReps = createElement('select', 'socket__select', propContainer);
                            let reps = [
                                '5',
                                '7',
                                '30',
                                '365',
                            ]
                            for (let i=0;i<reps.length;i++) {
                                createElement('option', 'socket__option', tagReps, reps[i]);
                            }
                            tagReps.onchange = () => {
                                tag[prop] = tagReps.selectedOptions[0].value;
                                this.callback();

                            }
                            break;
                        case 'ranges':
                            let optionWrapper       = createElement('div', 'socket___range__column', propContainer)
                            let rangeWrapper        = createElement('div', 'socket__range', optionWrapper);
                            let inputColumn         = createElement('div', 'socket__range__column', rangeWrapper);
                            let colorColumn         = createElement('div', 'socket__range__column', rangeWrapper);
                            colorColumn.style.margin = '16px 0 0 0'
                            let addButton           = createElement('div', 'socket__add__button', optionWrapper);
                            addButton.style.width   = '100%';
                            createElement('div', 'socket__add__button__text', addButton, 'Add Range');
                            createElement('img', 'socket__add__button__icon', addButton, undefined, {'src':AddIcon});
                            addButton.onclick = () => {
                                value[0].push(value[0][value[0].length - 1]);
                                value[1].push('#7ea0f3');
                                this.buildSockets();
                            }
                            for (let i=0;i<value[0].length;i++) {
                                let rangeInput = createElement('input', 'spinner', inputColumn, undefined, {   'autocomplete'     : 'off',
                                                                                                                'autocapitalize'   : 'off',
                                                                                                                'autocorrect'      : 'off',
                                                                                                                'spellcheck'       : 'false',
                                                                                                                'type'             : 'number'});	// Create the input element
                                rangeInput.value = value[0][i];
                                rangeInput.onchange = () => {
                                    tag[prop][0][i] = rangeInput.value;
                                    this.callback();
                                }
                                if (i>0) {
                                    let tagColor = createElement('input', 'tag-color__node-wrapper__color', colorColumn, '', {'type':'color'});
                                    tagColor.value = value[1][i - 1];
                                    tagColor.onchange = () => {
                                        tag[prop][1][i - 1] = tagColor.value;
                                        this.callback();
                                    }
                                }
                            }
                            break;
                        default:
                            break;
                    }
                }
                let removeButton = createElement('div', 'socket__tag-container__remove', tagContainer);
                createElement('img', 'socket__add__button__icon', removeButton, undefined, {'src':RemoveIcon});
                removeButton.onclick = () => {
                    socket.remove(index);
                    this.buildSockets();
                    this.callback();
                }
            })

            if (socket.tags.length == 0 || socket.fMultiple) {
                let addContainer    = createElement('div', 'socket__add', socketWrapper);
                this.addButton       = createElement('div', 'socket__add__button', addContainer);
                createElement('div', 'socket__add__button__text', this.addButton, 'Add Tag');
                createElement('img', 'socket__add__button__icon', this.addButton, undefined, {'src':AddIcon});
                let addProperties = {
                    type:               socket.fMultiple? TreeViewTypes.TVT_MULTISELECT_ACCEPT : TreeViewTypes.TVT_SELECT_ACCEPT,
                    selectedTags:       socket.tags.map(tag => this.socketList.card.resolveTag(tag)),
                    selectCallback:     this.selectCallback.bind(this, socket),
                    deselectCallback: 	this.deselectCallback.bind(this, socket),
                    andFilters:         socket.andFilters,
                    orFilters:          socket.orFilters,
                }
                this.addButton.onclick = () => new ViewModal(new TreeView(addProperties), {
                    closeCallback: ()=>this.socketList.card.rebuild()
                })
            }
        }
    }

    selectCallback(socket, tags) {
        for (let i=0;i<tags.length;i++) {
            let serializedTag = {deviceKey:tags[i].tree.device.key, path:tags[i].getDeviceRelativePath()}
            for (let j=0;j<socket.props.length;j++) {
                serializedTag[socket.props[j]] = socket.getDefaultProp(socket.props[j], tags[i])
            }
            socket.add(serializedTag)
        }
        this.buildSockets();
        this.callback();
        if (!tags || tags.length == 0)
            socket.card.editor.removeCard(this.socketList.card);
        //this.modal.destroy();
    }

    deselectCallback(socket, tag) {
        socket.tags.splice(socket.tags.indexOf(tag), 1);
        this.buildSockets();
        this.callback();
    }

}
