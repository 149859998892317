//@ts-nocheck

import owner from "../../../owner";
import assert from "../../debug";
import { Device } from "../../device";
import Dialog from "../../dialog";
import { createElement } from "../../elements";
import FrameParser from "../../frameparser";
import { StaticGraph } from "../../graph";
import LiveData from "../../livedata";
import localization from "../../localization";
import MouseCapture from "../../mousecapture";
import { Node, NodeQuality } from '../../node';
import NodeManager from "../../nodemanager";
import EditorPage from "../../pages/editorpage";
import { findBestEfficiencyFlow } from "../../pump";
import SquishedCurve from "../../squishedcurve";
import { checkLimits, createCaption, createGraphPulse, interpolateSECs } from "../../views/specificenergyview";
import { Gizmo } from "./gizmo";
import '../../views/specificenergyview.css'
import { TagUnit, UnitsMap, convert } from "../../widgets/lib/tagunits";
import { Role } from "../../role";

interface SerializedMapDevice {
    deviceKey: string;
    sourceSEC: number;
}

interface MapDevice {
    device: Device;
    sourceSEC: number;
    devPumps?: any[];
    targetSpeeds?: Node[];
    regimes?: MapRegime[];
}

interface MapLimit {
    minFlow: number;
    maxFlow: number;
    minSEC: number;
    maxSEC: number;
}

interface MapPoint {
    flow?: number;
    power?: number;
    flags?: number;
    sec?: number;
    regimes?: MapRegime[];
    indexes?: number[];
    fReq?: boolean;
    flowArray?: number[];
    powerArray?: number[];
    flagArray?: number[];
    speedArray?: number[];
}

interface MapRegime {
    config?: number;
    devID?: number;
    bestFlow?: number;
    bestPower?: number
    bestSEC?: number;
    sec?: number;
    pointArray?: MapPoint[];
}

export class UnifiedSEMapGizmo extends Gizmo   {
        index: number;
        mapDevices: Set<MapDevice> = new Set();
        deviceMap: Map<Device, MapDevice> = new Map();
        flowUnits: number;
        secUnits: number;
        flow: number;
        sec: number
        filterID: NodeJS.Timer;
        graphID: number;
        labels: string[];
        colors: string[];
        selectedRegime: number;
        selectedPoint: number;
        nodeManager: NodeManager;
        pumps: Node[];
        modelPumps: Node[];
        flowNodes: Node[];
        powerNodes: Node[];
        targetSpeeds: Node[];
        lineOptions: any;
        options: any;
        visible: boolean;
        graph: StaticGraph;
        flowDiv: HTMLElement;
        secDiv: HTMLElement;
        graphDiv: HTMLElement;
        currentPulse: SVGElement;
        targetPulse: SVGElement;
        selectPulse: SVGElement;
        parent: HTMLElement;
        selection: HTMLElement;
        arrow: HTMLElement;
        fHiddenSelection: boolean;
        selectedFlow: HTMLElement;
        selectedSEC: HTMLElement;
        nameDivs: HTMLElement[] = [];
        flowDivs: HTMLElement[] = [];
        powerDivs: HTMLElement[] = [];
        speedDivs: HTMLElement[] = [];
        flagDivs: SquishedCurve[] = [];
        keyPressFunction: ()=>{};
        limits: MapLimit;
        mapCount: number;
        capture: MouseCapture;
        aggregate: MapRegime[];
        top: number;
        left: number;
        data: any[][];
        lineLetter: string   =	'C';
        goodLetter: string   =	'D';
        okayLetter: string   =  'E';
        badLetter: string    =	'F';
        fInitialized: boolean;
        serializedMapDevices: Set<SerializedMapDevice> = new Set();
        public connectedCallback(): void {

            super.connectedCallback();
            this.index		= -1;

            if (!this.recipe.settings['MapDevices'])
                this.recipe.settings['MapDevices'] = [];
            this.rebuild();
            //this.rebuild();
            // Plant 13 + Wells = 9 pumps (512 combinations)
            // Plant 4 + Wells + Plant 13 = 16 pumps (65536 combinations)
        }

        rebuild() : void {
            this.fInitialized = false;

            if (this.nodeManager)
                this.nodeManager.destroy();
            this.mapDevices = new Set();
            this.deviceMap.clear();
            this.serializedMapDevices = new Set(this.recipe.settings['MapDevices']);
            if (this.serializedMapDevices.size < 1)
                return;
            for (let serializedDevice of this.serializedMapDevices) {
                let device = owner.ldc.devices.getByKey(serializedDevice.deviceKey);
                if (!device || !device.connected) {
                    //TODO: Show a warning?
                    continue;
                }

                let mapDevice: MapDevice = {
                    device: device,
                    sourceSEC: serializedDevice.sourceSEC
                }
                this.mapDevices.add(mapDevice);
                this.deviceMap.set(device, mapDevice);
            }

            for (let mapDev of this.mapDevices) {
                mapDev.device.requestNodeTree(this, this.onNodeTreeComplete);
            }
        }

        onNodeTreeComplete(device: Device) {
            for (let mapDev of this.mapDevices) { // check if any of our devices are disconnected or haven't given us a node tree yet
                if (!mapDev.device.isTreeComplete() || this.fInitialized) // if any of our trees aren't complete, back out until they are
                    return;
            }
            this.initialize()
        }

        initialize() {
            this.flowUnits		= TagUnit.TU_GPM;
            this.secUnits		= TagUnit.TU_KW_HR_PER_MG;
            this.flow			= -1;	// IIR filter storage values for the current point
            this.sec			= -1;
            if (this.filterID)
                clearInterval(this.filterID);
            this.filterID		= setInterval(this.updateCurrentPoint.bind(this), 200, this);	// Update five times a second
            this.graphID		= owner.ldc.registerGraph(this);	// Register for graph data
            this.labels			= [];
            this.colors			= [];
            this.selectedRegime	= 0;			// Start off on the 1st regime selected
            this.selectedPoint	= 0;			// Start off on the first point in the 0 regime

            var date			= new Date().getTime() * 1000;		// Most recent pump curve requested
            this.nodeManager	= new NodeManager(this);
            this.pumps = [],this.modelPumps = [], this.flowNodes = [], this.powerNodes = [], this.targetSpeeds = [];
            for (let device of this.mapDevices) {
                var pumpSystem 		= device.device.tree.nodes[0].findChildByRole(Role.ROLE_PUMP_BANK);
                var dpoFolder 		= device.device.tree.nodes[0].findChildByRole(Role.ROLE_DPO_FOLDER);
                var devPumps		= pumpSystem.findByRole(Role.ROLE_PUMP);
                device.devPumps 	= devPumps;
                this.pumps			= this.pumps.concat(devPumps);	// Find all the pump folders
                this.modelPumps		= this.modelPumps.concat(pumpSystem.findByRole(Role.ROLE_MODEL_PUMP));
                this.flowNodes.push(this.nodeManager.addNodeByRole(pumpSystem, Role.ROLE_TOTAL_FLOW));
                this.powerNodes.push(this.nodeManager.addNodeByRole(pumpSystem, Role.ROLE_TOTAL_POWER));
                device.targetSpeeds = this.nodeManager.addAllNodesWithRole(dpoFolder, Role.ROLE_TLC_TARGET_SPEED);
                this.targetSpeeds	= this.targetSpeeds.concat(device.targetSpeeds);
                owner.ldc.getPumpCurves(this.graphID, device.device.id, date, date, (1 << devPumps.length) - 1);	// Get the last century of curves for all pumps
            }

            this.lineOptions	= {drawPoints: false, connectSeparatedPoints: true, nomouseover: true};
            var fillOptions		= {connectSeparatedPoints: false, nomouseover: true};	// For fills, no hover and don't draw lines
            this.options		= {
                colors:						this.colors,		// Colors
                drawY2Line:					true,
                visibility:					this.visible,		// Which lines to show and hide
                connectSeparatedPoints:		false,				// Lines between points
                drawPoints: 				true,				// Put an indicator at each point
                pointSize: 					2,					// How big to make the circle
                xAxisAsNumber: 				true, 				// Interpret the x-axis as numbers, not time stamps
                pointClickCallback:			this.pointClicked.bind(this),
                drawCallback:				this.onDraw.bind(this),
                AutoHull: 					fillOptions,
                AutoLine:					this.lineOptions,
                xlabel: 					localization.toLocal("Flow (" + UnitsMap.get(this.flowUnits)?.abbrev + ")"),			// X-axis title
                ylabel: 					localization.toLocal("Specific Energy (" + UnitsMap.get(this.secUnits)?.abbrev + ")")	// Y-axis title
            };

            // Create a division to hold the legend (telling what the graph shows)
            let graphRow  			= createElement('div', 'flex__row full__height full__width', this)
            var graphWrapper		= createElement('div', 'full__height full__width flex__column', graphRow);
            var legend				= createElement('div', 'full__width flex__row', graphWrapper);
            var legendLeft			= createElement('div', 'secMapGraphLegendLeft', legend);
            var legendRight			= createElement('div', 'secMapGraphLegendRight', legend);

            createElement('div', 'secMapLegendText', legendLeft, 'Flow:');						// Label for flow
            this.flowDiv		= createElement('div', 'secMapLegendLabel secMapBlueLabel', legendLeft);

            createElement('div', 'secMapLegendText', legendLeft, 'Specific Energy:');			// Label for SEC
            this.secDiv			= createElement('div', 'secMapLegendLabel secMapGreenLabel', legendLeft);



            // Create a division to hold the graph we are going to create onJobComplete

            this.graphDiv			= createElement('div', 'secMapGraphDiv', graphWrapper);
            var captionWrapper		= createElement('div', 'secMapCaptionWrapper', graphWrapper);

            this.currentPulse	= createGraphPulse(this.graphDiv, 'green');
            this.targetPulse	= createGraphPulse(this.graphDiv, 'blue');
            this.selectPulse	= createGraphPulse(this.graphDiv, '#17FFE8');

            createCaption(captionWrapper, 'Valid Points:', 'green', null, true);
            createCaption(captionWrapper, 'Current Solution:', 'black', null, true);
            createCaption(captionWrapper, 'Invalid Points:', '#ff2828');
            createCaption(captionWrapper, 'Measured Operating Point:', 'green', null, false, true);
            createCaption(captionWrapper, 'Current Operating Point:', 'blue', null, false, true);
            createCaption(captionWrapper, 'Selected Point:', '#17FFE8', null, false, true);

            this.selection		= createElement('div', 'flex__column marker_for_checking', graphRow);
            let innerWrapper	= createElement('div', 'flex__column', this.selection);

            var stationData		= createElement('div', 'flex__row', innerWrapper);
            var dataLabel 		= createElement('div', 'secMapSelectedPointColumn', stationData);
            var dataValue		= createElement('div', 'secMapSelectedPointColumn secMapSelectedValue', stationData);
            var dataUnits		= createElement('div', 'secMapSelectedPointColumn secMapSelectedUnits', stationData);

            createElement('div', 'secMapSelectedPointCell', dataLabel, 'Flow:');
            this.selectedFlow	= createElement('div', 'secMapSelectedPointCell', dataValue);
            createElement('div', 'secMapSelectedPointCell', dataUnits).innerHTML = UnitsMap.get(this.flowUnits)?.abbrev;

            createElement('div', 'secMapSelectedPointCell', dataLabel, 'Specific Energy:');
            this.selectedSEC	= createElement('div', 'secMapSelectedPointCell', dataValue);
            createElement('div', 'secMapSelectedPointCell', dataUnits).innerHTML = UnitsMap.get(this.secUnits)?.abbrev;

            // We create columns for the per-pump section
            var pumpData 		= createElement('div', 'flex__row', innerWrapper);
            var pumpName 		= createElement('div', 'secMapSelectedPointColumn', pumpData);
            var pumpFlow 		= createElement('div', 'secMapSelectedPointColumn secMapSelectedValue', pumpData);
            var pumpFlowUnits	= createElement('div', 'secMapSelectedPointColumn secMapSelectedUnits', pumpData);
            var pumpPower 		= createElement('div', 'secMapSelectedPointColumn secMapSelectedValue', pumpData);
            var pumpPowerUnits	= createElement('div', 'secMapSelectedPointColumn secMapSelectedUnits', pumpData);
            var pumpSpeed 		= createElement('div', 'secMapSelectedPointColumn secMapSelectedValue', pumpData);
            var pumpSpeedUnits	= createElement('div', 'secMapSelectedPointColumn secMapSelectedUnits', pumpData);
            var pumpFlags 		= createElement('div', 'secMapSelectedPointColumn secMapSelectedRemarks', pumpData);

            // Create a header row above the pump info
            createElement('div', 'secMapSelectedPointCell', pumpName);
            createElement('div', 'secMapSelectedPointCell', pumpFlow, 'Flow');
            createElement('div', 'secMapSelectedPointCell', pumpFlowUnits);
            createElement('div', 'secMapSelectedPointCell', pumpPower, 'Power');
            createElement('div', 'secMapSelectedPointCell', pumpPowerUnits);
            createElement('div', 'secMapSelectedPointCell', pumpSpeed, 'Freq.');
            createElement('div', 'secMapSelectedPointCell', pumpSpeedUnits);
            createElement('div', 'secMapSelectedPointCell', pumpFlags, '% BEP Flow');

            // Create and store the individual divs for pump data

            for (var i = 0; i < this.pumps.length; ++i) {
                this.nameDivs.push(createElement('div', 'secMapSelectedPointCell', pumpName, this.pumps[i].tree.device.siteName + '/' + this.pumps[i].getDisplayName()));
                this.nameDivs.back().style.cursor = 'default';
                //this.nameDivs[i].onclick = this.togglePump.bind(this, i);
                this.flowDivs.push(createElement('div', 'secMapSelectedPointCell', pumpFlow));
                createElement('div', 'secMapSelectedPointCell', pumpFlowUnits).innerHTML = UnitsMap.get(this.flowUnits)?.abbrev;
                this.powerDivs.push(createElement('div', 'secMapSelectedPointCell', pumpPower));
                createElement('div', 'secMapSelectedPointCell', pumpPowerUnits, UnitsMap.get(TagUnit.TU_KW)?.abbrev);
                this.speedDivs.push(createElement('div', 'secMapSelectedPointCell', pumpSpeed));
                createElement('div', 'secMapSelectedPointCell', pumpSpeedUnits, UnitsMap.get(TagUnit.TU_HZ)?.abbrev);
                var porGraph = createElement('div', 'secMapSelectedPointCell', pumpFlags);
                this.flagDivs.push(new SquishedCurve(porGraph, this.pumps[i], this.modelPumps[i], {fUpdate:false}).initialize());
            }

            //this.selection.graphDiv		= this.graphDiv;
            //this.selection.onmousedown	= (e) => this.processMouseEvent(e);
            //this.selection.addEventListener('touchstart', () => this.selection.onmousedown());

            this.keyPressFunction	= this.onKeyPress.bind(this);	// We want to listen for the left and right arrow keys
            window.addEventListener('keydown', this.keyPressFunction);
            this.nodeManager.subscribe();
            for (let device of this.mapDevices)
                owner.ldc.getRegimeCurves(this.graphID, device.device.id);
            this.mapCount = 0;
            this.fInitialized = true;
        }

        findDevice(id: number): MapDevice {
            for (let device of this.mapDevices)
                if (device.device.id == id)
                    return device;
        }

        onPumpCurvesResponse(fp: FrameParser): void {
            fp.pop_u64();	// The start time we asked for
            fp.pop_u64();	// The last time we asked for
            var config		= fp.pop_u64();	// The pumps we asked data of

            var device = this.findDevice(fp.wv_id);
            assert(device);
            var flowConversion = convert(1, TagUnit.TU_GPM, this.flowUnits);
            for (var i = 0; i < device.devPumps.length; ++i) {
                if (!(config & (1 << i)))	// We didn't ask for this pump
                    continue;				// Skip it
                var pump: any = device.devPumps[i];	// Convenience reference to the pump we are modifying
                pump.curves = [];
                var curveCount = fp.pop_u8();
                for (var j = 0; j < curveCount; ++j) {
                    var curve: any = {timestamp: fp.pop_u64()};

                    curve.headCurve = [];	// Extract the head curve
                    var headCurveTerms	= fp.pop_u8();
                    for (var k = 0; k < headCurveTerms; ++k)
                        curve.headCurve.push(fp.pop_f64() * 1 / Math.pow(flowConversion, k));

                    curve.powerCurve = [];	// Extract the shaft power curve
                    var powerCurveTerms = fp.pop_u8();
                    for (var k = 0; k < powerCurveTerms; ++k)
                        curve.powerCurve.push(fp.pop_f64() / Math.pow(flowConversion, k));

                    var snapshotCount = fp.pop_u16();
                    for (var k = 0; k < snapshotCount; ++k)
                        fp.skip(4 * 4);
                    pump.curves.push(curve);
                }

                // This is the current pump curve
                var lastCurve = pump.curves[pump.curves.length - 1];
                device.devPumps[i].pumpCurve = lastCurve;
                pump.headCurve		= lastCurve.headCurve;		// Save these curves as current curves
                pump.powerCurve		= lastCurve.powerCurve;
                pump.timestamp		= lastCurve.timestamp;
                pump.curve			= lastCurve;
                var flowStep = 100*flowConversion;			// How much we jump to check and see if the head is negative
                var flow = flowStep;						// Start off with a small flow and keep jumping up until head gets negative
                while(pump.zeroHeadFlow === undefined) {	// Find the head at zero flow
                    if (pump.headCurve.evaluatePolynomial(flow) <= 0)	// Found a head less than 0!
                        pump.zeroHeadFlow	= pump.headCurve.solvePolynomial(flow-flowStep, flow, 0, 0.5*flowConversion);	// Get a little more accurate on zero head flow
                    else									// Head was positive
                        flow += flowStep;					// Keep looking at a bigger flow for the zero head flow
                }
                lastCurve.zeroHeadFlow	= pump.zeroHeadFlow;
                pump.bepFlow = lastCurve.bepFlow = findBestEfficiencyFlow(pump.zeroHeadFlow, pump.headCurve, pump.powerCurve);
            }

            var maxZeroHeadFlow = 0, maxShutoffHead = 0;	// Find out which pump has the biggest zero head flow
            for (var i = 0; i <  device.devPumps.length; ++i) {
                maxZeroHeadFlow = Math.max(maxZeroHeadFlow, device.devPumps[i].pumpCurve.zeroHeadFlow);	// Take the max zero head flow
                maxShutoffHead = Math.max(maxShutoffHead, device.devPumps[i].pumpCurve.headCurve[0]);
            }
            for (var i = 0; i <  device.devPumps.length; ++i) {
                device.devPumps[i].pumpCurve.maxZeroHeadFlow = maxZeroHeadFlow;							// Set the max zero head flow
                device.devPumps[i].pumpCurve.maxShutoffHead = maxShutoffHead;
            }
        }

        updateCurrentPoint(): void {	// Called five times a second
            if (this.graph == undefined)
                return;

            var newFlow = 0, newPower = 0;
            for (var i = 0; i < this.flowNodes.length; ++i) {
                var flow = 0;
                if (this.flowNodes[i].quality == NodeQuality.NQ_GOOD) {
                    flow = this.flowNodes[i].convertValue(TagUnit.TU_GPM);
                    newFlow += flow;
                }
                else
                    return;
                if (this.powerNodes[i].quality == NodeQuality.NQ_GOOD) {
                    newPower += this.powerNodes[i].convertValue(TagUnit.TU_KW);
                    newPower += this.deviceMap.get(this.powerNodes[i].tree.device).sourceSEC * flow * 60 / 1000000;
                }
            }
            var newSEC = 0;
            if (newFlow > 0)
                newSEC = convert(newPower / newFlow * 1E6 / 60, TagUnit.TU_KW_HR_PER_MG, this.secUnits);
            newFlow = convert(newFlow, TagUnit.TU_GPM, this.flowUnits);

            if (this.flow == -1) {	// Firt time we've been through here, just take the initial value
                this.flow = newFlow;
                this.sec = newSEC;
            } else {				// Every other time, 95% of the old value and 5% of the new
                this.flow = this.flow * 0.95 + newFlow*0.05;
                this.sec = this.sec * 0.95 + newSEC*0.05;
            }

            this.flowDiv.innerHTML	= this.flow.toFixed(1) + ' ' + UnitsMap.get(this.flowUnits)?.abbrev;	// Update the text labels above the graph
            this.secDiv.innerHTML	= this.sec.toFixed(1) + ' ' + UnitsMap.get(this.secUnits)?.abbrev;
            this.updatePulsePoint(this.currentPulse, this.flow, this.sec);
        }

        updatePulsePoint(pulse: SVGElement, flow: number, sec: number): void {
            if (!this.graph?.dygraph)
                return;
            var xAxisRange = this.graph.dygraph.xAxisRange();
            var yAxisRange = this.graph.dygraph.yAxisRange();
            if (flow < xAxisRange[0] || xAxisRange[1] < flow || sec < yAxisRange[0] || yAxisRange[1] < sec)	// Flow or SEC isn't in the graph area
                pulse.classList.add('collapse');
            else {
                pulse.classList.remove('collapse');	// Update the position of our pulser, which is a 14 by 14 circle
                pulse.style.left	= (this.graph.dygraph.toDomXCoord(flow) - 7) + 'px';
                pulse.style.top		= (this.graph.dygraph.toDomYCoord(sec) - 7) + 'px';
            }
        }

        onDraw(fInitial: boolean): void {
            if (fInitial)
                return;

            this.updateCurrentPoint();
            this.highlightSelected();
        }

        pointClicked(e: MouseEvent, point: any): void {
            let target: number = parseInt(point.name.substr(1));	// Get the index of the regime
            if (isNaN(target))
                return;

            let regime: MapRegime;
            for (var j = 0; j < this.aggregate.length; ++j) {
                if (this.aggregate[j].config === target) {
                    regime = this.aggregate[j];
                    break;
                }
            }
            assert(regime !== undefined);
            for (var i = 0; i < regime.pointArray.length; ++i) {
                if (point.yval == regime.pointArray[i].sec) {
                    this.selectedRegime	= j;	// Update our selected indices
                    this.selectedPoint	= i;
                    this.highlightSelected();	// Highlight the clicked point
                    if (this.fHiddenSelection)	// If we are hidden
                        this.hideSelection();   // Unhide
                    return;						// Done what we came to do
                }
            }
        }

        increaseSelectedPoint(): void {
            ++this.selectedPoint;
            this.highlightSelected();	// Highlight the correct point
        }

        decreaseSelectedPoint(): void {
            --this.selectedPoint;
            this.highlightSelected();	// Highlight the correct point
        }

        togglePump(i: number): void {
            if (this.selectedRegime & (1 << i))		// If the pump is on
                this.selectedRegime &= ~(1 << i);	// Clear the bit to turn it off
            else									// Pump is off
                this.selectedRegime |= (1 << i);	// Set the bit to turn it on
            this.highlightSelected();	// Highlight the correct point
        }

        onKeyPress(e: KeyboardEvent): void {
            var keyCode = typeof e.keyCode === 'number' ? e.keyCode : parseInt(e.keyCode);	// Depending on browser, key code can be a string or a number
            if (e.keyCode == 37) {			// Left arrow
                --this.selectedPoint;		// Go down a point within the regime
                this.highlightSelected();	// Highlight the correct line/point
            } else if (e.keyCode == 38) {	// Up arrow
                ++this.selectedRegime;		// Go to a higher regime
                this.highlightSelected();	// Highlight the correct line/point
            } else if (e.keyCode == 39) {	// Right arrow
                ++this.selectedPoint;		// Go up a point within the regime
                this.highlightSelected();	// Highlight the correct line/point
            } else if (e.keyCode == 40) {	// Down arrow
                --this.selectedRegime;		// Go to a lower regime
                this.highlightSelected();	// Highlight the correct line/point
            }
        }

        hideSelection(): void {
            this.fHiddenSelection = !this.fHiddenSelection;
            this.arrow.innerHTML = this.fHiddenSelection ? '&#9660' : '&#9650';
            this.selection.setAttribute('hide', this.fHiddenSelection? 'true' : 'false');
        }

        processMouseEvent(evt: MouseEvent): void {		// 'this' is the selection wrapper
            switch (evt.type) {
                case 'mousedown':
                case 'touchstart':
                    this.top		= this.selection.getBoundingClientRect().top;
                    this.left		= this.selection.getBoundingClientRect().left;
                    this.capture	= new MouseCapture(this.selection, evt);	// Capture the mouse
                    this.selection.setAttribute('grabbed', 'true');
                break;

                case 'mousemove':
                case 'touchmove':
                    this.selection.style.top	= Math.max(-this.graphDiv.clientHeight - 80, Math.min(this.top + this.capture.deltaY, -this.selection.clientHeight)) + 'px';
                    this.selection.style.left	= Math.max(0, Math.min(this.left + this.capture.deltaX, this.selection.parentElement.clientWidth - this.selection.clientWidth)) + 'px';
                break;

                case 'mouseup':
                case 'touchend':
                    delete this.capture;
                    this.selection.setAttribute('grabbed', 'false');
                break;
            }
        }

        getConfigFromSpeeds(speeds: Node[]): number {
            var config = 0;
            for (var i = 0; i < speeds.length; ++i)
                if (speeds[i].getValue() > 0)
                    config += (1 << i);
            return config;
        }

        update(node: Node): void {
            if (!this.aggregate)
                return;
            var index = this.targetSpeeds.indexOf(node);
            if(index == -1)	// If it isn't a target speed, we don't care
                return;

            var device = this.findDevice(this.targetSpeeds[index].tree.device.id);
            assert(device);

            var config = this.getConfigFromSpeeds(device.targetSpeeds);
            if (config != 0)
                owner.ldc.getPointData(this.graphID, device.device.id, true, config, false, device.targetSpeeds, 1, null);
        }

        updateTargetPoint() {
            var points = [];
            for (let device of this.mapDevices) {
                var speeds = device.targetSpeeds;
                var config = this.getConfigFromSpeeds(speeds);
                if (config == 0)
                    continue;

                var reg = device.regimes[config];
                assert(reg.config == config);
                var best = undefined, bestSOS = 1;
                for (var j = 0; j < reg.pointArray.length; ++j) {
                    let p = reg.pointArray[j];
                    if (!p.speedArray)
                        continue;
                    var sos = 0;
                    for (var k = 0; k < speeds.length; ++k) {
                        var diff = speeds[k].getValue() - p.speedArray[k];
                        sos += diff * diff;
                    }
                    if (sos < bestSOS) {
                        best = p;
                        bestSOS = sos;
                    }
                }
                if (best)
                    points.push(best);
                else
                    return;
            }

            var flow = 0, power = 0;
            for (var i = 0; i < points.length; ++i) {
                let p = points[i];
                flow += p.flow;
                power += p.power;
            }
            flow = convert(flow, TagUnit.TU_GPM, this.flowUnits);
            this.updatePulsePoint(this.targetPulse, flow, power / flow * 16666.67);
        }

        extractRegimes(fp: FrameParser, regimes: MapRegime[], device: MapDevice) {
            var regimeCount = fp.pop_u16();				// Count of regimes attached
            for (var i = 0; i < regimeCount; ++i) {		// For each regime they attached
                var regime: MapRegime = {};						// This will hold all the points for one regime
                regimes.push(regime);					// Add it to the big glut of regimes
                var config = fp.pop_u16();				// Pumps that are on in this regime set
                regime.config = config;					// Store the config on the regime for convenience
                regime.devID = fp.wv_id;
                fp.pop_u8();							// Skip alteration byte
                regime.pointArray = [];
                var pointCount = fp.pop_u16();			// Points for this regime
                var bestFlow, bestPower, bestSEC = Infinity;
                for (var j = 0; j < pointCount; ++j) {	// For each regime point
                    var point: MapPoint = {
                        flow: fp.pop_f32(),	// To sum the point data
                        power: fp.pop_f32(),			// Point total power
                        flags: fp.pop_u8(),
                        regimes: []
                    }
                    var sourcePower = device.sourceSEC * point.flow * 60 / 1000000;	// kWh/MG * gallons / minute * (60 minutes / hour) * (MG / 1E6 gallons)
                    point.power += sourcePower;
                    regime.pointArray.push(point);					// Add the point to this regime
                    var sec = point.power / point.flow;
                    if (sec < bestSEC) {
                        bestFlow = point.flow;
                        bestPower = point.power;
                        bestSEC = sec;
                    }
                }
                regime.bestFlow = bestFlow;	// Remeber the best point in the regime for later
                regime.bestPower = bestPower;
                regime.bestSEC = bestSEC;
            }

            var hullCount = fp.pop_u16();
            fp.skip(hullCount * 8);	// Skip the convex hull
        }

        onRegimeCurvesResponse(fp: FrameParser): void {
            var regimes: MapRegime[] = [];
            var device = this.findDevice(fp.wv_id);
            assert(device);
            device.regimes = regimes;
            this.extractRegimes(fp, regimes, device);	// Get auto regimes
            this.extractRegimes(fp, regimes, device);	// Get actual regimes

            if (++this.mapCount != this.mapDevices.size)	// If we're still waiting on SEC maps
                return;

            // Got all the maps. Make combined curves
            var flowConversion = convert(1, TagUnit.TU_GPM, this.flowUnits);
            var secConversion = convert(16666.67, TagUnit.TU_GPM, this.flowUnits);	// Term to calculate SEC in kWh/MG
            this.data = [this.labels];
            this.limits = {minFlow: Number.MAX_VALUE, maxFlow: 0, minSEC: Number.MAX_VALUE, maxSEC: 0};	// To hold overall statistics on data ranges
            var maxRegime = 1 << this.pumps.length;
            this.aggregate = [{
                config: 0,
                devID: 0,
                bestFlow: 0,
                bestPower: 0,
                bestSEC: 0,
                sec: 0,
                pointArray: [{
                    flow: 0,
                    sec: 0,
                    power: 0,
                    regimes: []
                }]
            }];

            // Find the best points to operate at. Combine every regime with every other regime. If the flow is
            // greater than our previous point's flow and it has the best SEC, it's our new point
            var prevFlow = 0;
            while (true) {
                var bestSEC = Infinity, bestRegimes = null, bestTarget, bestFlow;
                for (var target = 1; target < maxRegime; ++target) {		// Check every possible combination for the next best regime
                    var regimesToSum = [];
                    var pumpIndex = 0;	// Start looking at the first pump
                    for (let device of this.mapDevices) {			// Find all the regimes to sum
                        var devPumpMask = (1 << device.devPumps.length) - 1;	// Mask for device's n pumps
                        var shiftedMask = devPumpMask << pumpIndex;			// Shift the mask for the whole list of pumps

                        var devMask = target & shiftedMask;	// Mask of pumps on for this device
                        if (devMask != 0) {
                            devMask = devMask >> pumpIndex;	// Shift it back to device's pumps
                            for (var j = 0; j < device.regimes.length; ++j) {	// Find the regime with the matching config
                                if (device.regimes[j].config == devMask) {
                                    regimesToSum.push(device.regimes[j]);
                                    break;
                                }
                            }
                        }
                        pumpIndex += device.devPumps.length;
                    }

                    // Calculate the aggregate best point for this regime.
                    var aggFlow = 0, aggPower = 0;
                    for (var i = 0; i < regimesToSum.length; ++i) {
                        aggFlow += regimesToSum[i].bestFlow;
                        aggPower += regimesToSum[i].bestPower;
                    }
                    if (aggFlow <= prevFlow)
                        continue;
                    var aggSEC = aggPower / aggFlow;
                    if (aggSEC < bestSEC) {
                        bestFlow = aggFlow;
                        bestSEC = aggSEC;
                        bestRegimes = regimesToSum;
                        bestTarget = target;
                    }
                }
                if (bestRegimes === null)	// Didn't find another regime
                    break;	// Leave
                prevFlow = bestFlow;

                var regFlows: number[] = [], regSECs: number[] = [], goodSECs: number[] = [], okaySECs: number[] = [], badSECs: number[] = [];		// These arrays will hold the graph data for this regimes
                this.labels.push(this.lineLetter + bestTarget, this.goodLetter + bestTarget, this.okayLetter + bestTarget, this.badLetter + bestTarget);// Add a label for this guy
                this.colors.push('green', 'green', '#ecc900', '#ff2828');	// Allowed or not allowed in the convex hull
                this.data.push(regFlows, null, regSECs, null, regFlows, null, goodSECs, null, regFlows, null, okaySECs, null, regFlows, null, badSECs, null);
                this.options[this.lineLetter + bestTarget] = this.lineOptions;

                // Compute a meta point. Start at the fastest point for both and back them off together
                var regime: MapRegime = {
                    pointArray: []
                };
                this.aggregate.push(regime);
                regime.config = bestTarget;
                var indexes = [];
                for (var i = 0; i < bestRegimes.length; ++i)
                    indexes.push(bestRegimes[i].pointArray.length);
                while(true) {
                    var point: MapPoint = {flow: 0, power: 0, flags: 0, regimes: [], indexes: []};
                    var fNewPoint = false;
                    for (var i = 0; i < bestRegimes.length; ++i) {
                        if (indexes[i] > 0) {
                            --indexes[i];
                            fNewPoint = true;
                        }
                        let p = bestRegimes[i].pointArray[indexes[i]];
                        point.flow += p.flow;
                        point.power += p.power;
                        point.flags |= p.flags;
                        point.regimes.push(bestRegimes[i]);
                        point.indexes.push(indexes[i]);
                    }
                    if (!fNewPoint)
                        break;
                    regime.pointArray.unshift(point);
                    point.sec = point.flow > 0 ? secConversion * point.power / point.flow : 0;
                    point.flow *= flowConversion;
                    regFlows.push(point.flow);				// Add the totals to the arrays
                    regSECs.push(point.sec);
                    checkLimits(this.limits, point.flow, point.sec);
                    if (point.flags == 2) {				// If it was just in AOR
                        goodSECs.push(null);
                        okaySECs.push(point.sec);		// Add yellow to this point
                        badSECs.push(null);
                    } else if (point.flags > 0) {		// If any of the pumps had some problem
                        goodSECs.push(null);
                        okaySECs.push(null);
                        badSECs.push(point.sec);		// Add red to this point
                    } else {
                        goodSECs.push(point.sec);
                        okaySECs.push(null);
                        badSECs.push(null);
                    }
                }
            }
                // Interpolate lines for all of the convex hulls (which aren't straight)
            if (this.limits.minFlow == Number.MAX_VALUE && this.limits.minSEC == Number.MAX_VALUE) {
                this.limits.minFlow = this.limits.minSEC = 0;
                this.limits.maxFlow = this.limits.maxSEC = 5;
            }

            var hullFlows = [0], hullSECs = [0];
            var regimeIndexes = Array(this.aggregate.length).fill(0);
            let q: number = 0
            let p: number = 0;
            while (true) {
                var bestPoint = undefined, bestSEC = Infinity, bestReg;
                for (var i = 0; i < this.aggregate.length; ++i) {
                    var reg = this.aggregate[i];
                    for (let j: number = regimeIndexes[i]; j < reg.pointArray.length; ++j) {
                        let point = reg.pointArray[j];
                        if (point.flow <= q)
                            ++regimeIndexes[i];
                        else if (point.flags == 0 || point.flags == 2) {
                            let deltaSEC = (point.power - p) / (point.flow - q);  // marginal specific energy
                            if (deltaSEC < bestSEC) {
                                bestSEC = deltaSEC;
                                bestPoint = point;
                                bestReg = reg.config;
                            }
                        }
                    }
                }
                if (bestPoint) {
                    hullFlows.push(bestPoint.flow);
                    hullSECs.push(bestPoint.sec);
                    q = bestPoint.flow;
                    p = bestPoint.power;
                } else
                    break;
            }

            var lineFlows: number[] = [], lineSECs: number[] = [];
            var flowPerPixel		= 5*(this.limits.maxFlow-this.limits.minFlow)/this.graphDiv.clientWidth;	// Calculate this so we can put a point each five pixels
            interpolateSECs(flowPerPixel, hullFlows, hullSECs, lineFlows, lineSECs);

            var xAxisBuffer			= (this.limits.maxFlow - this.limits.minFlow) * .05;						// Calculate 5% of the plotted flow range
            this.options.dateWindow	= [this.limits.minFlow - xAxisBuffer, this.limits.maxFlow + xAxisBuffer];	// Give a 5% buffer on each side

            var yAxisBuffer 		= (this.limits.maxSEC - this.limits.minSEC) * .1;							// Calculate 10% of the plotted SEC range
            this.options.valueRange	= [this.limits.minSEC - yAxisBuffer, this.limits.maxSEC + yAxisBuffer];		// Give a 10% buffer on each side

            this.labels.push('AutoHull', 'AutoLine');
            this.colors.push('black', 'black');
            this.data.push(	hullFlows,	null, hullSECs,		null,
                            lineFlows,	null, lineSECs,		null);

            this.onJobCompleted();		// Redraw the graph
            this.highlightSelected();	// Make sure our SVG pulse point is in a good place
            for (let device of this.mapDevices)
                this.update(device.targetSpeeds[0]);	// Query all our target speed points now that we have them
        }

        // This calculates every combined operating point and gets too expensive:
        onRegimeCurvesResponseErething(fp: FrameParser): void {
            var regimes: MapRegime[]		= [];
            var device = this.findDevice(fp.wv_id);
            assert(device);
            device.regimes = regimes;
            this.extractRegimes(fp, regimes, device);	// Get auto regimes
            this.extractRegimes(fp, regimes, device);	// Get actual regimes

            if (++this.mapCount != this.mapDevices.size)	// If we're still waiting on SEC maps
                return;

            // Got all the maps. Make combined curves
            var flowConversion = convert(1, TagUnit.TU_GPM, this.flowUnits);
            var secConversion = convert(16666.67, TagUnit.TU_GPM, this.flowUnits);	// Term to calculate SEC in kWh/MG
            this.data = [this.labels];
            this.limits = {minFlow: Number.MAX_VALUE, maxFlow: 0, minSEC: Number.MAX_VALUE, maxSEC: 0};	// To hold overall statistics on data ranges
            var maxRegime = 1 << this.pumps.length;
            this.aggregate = [];
            for (var target = 1; target < maxRegime; ++target) {
                var regimesToSum = [];
                var pumpIndex = 0;	// Start looking at the first pump
                for (let device of this.mapDevices) {			// Find all the regimes to sum
                    var devPumpMask = (1 << device.devPumps.length) - 1;	// Mask for device's n pumps
                    var shiftedMask = devPumpMask << pumpIndex;			// Shift the mask for the whole list of pumps

                    var devMask = target & shiftedMask;	// Mask of pumps on for this device
                    if (devMask != 0) {
                        devMask = devMask >> pumpIndex;	// Shift it back to device's pumps
                        for (var j = 0; j < device.regimes.length; ++j) {	// Find the regime with the matching config
                            if (device.regimes[j].config == devMask) {
                                regimesToSum.push(device.regimes[j]);
                                break;
                            }
                        }
                    }
                    pumpIndex += device.devPumps.length;
                }

                var regFlows: number[] = [], regSECs: number[] = [], goodSECs: number[] = [], okaySECs: number[] = [], badSECs: number[] = [];		// These arrays will hold the graph data for this regimes
                this.labels.push(this.lineLetter + target, this.goodLetter + target, this.okayLetter + target, this.badLetter + target);		// Add a label for this guy
                this.colors.push('green', 'green', '#ecc900', '#ff2828');	// Allowed or not allowed in the convex hull
                this.data.push(regFlows, null, regSECs, null, regFlows, null, goodSECs, null, regFlows, null, okaySECs, null, regFlows, null, badSECs, null);
                this.options[this.lineLetter + target] = this.lineOptions;

                // Compute a meta point. Start at the fastest point for both and back them off together
                var regime: MapRegime = {};
                this.aggregate.push(regime);
                regime.config = target;
                var indexes = [];
                for (var i = 0; i < regimesToSum.length; ++i)
                    indexes.push(regimesToSum[i].pointArray.length);
                while(true) {
                    var point: MapPoint = {flow: 0, power: 0, flags: 0, regimes: [], indexes: []};
                    var fNewPoint = false;
                    for (var i = 0; i < regimesToSum.length; ++i) {
                        if (indexes[i] > 0) {
                            --indexes[i];
                            fNewPoint = true;
                        }
                        let p = regimesToSum[i][indexes[i]];
                        point.flow += p.flow;
                        point.power += p.power;
                        point.flags |= p.flags;
                        point.regimes.push(regimesToSum[i]);
                        point.indexes.push(indexes[i]);
                    }
                    if (!fNewPoint)
                        break;
                    regime.pointArray.unshift(point);
                    point.sec = point.flow > 0 ? secConversion * point.power / point.flow : 0;
                    point.flow *= flowConversion;
                    regFlows.push(point.flow);				// Add the totals to the arrays
                    regSECs.push(point.sec);
                    checkLimits(this.limits, point.flow, point.sec);
                    if (point.flags == 2) {				// If it was just in AOR
                        goodSECs.push(null);
                        okaySECs.push(point.sec);		// Add yellow to this point
                        badSECs.push(null);
                    } else if (point.flags > 0) {		// If any of the pumps had some problem
                        goodSECs.push(null);
                        okaySECs.push(null);
                        badSECs.push(point.sec);		// Add red to this point
                    } else {
                        goodSECs.push(point.sec);
                        okaySECs.push(null);
                        badSECs.push(null);
                    }
                }
            }
                // Interpolate lines for all of the convex hulls (which aren't straight)
            if (this.limits.minFlow == Number.MAX_VALUE && this.limits.minSEC == Number.MAX_VALUE) {
                this.limits.minFlow = this.limits.minSEC = 0;
                this.limits.maxFlow = this.limits.maxSEC = 5;
            }

            var hullFlows = [0], hullSECs = [0];
            var regimeIndexes = Array(this.aggregate.length).fill(0);
            let q = 0, p = 0;
            while (true) {
                var bestPoint = undefined, bestSEC = Infinity, bestReg;
                for (var i = 0; i < this.aggregate.length; ++i) {
                    var reg = this.aggregate[i];
                    for (var j: number = regimeIndexes[i]; j < reg.pointArray.length; ++j) {
                        var point = reg[j];
                        if (point.flow <= q)
                            ++regimeIndexes[i];
                        else if (point.flags == 0 || point.flags == 2) {
                            var deltaSEC = (point.power - p) / (point.flow - q);  // marginal specific energy
                            if (deltaSEC < bestSEC) {
                                bestSEC = deltaSEC;
                                bestPoint = point;
                                bestReg = reg.config;
                            }
                        }
                    }
                }
                if (bestPoint) {
                    hullFlows.push(bestPoint.flow);
                    hullSECs.push(bestPoint.sec);
                    q = bestPoint.flow;
                    p = bestPoint.power;
                } else
                    break;
            }
            var lineFlows: number[] = [], lineSECs: number[] = [];
            var flowPerPixel		= 5*(this.limits.maxFlow-this.limits.minFlow)/this.graphDiv.clientWidth;	// Calculate this so we can put a point each five pixels
            interpolateSECs(flowPerPixel, hullFlows, hullSECs, lineFlows, lineSECs);

            var xAxisBuffer			= (this.limits.maxFlow - this.limits.minFlow) * .05;						// Calculate 5% of the plotted flow range
            this.options.dateWindow	= [this.limits.minFlow - xAxisBuffer, this.limits.maxFlow + xAxisBuffer];	// Give a 5% buffer on each side

            var yAxisBuffer 		= (this.limits.maxSEC - this.limits.minSEC) * .1;							// Calculate 10% of the plotted SEC range
            this.options.valueRange	= [this.limits.minSEC - yAxisBuffer, this.limits.maxSEC + yAxisBuffer];		// Give a 10% buffer on each side

            this.labels.push('AutoHull', 'AutoLine');
            this.colors.push('black', 'black');
            this.data.push(	hullFlows,	null, hullSECs,		null,
                            lineFlows,	null, lineSECs,		null);

            this.onJobCompleted();		// Redraw the graph
            this.highlightSelected();	// Make sure our SVG pulse point is in a good place
            for (let device of this.mapDevices)
                this.update(device.targetSpeeds[0]);	// Query all our target speed points now that we have them
        }

        highlightSelected(): void {	// Make the selected regime and the selected point different colors
            if (!this.aggregate)	// Might not have data yet
                return;
            this.selectedRegime	= Math.max(0,Math.min(this.selectedRegime, this.aggregate.length - 1));	// Limit to valid indicies
            var reg = this.aggregate[this.selectedRegime];
            this.selectedPoint	= Math.max(0,Math.min(this.selectedPoint, reg.pointArray.length - 1));		// Limit to valid indicies

            var point = reg.pointArray[this.selectedPoint];
            if (this.graph)		// If we have drawn a graph
                this.updatePulsePoint(this.selectPulse, point.flow, point.sec);	// Update the pulse point

            var fAnyRequested = false;

            for (var i = 0; i < point.regimes.length; ++i) {
                var sourceRegime = point.regimes[i];
                var sourcePoint = sourceRegime.pointArray[point.indexes[i]];
                if (!sourcePoint.fReq) {		// No data on this point yet
                    sourcePoint.fReq = true;	// Make a note we are requesting this data
                    owner.ldc.getPointData(this.graphID, sourceRegime.devID, false, sourceRegime.config, true, null, null, point.indexes[i]);	// Ask for it
                    fAnyRequested = true;
                }
            }

            if (!fAnyRequested)					// If we already have all the data on this point
                this.updatePointDetails();		// Update the point display
        }

        onPointResponse(fp: FrameParser): void {
            if (!this.aggregate) {	// If don't have data
                fp.skip(fp.size);	// Don't respond to data
                return;
            }

            var device = this.findDevice(fp.wv_id);
            assert(device);
            var regimes = device.regimes;

            fp.pop_u8();	// If the point they want is in the auto system
            var regime	= fp.pop_u16();	// Regime for this point
            var index	= fp.pop_u16();	// Index of the point they want

            for (var i = 0; i < regimes.length; ++i) {	// Check each regime
                if (regimes[i].config != regime)	// If this is the right regime (they are in order, but potentially sparse)
                    continue;
                if (index >= regimes[i].pointArray.length)
                    break;

                var point = regimes[i].pointArray[index];		// Get a reference to the point
                point.flowArray = [], point.powerArray = [], point.speedArray = [], point.flagArray = [];	// Build up data arrays for it
                for (var j = 0; j < device.devPumps.length; ++j) {	// For each pump
                    if (regime & (1 << j)) {			// If the pump was on
                        point.flagArray.push(fp.pop_u16());	// Get flags
                        point.flowArray.push(fp.pop_f32());	// Get flow and convert to correct units
                        point.powerArray.push(fp.pop_f32());					// Get power and convert to correct units
                        point.speedArray.push(fp.pop_f32());					// Get speed and convert to correct units
                    } else {							// The pump is off
                        point.flagArray.push(0);			// And its data is uninteresting
                        point.flowArray.push(0);
                        point.powerArray.push(0);
                        point.speedArray.push(0);
                    }
                }
                if (fp.pop_u8())	// If there's a suction pressure
                    fp.pop_f32();	// Skip it
                if (fp.pop_u8())	// If there's a discharge pressure
                    fp.pop_f32();	// Skip it
                this.updatePointDetails();	// Check if we shoud display the data
                this.updateTargetPoint();
                return;	// Found our point
            }
            fp.skip(fp.size());	// Skip the data
        }

        updatePointDetails(): void {
            // Check our selected point
            var selected = this.aggregate[this.selectedRegime].pointArray[this.selectedPoint];
            for (var i = 0; i < selected.regimes.length; ++i) {
                var sourceRegime = selected.regimes[i];
                var sourcePoint = sourceRegime.pointArray[selected.indexes[i]];
                assert(sourcePoint.fReq, "All source points should be requested.");
                if (sourcePoint.flowArray === undefined)	// If this point isn't filled out
                    return;								// Gotta wait til it comes back
            }
            this.selectedFlow.textContent	= selected.flow.toFixed(0);
            this.selectedSEC.textContent	= selected.sec.toFixed(0);

            var pumpIndex = 0;	// Start looking at the first pump
            var devIndex = 0;
            for (let device of this.mapDevices) {			// Find all the regimes to sum
                var point: MapPoint = undefined;
                if (devIndex < selected.regimes.length && device.device.id == selected.regimes[devIndex].devID)
                    point = selected.regimes[devIndex].pointArray[selected.indexes[devIndex++]];

                for (var j = 0; j < device.devPumps.length; ++j) {
                    var flow = point ? point.flowArray[j] : 0;
                    var speed = point ? point.speedArray[j] : 0;
                    var power = point ? point.powerArray[j] : 0;
                    var flags = point ? point.flagArray[j] : 0;

                    var divIndex = j + pumpIndex;
                    this.nameDivs[divIndex].setAttribute('running', speed > 0 ? 'true' : 'false');
                    this.flowDivs[divIndex].textContent = flow.toFixed(0);
                    this.powerDivs[divIndex].textContent = power.toFixed(1);
                    this.speedDivs[divIndex].textContent = speed.toFixed(1);
                    this.flagDivs[divIndex].setFlow(flow, speed);
                    this.powerDivs[divIndex].classList.toggle('pumpOverPower', (flags & LiveData.MSS_OVER_POWER_LIMIT) != 0);
                    this.flowDivs[divIndex].classList.toggle('pumpOverPower', (flags & LiveData.MSS_UNDER_NPSHR) != 0);
                    this.speedDivs[divIndex].classList.toggle('pumpOverPower', (flags & LiveData.MSS_LOCKED_OUT) != 0);
                }
                pumpIndex += device.devPumps.length;
            }
        }

        onJobCompleted(): void {
            if (this.graph)				// If we've drawn a graph before
                this.graph.destroy();   // Delete it first

            // Create a new graph to plot our points
            this.graph = new StaticGraph(owner.ldc, this.graphDiv, this.graphDiv.clientWidth, this.graphDiv.clientHeight, this.data, this.options, false);
        }

        populateSettings(editor: EditorPage): void {
            super.populateSettings(editor);
            let wrapper             = this.createSection(editor.toolTabs.getSectionByName('Settings'),'Devices')
            let optionWrapper       = createElement('div', 'flex__column full__width', wrapper);

            let devices: Set<SerializedMapDevice> = new Set(this.recipe.settings['MapDevices']);
            if (devices)
                for (let device of devices) {
                    let row = createElement('div', 'flex__row full__width', optionWrapper);
                    this.addDeviceInput(row, device);
                }
            else
                this.recipe.settings['Map']

            let addButton           = createElement('div', 'socket__add__button', optionWrapper);
            addButton.style.width   = '100%';
            createElement('div', 'socket__add__button__text', addButton, 'Add DPO');
            createElement('img', 'socket__add__button__icon', addButton, undefined, {'src':''});

            addButton.onclick = () => {
                let options: HTMLOptionElement[] = [];
                for (let i=0;i<owner.sortedDevices.length;++i) {
                    let rootNode      = owner.sortedDevices[i].tree.nodes[0];
                    let pumpSystem    = rootNode.findChildByRole(Role.ROLE_PUMP_BANK);
                    if (pumpSystem)
                        options.push(createElement('option', '', null, owner.sortedDevices[i].siteName, {'value':owner.sortedDevices[i].key}));
                }
                let addOptions = {
                    fSelect: 	true,
                    title:		'Select a Device',
                    body: 		'',
                    options:	options,
                    callback:	(option: HTMLOptionElement) => {
                        this.recipe.settings['MapDevices'].push({
                            deviceKey: option.value,
                            sourceSEC: 0
                        });
                        this.rebuild()
                    }
                }
                new Dialog(document.body, addOptions)
            }
        }

        addDeviceInput(row: HTMLElement, device: SerializedMapDevice) {
            let actualDevice = owner.ldc.devices.getByKey(device.deviceKey);
            createElement('div', 'flex__1', row, actualDevice.siteName);
            let secInput  = createElement('input', 'dwsetting__range__value', row, undefined, {
                                                                                            'autocomplete'     : 'off',
                                                                                            'autocapitalize'   : 'off',
                                                                                            'type'             : 'number'});	// Create the input element
            secInput.value = device.sourceSEC.toString();
            secInput.onchange = () => {
                device.sourceSEC = parseFloat(secInput.value);
                this.rebuild();
            }
        }

        onResize() {
            this.refresh();
        }

        refresh(): void {			// This is called when the page is reloaded
            if (!this.graph)
                return;

            this.onJobCompleted();		// Tell the graph to quickly realign itself
            this.highlightSelected();	// Make sure our SVG pulse point is in a good place
        }

        disconnectedCallback(): void {
            super.disconnectedCallback();
            window.removeEventListener('keydown', this.keyPressFunction);
            if (this.graph) {
                this.graph.destroy();
            }
            if (this.nodeManager) {	// Initialize might not have been called and this wouldn't exist
                this.nodeManager.destroy();		// Unsubscribe to all the nodes we subscribed to
                owner.ldc.unregisterGraph(this.graphID);
                clearInterval(this.filterID);
                delete this.capture;
            }

            for(var key in this)	// Delete our created members
                delete this[key];	// Remove all of our constraint member variables
        }

    };
