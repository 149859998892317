import { createElement } from "../elements";
import './radio.css';
import tippy from 'tippy.js';

export interface RadioButton {
    name: string;
    icon?: string;
    displayName: string;
    selectCallback?: (name: string)=>any;
    deselectCallback?: (name: string)=>any;
    id?: number;
    tooltip?: string;
}

export interface RadioSelectorOptions {
    buttons: RadioButton[];
    title?: string;
    defaultSelection?: string;
    changeCallback?: (name: string)=>void;
    selectedStyles?: Partial<CSSStyleDeclaration> & { [propName: string]: string };
    defaultStyles?: Partial<CSSStyleDeclaration> & { [propName: string]: string };
}

export class RadioSelector {
    parent: HTMLElement;
    buttonMap: Map<string, HTMLElement> = new Map();
    selectedName: string;
    changeCallback: ((name: string)=>void) | undefined;
    _selectedStyles: CSSStyleDeclaration;
    _defaultStyles: CSSStyleDeclaration;
    options: RadioSelectorOptions = {
        buttons: [{name: 'Default', displayName: 'Default'}],
        defaultStyles: {
            backgroundColor: 'var(--color-surfaceContainerLow)',
        },
        selectedStyles: {
            backgroundColor: 'var(--color-primary)',
        }
    };
    constructor(parent: HTMLElement, options: RadioSelectorOptions) {
        this.parent         = parent;
		Object.assign(this.options, options);
        this.createLocalStyles();

        if (this.options.title && this.options.title.length > 0)
            createElement('div', '', this.parent, this.options.title)
        let tabContainer    = createElement('div', 'flex__row align__center', this.parent);
        for (let i=0;i<this.options.buttons.length;++i) {
            this.createButton(tabContainer, this.options.buttons[i].name, this.options.buttons[i].displayName, this.options.buttons[i].icon ?? '', this.options.buttons[i].tooltip ?? '', this.options.buttons[i].selectCallback,  i == 0);
        }
        if (typeof options.defaultSelection !== 'undefined' && this.buttonMap.has(options.defaultSelection)) {
            this.select(options.defaultSelection, false)
        }
        this.setButtonClasses();
    }

    createLocalStyles() {
        this._selectedStyles = createElement('div').style;
        Object.keys(this.options.selectedStyles!).forEach((styleKey: string) => {
            this._selectedStyles[styleKey] = this.options.selectedStyles![styleKey];
        });
        this._defaultStyles = createElement('div').style;
        Object.keys(this.options.defaultStyles!).forEach((styleKey: string) => {
            this._defaultStyles[styleKey] = this.options.defaultStyles![styleKey];
        });
    }

    createButton(parent: HTMLElement, name: string, displayName: string, icon: string, tooltip?: string, callback?: (name: string) => any, fFirst?: boolean) {
        let tab = createElement('div', 'radio__tab' + (fFirst ? ' radio__tab__selected' : ''), parent);
        tab.style.cssText = this.options.defaultSelection == name ? this._selectedStyles.cssText : this._defaultStyles.cssText;

        if (icon)
            createElement('img', 'radio__icon', tab, '', {'src':icon});
        if (displayName)
            createElement('div', 'radio__title', tab, displayName);
        if (tooltip) {
            tippy(tab, {
                content  : tooltip ?? '',
                delay    : [750, 0],        // duration: [show, hide]
                duration : [0, 0],          // duration: [show, hide]
                placement: 'top',
                offset   : [0, 5],
                allowHTML: true
            });
        }
        this.buttonMap.set(name, tab)
        tab.onclick = () => {
            this.select(name);
            callback && callback(name);                         // Call the individual button's callback
        }
    };

    getButtonByName(name: string) {
        return this.buttonMap.get(name);
    }

    select(name: string, fCallback: boolean = true) {
        let tab = this.buttonMap.get(name);
        if (!tab)
            return;
        for (let button of this.buttonMap.values()) {
            button.style.cssText = this._defaultStyles.cssText;
            button.classList.remove('radio__tab__selected')
        }
        tab.style.cssText = this._selectedStyles.cssText;
        tab.classList.add('radio__tab__selected')
        this.selectedName = name;
        if (fCallback)
            this.options.changeCallback && this.options.changeCallback(name);   // Call our overall change callback
    }

    selectDefault() {
        this.buttonMap.get(this.options.buttons[0].name)!.click();
    }

    setButtonEnabled(name: string, fEnabled: boolean) {
        let button = this.buttonMap.get(name)!;
        //button.disabled = fEnabled;
    }

    hideButton(name: string) {
        let button = this.buttonMap.get(name)
    }

    setButtonVisibility(buttonName: string, visibility: boolean) {
        let button = this.buttonMap.get(buttonName);
        if (!button)
            return;
        button.classList.toggle('hide', !visibility);
        this.setButtonClasses();
    }

    private setButtonClasses() {
        let fFoundFirstVisible = false;
        let lastFoundButtonElement: HTMLElement | undefined;
        this.options.buttons.forEach(button => {
            let buttonElement = this.buttonMap.get(button.name);
            buttonElement?.classList.remove('radio__tab__first');
            buttonElement?.classList.remove('radio__tab__last');
            if (!buttonElement?.classList.contains('hide')) { // Found a visible element
                if (!fFoundFirstVisible) {
                    fFoundFirstVisible = true;
                    buttonElement?.classList.add('radio__tab__first');
                }
                lastFoundButtonElement = buttonElement;
            }
        })
        if (lastFoundButtonElement)
            lastFoundButtonElement.classList.add('radio__tab__last');
    }

    showButton(name: string) {
        let button = this.buttonMap.get(name)
    }

    update(setting: string) {
        for (let button of this.buttonMap.values()) {
            button.classList.remove('radio__tab__selected');
            this._defaultStyles
            button.style.cssText = this._defaultStyles.cssText;
        }
        let button = this.buttonMap.get(setting);
        if (button) {
            button?.classList.add('radio__tab__selected');
            button.style.cssText = this._selectedStyles.cssText;
        }
    }
}