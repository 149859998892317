/*
    EXAMPLE USAGE: 

        passwordSecurityChecker = new PasswordSecurityChecker();

        passwordSecurityChecker.evaluatePassword("supersecurepassword").then( () => {   // Call evaluatePassword outside of an async function with a .then callback
            console.log(passwordSecurityChecker.isSecure);                              // Do stuff with the results when it's done
        });

        async function otherAsyncFunction() {                                           // Example inside an async function
            await passwordSecurityChecker.evaluatePassword("supersecurepassword");
            console.log(passwordSecurityChecker.isSecure)
        }
    
    HIBP passwords API:

        API overview - https://haveibeenpwned.com/API/v3#PwnedPasswords
        
        1. GET https://api.pwnedpasswords.com/range/{first 5 SHA-1 hash chars}
        2. Check the list of returned hashes against the hash of the password in question

        Try it out: curl https://api.pwnedpasswords.com/range/fffff
        
        Format of text response content...
        
        HTTP/2 200
        content-type: text/plain
        ...

        F37EBBA50615FC7B734CE5147AA75C17046:31      # <SHA-1 hash without first 5 hex chars>:<# of instances in HIBP database>
        F39B35E376A2B14607ECAB4CFBDABA2FF53:7
        F440077E290D8CBA01109998D5E0EA3E2BD:1
        F5A37C325A7D2149A4D7C6CF5BAF52B4AD2:24
        F5DD6C322279E6FF3A887C3E1B1B1F2A92E:1
        F62FE11AFCAFA150C120CE677906CD11B0C:17
        F64E4DC8F38A0E12074B3DD7CF072C0D5E6:4
        F6A89120E49BE8BBB7F86E7B1E23E7C26E0:1
        F711C98A16585AF377D51B0FC856B03570F:1
        F753DA987B17B846200FB4438F8CA4FCC2E:1
        F772667C79BDDE35F8347F691A115BFC54A:3
        ...
        
        API LICENSING:
            Cost: free!
            License in documentation: https://haveibeenpwned.com/API/v3#License
            
            Most of the HIBP APIs are subject to Creative Commons Attribution 4.0. However, the password API is not...
            
            "In order to help maximise adoption, there is no licencing or attribution requirements on the Pwned Passwords API, 
             although it is welcomed if you would like to include it."

            So, it's not required to give attribution but let's mention HIBP where it's relevant and affects user interaction.

    TODO: 
        Now that we are checking against the HIBP database, ideally we should get rid of 
        special char and number requirements in favor of just length and checking against
        HIBP. Some things worth considering when we do this...
        
        1.  As of writing this, the back-end rejects new passwords that don't have a number/special char so we'll need
            to make sure to remove the password security requirements on the back-end first, except for a length
            check, and then remove the number/special char requirements from the front-end so that things don't break.
        2.  HIBP could always go down, return malformed data, etc. Because of this, the API request and handling of
            response data is wrapped in a try/catch below so that the application will still function. If
            this becomes our sole password security requirement, do we conditionally impose the old security requirements
            if we can't reach HIBP?
*/
export default class PasswordSecurityChecker {

    constructor() {
        // Secure password attributes
        this.hasNotBeenPwned            = false;
        this.isLongEnough               = false;

        this.HIBP_APIrequestCount       = 0;        // Because the HIBP API requests are async, keep track of the order in which we've made requests so that the password security results only update to reflect the last password we were asked to evaluate
                                                    // i.e. if we make 5 requests and request #3 arrives after request #5, we will not update to the results of request #3

        this.pendingAPIrequests         = 0;        // Tracking # of pending requests so that we know if we're done checking passwords (mainly so the UI can display a loader for the user)

        this.isSecure                   = false;    // Everything else is true
    }

    async evaluatePassword(password) {
		// A secure password requires no pwnage, at least one number, one letter, one other character, and has to be eight characters long
        this.isLongEnough           = (password.length < 8)     ? false : true;

        ++this.HIBP_APIrequestCount;
        let {count, pwnage} = await this.passwordHasBeenPwned(password, this.HIBP_APIrequestCount);

        if(count < this.HIBP_APIrequestCount)   // This response was received out of order with other requests to evaluate a password. Stale, throw it away
            return; // Too slow joe, don't update the pwnage or overall security

        this.hasNotBeenPwned = (parseInt(pwnage) > 0) ? false : true;
		
        this.isSecure = this.hasNotBeenPwned && this.isLongEnough;
        
        return this.isSecure;   // Done! you can check this.isSecure for results
	}

    async passwordHasBeenPwned(password, localRequestCount) {

        if(password.length < 8) // Don't bother checking short passwords against the API, assume pwnage
            return {
                count: localRequestCount,
                pwnage: 1
            };

        let hashBuffer = await this.sha1hash(password);

        const hashArray = Array.from(new Uint8Array(hashBuffer));
        const hash = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');  // hex representation of hash digest

        let shareableHash = hash.substring(0,5);    // First five hex chars are shared with the api

        try {   // Try to query the API, parse the data, and 
            ++this.pendingAPIrequests;
            var hibpHashes = await this.queryHIBP_API(shareableHash);    
            --this.pendingAPIrequests;   // Decrement so we can get back to 0 eventually (0 == all submitted requests successful)

            for(var i = 0; i < hibpHashes.length; i++){         // Go through all of the hashes from hipb to see if the password is in the list
                var testSubject = hibpHashes[i].split(":");
                
                if(testSubject[1] == 0)
                    continue;   // This was a padding hash, skip it

                if((shareableHash + testSubject[0].toLocaleLowerCase()) == hash.toLocaleLowerCase()){
                    return {
                        count: localRequestCount,
                        pwnage: testSubject[1],  // Return the number of times this password has been pwned
                    };              
                }
            }
        }
        catch(error) {
            console.log("Difficulty querying data from HIBP API. Skipping password denylist check.");
            console.log(error);
            return {
                count: localRequestCount,
                pwnage: 0    // Something is wrong with our ability to reach the API, consider it good so we don't block logins
            };  
        }

        return {
            count: localRequestCount,
            pwnage: 0
        };   // Password has not been pwned
    }

    async sleep(ms) {   // Nice for testing async functionality with some delays thrown in
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    async queryHIBP_API(shareableHash) {    // Returns a promise that resolves to an array of HIBP hashes 
        // await this.sleep(Math.floor(Math.random() * 4000));  // For testing handling of responses arriving out of order

        return new Promise(function (resolve, reject) {
            var xhr = new XMLHttpRequest();

            var hibpURL = "https://api.pwnedpasswords.com/range/" + shareableHash;
            xhr.open("GET", hibpURL);
            xhr.setRequestHeader("Add-Padding", "true");    // Ask for HIBP to pad responses with irrelevant hashes to prevent attacker inferring API lookups based on response sizes (https://haveibeenpwned.com/API/v3#PwnedPasswordsPadding)
            xhr.timeout = 3000;                             // timeout in milliseconds (if we are hitting this somehow, it will be a bad experience for users setting a new password but logins will only suffer for a single 3 seconds)

            xhr.onload = function () {
                if (xhr.status == 200) {                    // HIBP (when working properly) should only ever respond with a 200
                    resolve(xhr.responseText.split("\r\n"));
                } else {
                    reject(Error(xhr.statusText));
                }
            };

            xhr.ontimeout = function (e) {
                reject(e);
            };

            xhr.onerror = function () {
                reject(Error(xhr.statusText));
            };

            xhr.send();
        });
    }

    async sha1hash(stringToHash) {
        try {
            var encoder = new TextEncoder();
            let hashBuffer = window.crypto.subtle.digest("SHA-1", encoder.encode(stringToHash));
            return hashBuffer;
        } catch(e) {
            console.log(e);
            throw e;      // let caller know the promise was rejected with this reason
        }
    }
}