import {Widget} from "./widget";
import {NodeQuality} from './node';
import assert from './debug'

export default class ColoredHeader extends Widget {
    constructor(element, properties) {
        super();
        assert (element, 'element is a required property');
        assert (element.parentNode, 'element must be connected to DOM so that CSS info is available.');
        this.element = element;
        this.registerAsWidget(element);
        
        this.checkValueFunction = function(v){return v;};
        this.copy(properties);	// Copy properties over to the object:
        
        // The colored header wants an array of nodes put into a member called 'nodes'. Nodes
        // should be added in the order of priority
        assert(this.nodes && Array.isArray(this.nodes), "ColoredHeader needs a node array!");
        assert(this.nodes.length > 0, "ColoredHeader needs a node!");

        // We want to allow custom class changes. to do that we can pick up any classes they may pass us:
        if (!this.trueClass) {		// They didn't give us a default set of class names
            this.trueClass = [];	// Empty array to start out with
            for (var i = 0; i < this.nodes.length; ++i)
                this.trueClass.push('active' + i);		// Add a true class for each node they gave us
        }
        assert(this.trueClass.length == this.nodes.length, "Should have a class name for each node!");
        this.falseClass = this.falseClass || 'off';		// They didn't pass us a default off class, so pick one

        this.element.classList.add(this.falseClass);	// Defaulting to false
        this.currentClass = this.falseClass;			// Store which class we are using
        
        for (var i = 0; i < this.nodes.length; ++i) {
            assert(this.nodes[i], "No null nodes allowed!");
            this.nodes[i].subscribe(this);	// Subscribe to the nodes they gave us
        }
    };

    update() {
        for (var i = 0; i < this.nodes.length; ++i) {
            if (this.nodes[i].quality != NodeQuality.NQ_GOOD)
                continue;
            if (this.checkValueFunction(this.nodes[i].getValue(this.fPending))) {
    //		if (this.nodes[i].getValue()) {					// Find the first node that is true in the array
                var newClass = this.trueClass[i];
                if (this.currentClass != newClass) {					// If this requires us to change class
                    this.element.classList.remove(this.currentClass);	// Remove our current class
                    this.element.classList.add(newClass);				// Add our new class
                    this.currentClass = newClass;						// Update our current class
                }
                return;	// We found our node in control, so we are done
            }
        }
        // None of our nodes were on
        this.element.classList.remove(this.currentClass);	// Remove our current class
        this.element.classList.add(this.falseClass);		// Add our false class
        this.currentClass = this.falseClass;				// Update our current class
    };

    destroy = function() {
        this.element.classList.remove(this.currentClass);	// Clean up our last used class
        for (var i = 0; i < this.nodes.length; ++i)			// Unsubscribe from all of our nodes
            this.nodes[i].unsubscribe(this);
        this.unregisterAsWidget();
    };
};
